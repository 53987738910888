<template>
    <div class="Trafficguide">
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="9"></Header>
        <div class="body">
            <!-- <div v-html="html"></div> -->
            <!-- 以上为对接接口了 -->	            
            <h3>交通指引</h3>
            <h4>1、展馆位置示意图</h4>
            <h5>地址：广州市海珠区阅江中路380号</h5>
            <el-image 
                :src="require('@/assets/images/dt.png')"
                :preview-src-list="[require('@/assets/images/dt.png')]">
            </el-image>
        </div>

    </div>
</template>

<script>
    import {
        exhibitiondisplaycommonGetList,
    } from '@/api/exhibition'
export default {
    name: 'ExhibitionprojectTrafficguide',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
            html: '',
        };
    },

    mounted() {
        // this.exhibitiondisplaycommonGetList()
    },

    methods: {
            exhibitiondisplaycommonGetList() {
                var param2 = {
                    firstDirName: '展商服务',
                    secondDirName: '交通指引',
                }

                exhibitiondisplaycommonGetList(param2).then(res=>{
					var data = res.data.data
					var html = data[0].plateBody
					this.html = html

                })                
            },        
    },
};
</script>

<style lang="scss" scoped>
.Trafficguide {
    .body {
        max-width: 850px;  
        margin: 0 auto;  
    }
    h3 {
        height: 48px;
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;        
    }
    h4 {
        height: 30px;
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #000000;
        line-height: 30px;
        margin-top: 48px;
    }
    h5 {
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        margin-top: 12px;
    }
    .text {
        width: 100%;
        max-width: 850px;  
        margin: 0 auto;      
        height: 420px;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        text-align: justify;
    }
    .el-row {
        width: 100%;
        max-width: 850px;  
        margin: 0 auto;  
        height: 54px;    
        line-height: 54px;    
        border-top: 1px solid #D8D8D8;
        &:last-child {
            border-bottom: 1px solid #D8D8D8;
            margin-bottom: 48px;
        }
    }
    .head {
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #727272;
        margin-top: 25px;
    }
    .body {
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
    }
    img, .el-image {
        margin-top: 12px;
        width: 100%;
        max-width: 850px;
        margin-bottom: 12px;
    }
}
@media screen and (max-width: 1300px) { // 非pc端
    .el-row {
        // transform: scale(0.8); 
    }
    .head {
        font-size: 12px !important;
    }
    .body {
        font-size: 12px !important;
        line-height: 27px !important;   
        .el-col {
            &:nth-child(3) {
                text-indent: 15px;
            }
        }
    }

}
</style>