<template>
	<div class="exhibition-range">
		<Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="7"></Header>
		<div class="main">
			<div class="content1">
				<div class="title">展品范围</div>
				<!-- 接入接口 -->
				<!-- <div :class="{'content-block':true, needReverse: (isMobile?true:i%2!=1), blocktype2: i%2==1, reverseDirect: (!isMobile&&i%2==1)}" v-for="(item, i) in imgList" :key="i">
					<div class="exhibit">
						<div class="exhibit-title">{{item.h2}}</div>
						<div class="exhibit-introduce">
							{{item.h5}}
						</div>
					</div>
					<img :src="item.imgurl" class="content-image" />
				</div> -->
				<div class="content-block needReverse">
					<div class="exhibit">
						<div class="exhibit-title">（1）智能列车及先进车辆段配套装备</div>
						<div class="exhibit-introduce">
							全面展示先进轨道交通及铁路机车车辆技术、车辆组件及零部件、机电设备装备、轨道工业制造装备及材料、车辆段设备等。
						</div>
					</div>
					<img :src="require('@/assets/images/exhiRange1.png')" class="content-image" />
				</div>
				<div class="content-block blocktype2">
					<img :src="require('@/assets/images/exhiRange2.png')" class="content-image" />
					<div class="exhibit">
						<div class="exhibit-title">（2）通信信号系统及城轨云</div>
						<div class="exhibit-introduce">
							全面展示城轨行业的数字化、信息化建设成果，互联互通的全自动运行、人工智能技术。重点突出轨道交通 5G 应用技术、云计算、物联网等先进技术。
						</div>
					</div>
				</div>
				<div class="content-block needReverse">
					<div class="exhibit">
						<div class="exhibit-title">（3）供电系统及智慧能源</div>
						<div class="exhibit-introduce">
							全面展示轨道交通供电系统建设成果，重点突出“双碳”及利于打造特有产业链的创新性产品。
						</div>
					</div>
					<img :src="require('@/assets/images/exhiRange3.png')" class="content-image" />
				</div>
				<div class="content-block blocktype2">
					<img :src="require('@/assets/images/exhiRange4.png')" class="content-image" />
					<div class="exhibit">
						<div class="exhibit-title">（4）智慧车站、智能运输及配套装备</div>
						<div class="exhibit-introduce">
							全面展示先进车站技术及关键配套部件与装备。重点突出电扶梯及新材料、自动售检票系统、屏蔽门安全系统以及其它地铁站点设备等。
						</div>
					</div>
				</div>
				<div class="content-block needReverse">
					<div class="exhibit">
						<div class="exhibit-title">（5）先进轨道交通基础设施建设及施工技术</div>
						<div class="exhibit-introduce">
							全面展示轨道交通基础设施建设的产业链及技术、工程监理与施工系统、工程检测与监测技术、建设管理系统等，推动“四新”成果应用推广。重点突出装配式建筑设计施工、盾构施工创新工艺、轨道减振降噪、新型防火防水材料等领域的展示。
						</div>
					</div>
					<img :src="require('@/assets/images/exhiRange5.png')" class="content-image" />
				</div>
				<div class="content-block blocktype2">
					<img :src="require('@/assets/images/exhiRange6.png')" class="content-image" />
					<div class="exhibit">
						<div class="exhibit-title">（6）设计、咨询、检测认证与安全管理综合</div>
						<div class="exhibit-introduce">
							全面展示大湾区设计咨询领域优势、国家质量基础设施体系（NQI）标准化建设工作、轨道交通安全管理发展的重要成果，凸显大湾区设计咨询、检测认证、安全管理等领域的协同创新。
						</div>
					</div>
				</div>
				<div class="content-block needReverse">
					<div class="exhibit">
						<div class="exhibit-title">（7）城轨人才</div>
						<div class="exhibit-introduce">
							全面展示城轨行业人才培养、技能竞赛、师资培训、职业教育等内容。重点突出轨交企业及相关机构对轨道交通人力资源开发利用的成果。
						</div>
					</div>
					<img :src="require('@/assets/images/exhiRange7.png')" class="content-image" />
				</div>
				<div class="content-block blocktype2">
					<img :src="require('@/assets/images/exhiRange8.png')" class="content-image" />
					<div class="exhibit">
						<div class="exhibit-title">（8）轨道交通物资采购</div>
						<div class="exhibit-introduce">
							邀请来自全国各地包括深圳、佛山、东莞、武汉、上海、杭州、沈阳、合肥等多家轨道交通业主单位共聚一堂，并在现场设置各业主单位驻场供需对接专区，充分释放业主单位采购新需求，践行“三优”采购标准，积极赋能供应商业务新增量。
						</div>
					</div>
				</div>
				<div class="content-block needReverse">
					<div class="exhibit">
						<div class="exhibit-title">（9）轨道交通产业园</div>
						<div class="exhibit-introduce">
							邀请包括广州白云、南沙、花都轨道交通相关产业园等全国各地的产业园区，综合展示园区的政策、配套、规划等内容。
						</div>
					</div>
					<img :src="require('@/assets/images/exhiRange9.png')" class="content-image" />
				</div>
				<div class="content-block blocktype2">
					<img :src="require('@/assets/images/exhiRange10.png')" class="content-image" />
					<div class="exhibit">
						<div class="exhibit-title">（10）站城融合（TOD）及配套服务</div>
						<div class="exhibit-introduce">
							全面展示大湾区站城融合 TOD4.0 规划与实践探索成果，囊括 TOD 相关综合开发类、专业服务类、产业经营类项目与解决方案。重点突出广州地铁沿线地块的项目开发成果展示。
						</div>
					</div>
				</div>
				<div class="content-block needReverse">
					<div class="exhibit">
						<div class="exhibit-title">（11）城轨文化、资源经营</div>
						<div class="exhibit-introduce">
							全面展示相关文创产品、城轨文化出版物、城轨新闻媒体产品与服务，资源经营相关企业的创新、开发、运营、管理等优秀实践成果。
						</div>
					</div>
					<img :src="require('@/assets/images/exhiRange11.png')" class="content-image" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import {
        exhibitiondisplaycommonGetList,
    } from '@/api/exhibition'
export default {
	name: 'ExhibitionRange',
	data() {
		return {
			headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
			isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
			imgList: [],
		}
	},
	mounted() {
		// this.exhibitiondisplaycommonGetList()
	},
	methods: {
            exhibitiondisplaycommonGetList() {
                var param2 = {
                    firstDirName: '展商服务',
                    secondDirName: '展品范围',
                }
                var cellList = []

                exhibitiondisplaycommonGetList(param2).then(res=>{
                    var data = res.data.data
                    data.map(r=>{
                        if (r.firstDirName=='展商服务'&&r.secondDirName=='展品范围') {
                            var obj = JSON.parse(r.plateBody)
                            obj.form.fileList[0].url = JSON.parse(r.plateBody).form.fileList[0].response.data.link   
                            obj.id = r.id    
                            obj.imgurl = JSON.parse(r.plateBody).form.fileList[0].response.data.link   
                            obj.h2 = JSON.parse(r.plateBody).form.title
                            obj.h5 = JSON.parse(r.plateBody).form.content
                            cellList.push(obj)
                        }
                    })
              
                    this.imgList = cellList

                })                
            },		
	},
}
</script>

<style lang='less' scoped>

.exhibition-range {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #EEE;
	.reverseDirect {
		flex-direction: row-reverse;
	}
	.main {
		// width: 1440px;
		// margin: 0 auto;
		background-color: #FFF;

		.title {
			height: 48px;
			font-size: 26px;
			font-family: Source Han Sans CN-Bold, Source Han Sans CN;
			font-weight: 700;
			color: #0C266D;
			line-height: 48px;       
			margin: 48px 0 40px 0;
		}

		.content1 {
			width: 100%;
			.content-block {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				padding: 136px 206px 64px 224px;
				box-sizing: border-box;
				background-color: #FFF;
				&:nth-child(2) {
					padding-top: 0;
				}

				.exhibit {
					max-width: 550px;

					.exhibit-title {
						width: 100%;
						color: #0B43BC;
						font-family: Source Han Sans CN-Regular, Source Han Sans CN;
						font-size: 24px;
						text-align: left;
						line-height: 48px;

					}
					.exhibit-introduce {
						width: 460px;
						min-height: 100px;
						margin-top: 12px;
						padding: 0 10px;
						color: #000;
						font-family: Source Han Sans CN-Regular, Source Han Sans CN;
						font-size: 16px;
						// text-indent: 2em;
						text-align: justify;
						line-height: 32px;
					}
				}

				.content-image {
					width: 400px;
					height: 300px;
					background-color: silver;
				}
			}
			.blocktype2 {
				padding-top: 80px;
				padding-bottom: 80px;
				background-color: #F7F7F7;
			}
		}
	}

}
    @media screen and (max-width: 1300px) { // 非pc端
        .content-block {
            padding: 26px 46px 22px 58px !important;
        }
		.needReverse {
			display: flex !important;
			// flex-direction: column-reverse;
		}
		.exhibit {
			// max-width: 280px !important; 
			.exhibit-introduce {
				// width: 240px !important;
				min-height: 256px;
				margin-top: 12px;
			}

		}
		.content-image {
            width: 400px !important;
            height: 300px !important;
		}
    }

    @media screen and (max-width: 950px) { // 非pc端
        .content-image {
            width: 300px !important;
            height: 250px !important;
        }
		.needReverse {
			display: flex !important;
			flex-direction: column-reverse;
		}
    }
    @media screen and (max-width: 800px) { // 非pc端
        .content-image {
            width: 250px !important;
            height: 180px !important;
        }
    }
    @media screen and (max-width: 750px) { // 非pc端
		.content-block {
			display: inline-block !important;
		}
		.needReverse {
			display: flex !important;
			flex-direction: column-reverse;
		}
		.exhibit-introduce {
            width: 100% !important;
        }
    }	
</style>
