<template>
    <div class="Forumlive">
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="12"></Header>
        <h3>展会直播</h3>
        <img :src="require('@/assets/images/empty__image@2x.png')" alt="">
        <div class="text">此功能建设中，敬请期待！</div>
    </div>
</template>

<script>
export default {
    name: 'ExhibitionprojectForumlive',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),

        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.Forumlive {
    min-height: 100vh;
    h3 {
        height: 48px;
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;       
        padding-bottom: 32px;
        margin-top: 48px;
    }
    img {
        width: 160px;
        height: 173px;
    }
    .text {
        height: 30px;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        margin-bottom: 180px;
    }
}
</style>