import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//导入lib-flexible
import './assets/js/flexible.js'
// 导入全局注册的组件
import './components/index.js'
// import Vconsole from 'vconsole';
// const vConsole = new Vconsole()
// Vue.use(vConsole)
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
var options = {
  fullscreenEl: false
}
Vue.use(preview, options)
Vue.use(preview)
// import {
//   indexAccess,
// } from '@/api/exhibition.js'
// 访问统计计数接口
// indexAccess();
import './api/mock.js'

// 引入共同css
import './styles/index.scss'
// 引入通用样式
import './styles/common.scss'
import axios from 'axios'
window.axios = axios
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
