<template>
    <div class="cooperateMedia">
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="11"></Header>
        <!-- <div v-for="(item, i1) in data" :key="i1">
            <h3>{{item.typeName}}</h3>
            <div class="cooperateSlide">
                <template v-if="isMobile">
                    <swiper :options="swiperOption11">
                        <swiper-slide v-for="(kitem, k) in item.mobileMediaList" :key="k">
                            <div class="mediaList">
                                <div class="exibox flex_fcc" v-for="(ksonitem, i) in kitem" :key="i">
                                    <a @click="toLink(ksonitem.address)" class="flex_column_cc" target="_blank">
                                        <img :src="ksonitem.fileList[0].response.data.link" alt="">
                                        <span>{{ksonitem.name}}</span>
                                    </a>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </template>
                <template v-else>
                    <swiper :options="swiperOption">
                        <swiper-slide v-for="(kitem, k) in item.pcMediaList" :key="k">
                            <div class="mediaList">
                                <div class="exibox flex_fcc" v-for="(ksonitem, i) in kitem" :key="i">
                                    <a @click="toLink(ksonitem.address)" class="flex_column_cc" target="_blank">
                                        <img :src="ksonitem.fileList[0].response.data.link" alt="">
                                        <span>{{ksonitem.name}}</span>
                                    </a>
                                </div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                        <div class="swiper-button-prev swiper-button-black" slot="button-prev"></div>
                        <div class="swiper-button-next swiper-button-black" slot="button-next"></div>
                    </swiper>
                </template>
            </div>
        </div> -->
        <!-- 以上为对接了接口 -->
        <h3>大众媒体</h3>
        <div class="cooperateSlide">
            <template v-if="isMobile">
                <swiper :options="swiperOption11">
                    <swiper-slide v-for="(kitem, k) in publicImgList11" :key="k">
                        <!-- <img :src="item.imgurl" width="100%" @click="onClick(item)" /> -->
                        <div class="mediaList">
                            <div class="exibox flex_fcc" v-for="(item, i) in kitem" :key="i">
                                <a @click="toLink(item.href)" class="flex_column_cc" target="_blank">
                                    <img :src="item.imgurl" alt="">
                                    <span>{{item.name}}</span>
                                </a>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </template>
            <template v-else>
                <swiper :options="swiperOption">
                    <swiper-slide v-for="(kitem, k) in publicImgList" :key="k">
                        <!-- <img :src="item.imgurl" width="100%" @click="onClick(item)" /> -->
                        <div class="mediaList">
                            <div class="exibox flex_fcc" v-for="(item, i) in publicImgList[k]" :key="i">
                                <a @click="toLink(item.href)" class="flex_column_cc" target="_blank">
                                    <img :src="item.imgurl" alt="">
                                    <span>{{item.name}}</span>
                                </a>
                            </div>
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                    <div class="swiper-button-prev swiper-button-black" slot="button-prev"></div>
                    <div class="swiper-button-next swiper-button-black" slot="button-next"></div>
                </swiper>
            </template>
        </div>
        <h3>行业媒体</h3>
        <div class="cooperateSlide">
            <template v-if="isMobile">
                <swiper :options="swiperOption22">
                    <swiper-slide v-for="(kitem, k) in industryImgList22" :key="k">
                        <!-- <img :src="item.imgurl" width="100%" @click="onClick(item)" /> -->
                        <div class="mediaList">
                            <div class="exibox flex_column_cc" v-for="(item, i) in kitem" :key="i">
                                <a @click="toLink(item.href)" class="flex_column_cc" target="_blank">
                                    <img :src="item.imgurl" alt="">
                                    <span>{{item.name}}</span>
                                </a>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </template>
            <template v-else>
                <swiper :options="swiperOption">
                    <swiper-slide v-for="(kitem, k) in industryImgList" :key="k">
                        <!-- <img :src="item.imgurl" width="100%" @click="onClick(item)" /> -->
                        <div class="mediaList">
                            <div class="exibox flex_column_cc" v-for="(item, i) in industryImgList[k]" :key="i">
                                <a @click="toLink(item.href)" class="flex_column_cc" target="_blank">
                                    <img :src="item.imgurl" alt="">
                                    <span>{{item.name}}</span>
                                </a>
                            </div>
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                    <div class="swiper-button-prev swiper-button-black" slot="button-prev"></div>
                    <div class="swiper-button-next swiper-button-black" slot="button-next"></div>
                </swiper>
            </template>

        </div>
    </div>
</template>

<script>
    import {
        mediaExpand,
    } from '@/api/exhibition'
    // 引入插件
    import {
        swiper,
        swiperSlide
    } from "vue-awesome-swiper";
    import "swiper/dist/css/swiper.css";

    export default {
        name: 'ExhibitionprojectCooperatemedia',
        components: {
            swiper,
            swiperSlide
        },
        data() {
            return {
                headImgUrl: require('@/assets/images/header.png'),
                mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
                isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
                swiperOption: {
                    loop: false,
                    autoplay: {
                        delay: 8000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false
                    },
                    // 显示分页
                    // pagination: {
                    //   el: ".swiper-pagination",
                    //   clickable: true //允许分页点击跳转
                    // },
                    // 设置点击箭头
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }
                },
                swiperOption22: {
                    loop: true,
                    slidesPerView: 3,
                    spaceBetween: 3,
                    grid: {
                        fill: 'column',
                        rows: 2,
                    },
                    autoplay: {
                        delay: 2000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false
                    },
                },
                swiperOption11: {
                    loop: true,
                    slidesPerView: 3,
                    spaceBetween: 3,
                    grid: {
                        fill: 'column',
                        rows: 2,
                    },
                    autoplay: {
                        delay: 1500,
                        stopOnLastSlide: false,
                        disableOnInteraction: false,
                    },
                    // 显示分页
                    // pagination: {
                    //   el: ".swiper-pagination",
                    //   clickable: true //允许分页点击跳转
                    // },
                },
                industryImgList: [
                    [
                        {
                            imgurl: require('@/assets/images/20191226041909.jpg'),
                            href: 'https://www.chinametro.net/',
                            name: '《城市轨道交通》',
                        },      
                        {
                            imgurl: require('@/assets/images/20191226041916.jpg'),
                            href: '',
                            name: '《城市轨道交通研究》',
                        },  
                        {
                            imgurl: require('@/assets/images/20191226042104.jpg'),
                            href: '',
                            name: '《现代城市轨道交通》',
                        },   
                        {
                            imgurl: require('@/assets/images/20191226042053.png'),
                            href: '',
                            name: '《世界轨道交通》',
                        },                                                                                     
                        {
                            imgurl: require('@/assets/images/20200921113539.jpeg'),
                            href: 'http://www.ecrrc.com/exhibition/',
                            name: 'e车网',
                        },

                        {
                            imgurl: require('@/assets/images/20230613.png'),
                            href: '',
                            name: '中国城市轨道交通网',
                        },
                        {
                            imgurl: require('@/assets/images/20191226042000.gif'),
                            href: '',
                            name: '世界轨道交通资讯网',
                        },
                        {
                            imgurl: require('@/assets/images/20191226042007.jpg'),
                            href: '',
                            name: '《现代城市轨道交通》',
                        },

                    ],
                    [
                        {
                            imgurl: require('@/assets/images/zgjtb.jpg'),
                            href: '',
                            name: '中国交通报',
                        },   
                        {
                            imgurl: require('@/assets/images/20191226042024.png'),
                            href: '',
                            name: '《都市快轨交通》',
                        },                                                                                     
                        {
                            imgurl: require('@/assets/images/20230320103105.jpg'),
                            href: '',
                            name: '《机车电传动》',
                        },
                        {
                            imgurl: require('@/assets/images/20191226042014.jpg'),
                            href: 'http://rttoday.cn/',
                            name: '《今日轨道交通》',
                        },   
                        {
                            imgurl: require('@/assets/images/rtlogo.png'),
                            href: 'http://www.rt-media.cn/list/713.html',
                            name: 'RT轨道交通',
                        },  
                        {
                            imgurl: require('@/assets/images/20191226042031.jpg'),
                            href: '',
                            name: '《高速铁路与轨道交通》',
                        },                           

                    ],
                ],
                industryImgList22: [
                        [{
                            imgurl: require('@/assets/images/20191226041909.jpg'),
                            href: 'https://www.chinametro.net/',
                            name: '《城市轨道交通》',
                        }],      
                        [{
                            imgurl: require('@/assets/images/20191226041916.jpg'),
                            href: '',
                            name: '《城市轨道交通研究》',
                        }],  
                        [{
                            imgurl: require('@/assets/images/20191226042104.jpg'),
                            href: '',
                            name: '《现代城市轨道交通》',
                        }],   
                        [{
                            imgurl: require('@/assets/images/20191226042053.png'),
                            href: '',
                            name: '《世界轨道交通》',
                        }],                                                                                     
                        [{
                            imgurl: require('@/assets/images/20200921113539.jpeg'),
                            href: 'http://www.ecrrc.com/exhibition/',
                            name: 'e车网',
                        }],

                        [{
                            imgurl: require('@/assets/images/20230613.png'),
                            href: '',
                            name: '中国城市轨道交通网',
                        }],
                        [{
                            imgurl: require('@/assets/images/20191226042000.gif'),
                            href: '',
                            name: '世界轨道交通资讯网',
                        }],
                        [{
                            imgurl: require('@/assets/images/20191226042007.jpg'),
                            href: '',
                            name: '《现代城市轨道交通》',
                        }],
                        [{
                            imgurl: require('@/assets/images/zgjtb.jpg'),
                            href: '',
                            name: '中国交通报',
                        }],   
                        [{
                            imgurl: require('@/assets/images/20191226042024.png'),
                            href: '',
                            name: '《都市快轨交通》',
                        }],                                                                                     
                        [{
                            imgurl: require('@/assets/images/20230320103105.jpg'),
                            href: '',
                            name: '《机车电传动》',
                        }],
                        [{
                            imgurl: require('@/assets/images/20191226042014.jpg'),
                            href: 'http://rttoday.cn/',
                            name: '《今日轨道交通》',
                        }],   
                        [{
                            imgurl: require('@/assets/images/rtlogo.png'),
                            href: 'http://www.rt-media.cn/list/713.html',
                            name: 'RT轨道交通',
                        }],  
                        [{
                            imgurl: require('@/assets/images/20191226042031.jpg'),
                            href: '',
                            name: '《高速铁路与轨道交通》',
                        }],   


                ],
                publicImgList: [
                    [{
                            imgurl: require('@/assets/images/2023060101.png'),
                            href: 'https://dzxf.dzwww.com/tt/gdxw/202305/t20230525_12014371.htm',
                            name: '大众网',
                        },
                        {
                            imgurl: require('@/assets/images/20220623033237.png'),
                            href: 'http://business.china.com.cn/2023-05/26/content_42384203.html',
                            name: '中国网',
                        },
                        {
                            imgurl: require('@/assets/images/20220623042840.png'),
                            href: 'https://life.china.com/2023-05/25/content_210273.html',
                            name: '中华网',
                        },
                        {
                            imgurl: require('@/assets/images/20220623041916.png'),
                            href: 'https://tech.chinadaily.com.cn/a/202305/26/WS647047b1a3105379893760fd.html',
                            name: '中国日报',
                        },
                        {
                            imgurl: require('@/assets/images/20220623042300.png'),
                            href: 'https://nb.ifeng.com/c/8Q4hH0lKuNi',
                            name: '凤凰网',
                        },
                        {
                            imgurl: require('@/assets/images/2023060102.png'),
                            href: 'https://www.toutiao.com/article/7237033940064682529/',
                            name: '今日头条',
                        },
                        {
                            imgurl: require('@/assets/images/2023060103.png'),
                            href: 'http://www.rmjtxw.com/news/hyxw/207880.html',
                            name: '人民交通网',
                        },
                        {
                            imgurl: require('@/assets/images/2023060104.png'),
                            href: 'http://www.jiaotongwang.cn/shouye/zixun/keji/2023/0525/475001.html?WebShieldSessionVerify=MpqWi7FuU6RMJVRqmLd1',
                            name: '中国交通在线',
                        },
                    ],
                    [{
                            imgurl: require('@/assets/images/2023060105.png'),
                            href: 'http://hkzc.xznqc.cn/shehui/1336.html',
                            name: '香港资讯网',
                        },
                        {
                            imgurl: require('@/assets/images/2023060106.png'),
                            href: 'http://cjzgb.cn/c1e/Njc2NzI2Nw==.html',
                            name: '财经中国报',
                        },
                        {
                            imgurl: require('@/assets/images/2023060107.png'),
                            href: 'http://www.gdcomw.com/xinwen/971036.html',
                            name: '广东财经网',
                        },
                        {
                            imgurl: require('@/assets/images/2023060108.png'),
                            href: 'http://keji.renmen.xyz/shehui/3252.html',
                            name: '科技中国',
                        },
                        {
                            imgurl: require('@/assets/images/20220623030821.png'),
                            href: '',
                            name: '央视新闻',
                        },
                        {
                            imgurl: require('@/assets/images/20220623031436.png'),
                            href: '',
                            name: '人民日报',
                        },
                        {
                            imgurl: require('@/assets/images/20220623031600.png'),
                            href: '',
                            name: '人民网',
                        },
                        {
                            imgurl: require('@/assets/images/20220623032646.png'),
                            href: '',
                            name: '经济日报',
                        },
                    ],
                    [

                        {
                            imgurl: require('@/assets/images/20220623033044.png'),
                            href: '',
                            name: '中国新闻网',
                        },
                        {
                            imgurl: require('@/assets/images/20220623034829.png'),
                            href: '',
                            name: '北京晚报',
                        },
                        {
                            imgurl: require('@/assets/images/20220623034948.png'),
                            href: '',
                            name: '中国经济网',
                        },
                        {
                            imgurl: require('@/assets/images/20220623035944.png'),
                            href: '',
                            name: '中国发展网',
                        },
                        {
                            imgurl: require('@/assets/images/20220623044204.png'),
                            href: '',
                            name: '中国报道',
                        },
                        {
                            imgurl: require('@/assets/images/nfrb.jpg'),
                            href: '',
                            name: '南方日报',
                        },
                        {
                            imgurl: require('@/assets/images/ycwb.jpg'),
                            href: '',
                            name: '羊城晚报',
                        },
                        {
                            imgurl: require('@/assets/images/20220623042655.png'),
                            href: '',
                            name: '广州日报',
                        },
                    ],
                    [{
                            imgurl: require('@/assets/images/nfdsb.jpg'),
                            href: '',
                            name: '南方都市报',
                        },
                        {
                            imgurl: require('@/assets/images/xkb.jpg'),
                            href: '',
                            name: '新快报',
                        },
                        {
                            imgurl: require('@/assets/images/xxsb.jpg'),
                            href: '',
                            name: '信息时报',
                        },
                        {
                            imgurl: require('@/assets/images/gddst.jpg'),
                            href: '',
                            name: '广东电视台',
                        },
                        {
                            imgurl: require('@/assets/images/gzgbdst.jpg'),
                            href: '',
                            name: '广州电视台',
                        },
                        {
                            imgurl: require('@/assets/images/gddt.jpg'),
                            href: '',
                            name: '广东电台',
                        },
                        {
                            imgurl: require('@/assets/images/gzdt.jpg'),
                            href: '',
                            name: '广州电台',
                        },
                        {
                            imgurl: require('@/assets/images/gzdtjtt.jpg'),
                            href: '',
                            name: '广州电台交通台',
                        },
                    ],
                    [{
                            imgurl: require('@/assets/images/ycjtdt.jpg'),
                            href: '',
                            name: '羊城交通电台',
                        },
                        {
                            imgurl: require('@/assets/images/ysdst.jpg'),
                            href: '',
                            name: '中央电视台',
                        },
                        {
                            imgurl: require('@/assets/images/xhs.jpg'),
                            href: '',
                            name: '新华社',
                        },
                        {
                            imgurl: require('@/assets/images/xhw.jpg'),
                            href: '',
                            name: '新华网',
                        },
                        {
                            imgurl: require('@/assets/images/zxs.png'),
                            href: '',
                            name: '中新社',
                        },
                        {
                            imgurl: require('@/assets/images/xgsb.jpg'),
                            href: '',
                            name: '香港商报',
                        },
                        {
                            imgurl: require('@/assets/images/kjrb.jpg'),
                            href: '',
                            name: '科技日报',
                        },
                        {
                            imgurl: require('@/assets/images/xlgd.jpg'),
                            href: '',
                            name: '新浪广东',
                        },

                    ],
                ],
                publicImgList11: [
                    [{
                        imgurl: require('@/assets/images/2023060101.png'),
                        href: 'https://dzxf.dzwww.com/tt/gdxw/202305/t20230525_12014371.htm',
                        name: '大众网',
                    }, ],
                    [{
                        imgurl: require('@/assets/images/20220623033237.png'),
                        href: 'http://business.china.com.cn/2023-05/26/content_42384203.html',
                        name: '中国网',
                    }],
                    [{
                        imgurl: require('@/assets/images/20220623042840.png'),
                        href: 'https://life.china.com/2023-05/25/content_210273.html',
                        name: '中华网',
                    }],
                    [{
                        imgurl: require('@/assets/images/20220623041916.png'),
                        href: 'https://tech.chinadaily.com.cn/a/202305/26/WS647047b1a3105379893760fd.html',
                        name: '中国日报',
                    }],
                    [{
                        imgurl: require('@/assets/images/20220623042300.png'),
                        href: 'https://nb.ifeng.com/c/8Q4hH0lKuNi',
                        name: '凤凰网',
                    }],
                    [{
                        imgurl: require('@/assets/images/2023060102.png'),
                        href: 'https://www.toutiao.com/article/7237033940064682529/',
                        name: '今日头条',
                    }, ],
                    [{
                        imgurl: require('@/assets/images/2023060103.png'),
                        href: 'http://www.rmjtxw.com/news/hyxw/207880.html',
                        name: '人民交通网',
                    }, ],
                    [{
                        imgurl: require('@/assets/images/2023060104.png'),
                        href: 'http://www.jiaotongwang.cn/shouye/zixun/keji/2023/0525/475001.html?WebShieldSessionVerify=MpqWi7FuU6RMJVRqmLd1',
                        name: '中国交通在线',
                    }, ],
                    [{
                        imgurl: require('@/assets/images/2023060105.png'),
                        href: 'http://hkzc.xznqc.cn/shehui/1336.html',
                        name: '香港资讯网',
                    }],
                    [{
                        imgurl: require('@/assets/images/2023060106.png'),
                        href: 'http://cjzgb.cn/c1e/Njc2NzI2Nw==.html',
                        name: '财经中国报',
                    }, ],
                    [{
                        imgurl: require('@/assets/images/2023060107.png'),
                        href: 'http://www.gdcomw.com/xinwen/971036.html',
                        name: '广东财经网',
                    }, ],
                    [{
                        imgurl: require('@/assets/images/2023060108.png'),
                        href: 'http://keji.renmen.xyz/shehui/3252.html',
                        name: '科技中国',
                    }, ],

                    [{
                        imgurl: require('@/assets/images/20220623030821.png'),
                        href: '',
                        name: '央视新闻',
                    }],
                    [{
                        imgurl: require('@/assets/images/20220623031436.png'),
                        href: '',
                        name: '人民日报',
                    }],
                    [{
                        imgurl: require('@/assets/images/20220623031600.png'),
                        href: '',
                        name: '人民网',
                    }],
                    [{
                        imgurl: require('@/assets/images/20220623032646.png'),
                        href: '',
                        name: '经济日报',
                    }],
                    [{
                        imgurl: require('@/assets/images/20220623033044.png'),
                        href: '',
                        name: '中国新闻网',
                    }],
                    [{
                        imgurl: require('@/assets/images/20220623034829.png'),
                        href: '',
                        name: '北京晚报',
                    }],
                    [{
                        imgurl: require('@/assets/images/20220623034948.png'),
                        href: '',
                        name: '中国经济网',
                    }, ],
                    [{
                        imgurl: require('@/assets/images/20220623035944.png'),
                        href: '',
                        name: '中国发展网',
                    }],
                    [{
                        imgurl: require('@/assets/images/20220623044204.png'),
                        href: '',
                        name: '中国报道',
                    }],
                    [{
                        imgurl: require('@/assets/images/nfrb.jpg'),
                        href: '',
                        name: '南方日报',
                    }],
                    [{
                        imgurl: require('@/assets/images/ycwb.jpg'),
                        href: '',
                        name: '羊城晚报',
                    }],
                    [{
                        imgurl: require('@/assets/images/20220623042655.png'),
                        href: '',
                        name: '广州日报',
                    }, ],
                    [{
                        imgurl: require('@/assets/images/nfdsb.jpg'),
                        href: '',
                        name: '南方都市报',
                    }],
                    [{
                        imgurl: require('@/assets/images/xkb.jpg'),
                        href: '',
                        name: '新快报',
                    }],
                    [{
                        imgurl: require('@/assets/images/xxsb.jpg'),
                        href: '',
                        name: '信息时报',
                    }],
                    [{
                        imgurl: require('@/assets/images/gddst.jpg'),
                        href: '',
                        name: '广东电视台',
                    }],
                    [{
                        imgurl: require('@/assets/images/gzgbdst.jpg'),
                        href: '',
                        name: '广州电视台',
                    }],
                    [{
                        imgurl: require('@/assets/images/gddt.jpg'),
                        href: '',
                        name: '广东电台',
                    }],
                    [{
                        imgurl: require('@/assets/images/gzdt.jpg'),
                        href: '',
                        name: '广州电台',
                    }],
                    [{
                        imgurl: require('@/assets/images/gzdtjtt.jpg'),
                        href: '',
                        name: '广州电台交通台',
                    }, ],
                    [{
                        imgurl: require('@/assets/images/ycjtdt.jpg'),
                        href: '',
                        name: '羊城交通电台',
                    }],
                    [{
                        imgurl: require('@/assets/images/ysdst.jpg'),
                        href: '',
                        name: '中央电视台',
                    }],
                    [{
                        imgurl: require('@/assets/images/xhs.jpg'),
                        href: '',
                        name: '新华社',
                    }],
                    [{
                        imgurl: require('@/assets/images/xhw.jpg'),
                        href: '',
                        name: '新华网',
                    }],
                    [{
                        imgurl: require('@/assets/images/zxs.png'),
                        href: '',
                        name: '中新社',
                    }],
                    [{
                        imgurl: require('@/assets/images/xgsb.jpg'),
                        href: '',
                        name: '香港商报',
                    }],
                    [{
                        imgurl: require('@/assets/images/kjrb.jpg'),
                        href: '',
                        name: '科技日报',
                    }],
                    [{
                            imgurl: require('@/assets/images/xlgd.jpg'),
                            href: '',
                            name: '新浪广东',
                        },

                    ],
                ],
                data:  [],
            };
        },

        mounted() {
            var that = this
            this.$root.$on('upDateView', () => {
                that.isMobile = JSON.parse(sessionStorage.getItem('isMobile'))
            })
            // this.exhibitiondisplaycommonGetList()
        },

        methods: {
            toLink(href) {
                if (href != '') {
                    window.open(href)
                }
            },
            exhibitiondisplaycommonGetList() {
                mediaExpand().then(res=>{
                    var data = res.data.data
                    var a2 = []
                    data.map(r=>{
                        r.mediaList = JSON.parse(r.mediaList)
                        var arr = []
                        var secIndex = 0
                        arr[secIndex] = []
                        r.mediaList.map((r, i)=>{ // 分为一页8条数据
                            if ((i+1) % 8 == 0) { // 余8
                                arr[secIndex].push(r)
                                secIndex += 1 // 二维数组下标
                                arr[secIndex] = []
                            } else {
                                arr[secIndex].push(r)
                            }
                        })
                        arr[arr.length - 1].length == 0 ? arr.splice(arr.length - 1, 1) : '' // 去除最后一个为空的数组
                        // this.total = data.length
                        r.pcMediaList = arr
                        r.mediaList.map(r2=>{
                            a2.push([r2])
                        })
                        r.mobileMediaList = a2
                    })            
                    console.log('dataaaa',data);       
                    this.data = data
                })             
            },             
        },
    };
</script>

<style lang="scss" scoped>
    // 更改轮播图翻页icon样式
    ::v-deep .swiper-button-prev {
        left: 250px !important;
    }

    ::v-deep .swiper-button-next {
        right: 250px !important;
    }

    ::v-deep .swiper-button-prev,
    ::v-deep .swiper-button-next {
        width: 28px !important;
        height: 28px !important;
    }

    .cooperateMedia {
        h3 {
            height: 48px;
            font-size: 26px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 700;
            color: #0C266D;
            line-height: 48px;
            padding-bottom: 32px;
            margin-top: 48px;
        }

        .cooperateSlide {

            .mediaList {
                width: 960px;
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                align-content: flex-start;
            }

            .exibox {
                width: 222px;
                height: 177px;
                background: #FFFFFF;
                border-radius: 0px 0px 0px 0px;
                // border: 1px solid #CACACA;
                margin-bottom: 20px;

                // &:not(:nth-child(4n)) {
                //     margin-right: 20px;
                // }
                img {
                    width: 190px;
                    height: 116px;
                }

                span {
                    height: 24px;
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #000000;
                    line-height: 24px;
                    margin-top: 6px;

                }

                a {
                    cursor: pointer;
                }
            }
        }
    }

    @media screen and (max-width: 1300px) {

        // 非pc端
        .cooperateSlide {
            transform: scale(0.8);

            .exibox {
                // width: 180px !important;
                // height: 150px !important;
                background-color: transparent !important;

                img {
                    // width: 450px !important;
                    // height: 177px !important;
                }
            }

            .mediaList,
            .swiper-slide {
                // width: 450px !important;
            }
        }

        ::v-deep .swiper-button-prev {
            left: 70px !important;
        }

        ::v-deep .swiper-button-next {
            right: 70px !important;
        }
    }

    @media screen and (max-width: 1000px) { 
        // 非pc端
        .cooperateSlide {

            .mediaList,
            .swiper-slide {
                width: 450px !important;
            }
        }
    }

    @media screen and (max-width: 550px) {

        // 非pc端
        .cooperateSlide {
            .exibox {
                // width: 180px !important;
                // height: 150px !important;
                background-color: transparent !important;

                img {
                    width: 100% !important;
                    height: 100% !important;
                }
            }

            .mediaList,
            .swiper-slide {
                width: 240px !important;
            }
        }

        ::v-deep .swiper-button-prev {
            left: 70px !important;
        }

        ::v-deep .swiper-button-next {
            right: 70px !important;
        }
    }
</style>