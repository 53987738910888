<!--
 * @Author: huanggs huanggx@gzyct.com
 * @Date: 2023-05-15 10:51:24
 * @LastEditors: huanggs huanggx@gzyct.com
 * @LastEditTime: 2023-05-29 10:38:22
 * @FilePath: \exhibitionProject\src\views\mediaCenter\Mediamatrix.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="Mediamatrix">
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="17"></Header>
        <h3>媒体矩阵</h3>
        <!-- <div class="row flex_fcc row3 rowIn">
            <div class="cell flex_column_cc" @click="toLink(`${item.address}`)" v-for="(item, i) in cellList" :key="i">
                <div class="img">
                    <img :src="item.imgurl" alt="">
                </div>
                <span>{{item.name}}</span>
            </div>
        </div> -->
        <!-- 以上为对接接口了 -->
        <div class="row flex_fcc row3">
            <div class="cell flex_column_cc" @click="toLink('http://www.metrotrans.com.cn')">
                <div class="img">
                    <img :src="require('@/assets/images/bj81@2x.png')" alt="">
                </div>
                <span>北京轨道展</span>
            </div>
            <div class="cell flex_column_cc" @click="toLink('https://www.gzmtr.com/')">
                <div class="img">
                    <img :src="require('@/assets/images/gzdtLogo.jpg')" alt="">
                </div>
                <span>广州地铁</span>
            </div>
            <div class="cell flex_column_cc">
                <div class="img">
                    <img :src="require('@/assets/images/gz81@2x.png')" alt="">
                </div>
                <span>广州轨道展</span>
            </div>
        </div>
        <div class="row flex_fcc row3">
            <div class="cell flex_column_cc">
                <div class="img">
                    <img :src="require('@/assets/images/tod@2x.png')" alt="">
                </div>
                <span>TOD界</span>
            </div>
            <div class="cell flex_column_cc" @click="toLink('https://www.gzrtia.com/#/home')">
                <div class="img">
                    <img :src="require('@/assets/images/cylmLogo.png')" alt="">
                </div>
                <span>产业联盟</span>
            </div>            
            <div class="cell flex_column_cc" @click="toLink('https://appcrgimdwu7443.h5.xiaoeknow.com/p/decorate/homepage?entry=2&entry_type=2001')">
                <div class="img">
                    <img :src="require('@/assets/images/cgbt@2x.png')" alt="">
                </div>
                <span>城轨讲堂</span>
            </div>
        </div>
        <div class="row flex_fcc row3">
            <div class="cell flex_column_cc"  @click="toLink('https://mp.weixin.qq.com/s/70pHLCHuRBTN-pIs3JMWZQ')">
                <div class="img">
                    <img :src="require('@/assets/images/qns@2x.png')" alt="">
                </div>
                <span>青年说</span>
            </div>
            <div class="cell flex_column_cc" @click="toLink('http://app.camet.org.cn/#/download')">
                <div class="img">
                    <img :src="require('@/assets/images/rmcg@2x.png')" alt="">
                </div>
                <span>人民城轨</span>
            </div>
            <div class="cell flex_column_cc">
                <div class="img">
                    <img :src="require('@/assets/images/csgdjt@2x.png')" alt="">
                </div>
                <span>城市轨道交通杂志</span>
            </div>
        </div>
    </div>
</template>

<script>
import {
    exhibitiondisplaycommonGetList,
} from '@/api/exhibition'
export default {
    name: 'ExhibitionprojectMediamatrix',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
            cellList: [],
        };
    },

    mounted() {
        // this.exhibitiondisplaycommonGetList()
    },

    methods: {
        toLink(url) {
            if (url != '') {
                window.open(url)
            }
        },
        exhibitiondisplaycommonGetList() {
            var param = {
                firstDirName: '媒体中心',
                secondDirName: '媒体矩阵',                
            }
            exhibitiondisplaycommonGetList(param).then(res=>{
                var data = res.data.data
                if (data[0].firstDirName=='媒体中心'&&data[0].secondDirName=='媒体矩阵') {
                    var obj = JSON.parse(data[0].plateBody)
                    obj.map(o=>{
                        o.imgurl = o.fileList[0].response.data.link  
                    })
                    this.cellList = obj
                }
            })
        },        
    },
};
</script>

<style lang="scss" scoped>
.Mediamatrix {
    .rowIn {
        max-width: 850px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;

    }
    h3 {
        height: 48px;
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;       
        padding-bottom: 32px;
        margin-top: 48px;
    }
    .row {
        margin-bottom: 20px;
        .cell {
            width: 222px;
            height: 177px;
            background: #FFFFFF;
            // border: 1px solid #CACACA;
            cursor: pointer;
            .img {
                width: 190px;
                height: 116px;
                display: flex;
                align-items: center;
                img {
                    width: 100%;
                    max-height: 100%;
                }
            }
            span {
                height: 24px;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #000000;
                line-height: 24px;
            }
        }
    }
    .row1 {
        .cell {
            &:nth-child(1) {
                margin-right: 20px;
            }
        }
    }
    .row2 {
        .cell {
            &:nth-child(1) {
                margin-right: 100px;
            }
        }
    }
    .row3 {
        .cell {
            // &:nth-child(1),
            // &:nth-child(2) {
                margin-right: 20px;
                // margin-bottom: 20px;
            // }
        }
    }
}
  @media screen and (max-width: 800px) { // 非pc端
	.Mediamatrix { 
        .row {
            .cell {
                width: 125px;
                height: 110px;
                .img {
                    width: 100%;
                    height: 96px;
                    img {
                        width: 100%;
                    }
                }
                span {
                    font-size: 12px;
                }
            }
        }
        .row2 {
            .cell {
                &:nth-child(1) {
                    margin-right: 10px;
                }
            }
        }  
        .row3 {
            .cell {
                &:nth-child(1),
                &:nth-child(2) {
                    margin-right: 10px;
                }
            }
        }      
	}
 }
</style>