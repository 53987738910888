<template>
    <div>
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="15"></Header>
        <h3>精彩图集</h3>
        <div class="picList">
            <div class="picCell" v-for="(item, i) in excellentPics[current-1]" :key="i">
                <img :src="item.url"  :large="item.url" preview="1" preview-text="" alt="" srcset="">
            </div>
        </div>
        <SelfPage @handleCurrentChange="handleCurrentChange" :total="total" :size="size"></SelfPage>
    </div>
</template>

<script>
import {
    exhibitiondisplayphotoList,
} from '@/api/exhibition'
export default {
    name: 'ExhibitionprojectWonderfulpic',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
            excellentPics: [
                // {
                //     url: require('@/assets/images/excellentPics/1.jpg'),
                // },
                [{
                    url: require('@/assets/images/20230630/9.1/1.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/2.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/3.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/4.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/5.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/6.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/7.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/8.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/9.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/10.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/11.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/12.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/13.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/14.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/15.jpg'),
                },],

                [{
                    url: require('@/assets/images/20230630/9.1/16.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/17.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/18.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/19.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/20.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/21.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/22.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/23.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/24.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/25.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/26.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/27.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/28.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/9.1/29.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/1.jpg'),
                }],
                [{
                    url: require('@/assets/images/20230630/10.1/2.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/3.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/4.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/5.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/6.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/7.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/8.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/9.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/10.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/11.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/12.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/13.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/14.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/15.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/16.jpg'),
                },],[
                {
                    url: require('@/assets/images/20230630/10.1/17.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/18.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/19.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/20.jpg'),
                },
                {
                    url: require('@/assets/images/20230630/10.1/21.jpg'),
                },]

            ],
            total: 50,
            current: 1,
            size: 15,            
        };
    },

    mounted() {
        // this.exhibitiondisplayphotoList()
    },

    methods: {
        exhibitiondisplayphotoList() {
            var param = {
                current: this.current,
                size: 15,
            }
            exhibitiondisplayphotoList(param).then(res=>{
                var data = res.data.data

                var list = data.records.length==0?[]:data.records.map(r=>{
                    var obj = {
                        name: JSON.parse(r.photoUrl).name,
                        url: JSON.parse(r.photoUrl).response.data.link,
                        fid: r.id
                    }
                    return obj
                })

                this.excellentPics = list
                this.total = data.total
            })
        },      
        handleCurrentChange(v) {
            this.current = v
            // this.exhibitiondisplayphotoList()
        },             
    },
};
</script>

<style lang="scss" scoped>
.picList {
    width: 940px;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;
    .picCell {
        width: 300px;
        height: 200px;
        background: #EFEFEF;
        margin-right: 10px;
        margin-bottom: 20px;
        &:nth-child(3n) {
            // margin-right: 0;
        }
        .el-image, img {
            width: 300px;
            height: 200px;
        }
    }
}
    h3 {
        height: 48px;
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;       
        margin-bottom: 32px;
        margin-top: 48px;
    }
    @media screen and (max-width: 1300px) { // 非pc端
        .picList {
            width: 100%;
            margin: 0 auto;
            justify-content: center;         
            .picCell, .el-image, img {
                width: 150px !important;
                height: 100px !important;
            }


        }
    }
    @media screen and (max-width: 600px) { // 非pc端
        .picList {
            width: 95%;
            margin: 0 auto;
            justify-content: space-around;
            align-content: center;
            flex-direction: row;  
            flex-wrap: wrap;
            .picCell, .el-image, img {
                width: 150px !important;
                height: 100px !important;
            }
            .picCell {
                margin-right: 0px;
            }
        }
    }
</style>