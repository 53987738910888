import Vue from 'vue'
import Navigation from './navigation.vue'
import Banner from './banner.vue'
import Header from './header.vue'
import Footer from './footer.vue'
import SelfPage from './selfPage.vue'
import Empty from './empty.vue'

// 注册全局容器
Vue.component('Navigation', Navigation);
Vue.component('Banner', Banner);
Vue.component('Header', Header);
Vue.component('Footer', Footer);
Vue.component('SelfPage', SelfPage);
Vue.component('Empty', Empty);
