
import request from '@/router/axios';

// 文件类型列表展开
export const downloadListExpand = (params) => {
  return request({
    url: '/qyc/blade-exhibition/download/list-expand',
    method: 'get',
    params: {
      ...params
    }
  })
}
// 媒体类型列表展开
export const mediaExpand = (params) => {
  return request({
    url: '/qyc/blade-exhibition/media/list-expand',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 分页
export const exhibitiondisplaynewsList = (params) => {
  return request({
    url: '/qyc/blade-exhibition/exhibitiondisplaynews/list',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 展位预订管理接口
// 展位预定
export const exhibitionstallappointApply = (params) => {
    return request({
      url: '/qyc/blade-exhibition/exhibitionstallappoint/apply',
      method: 'post',
      data: params
    })
  }

// 访问统计计数接口
export const indexAccess = (params) => {
    return request({
      url: '/qyc/blade-exhibition/exhibition-index/index-access',
      method: 'get',
      params: {
        ...params
      }
    })
  }

// 批量获取内容
export const exhibitiondisplaycommonGetList = (params) => {
  return request({
    url: '/qyc/blade-exhibition/exhibitiondisplaycommon/get-list',
    method: 'get',
    params: {
      ...params
    }
  })
}
  
// 精彩图集
// 分页
export const exhibitiondisplayphotoList = (params) => {
  return request({
    url: '/qyc/blade-exhibition/exhibitiondisplayphoto/list',
    method: 'get',
    params: {
      ...params
    }
  })
}