<template>
    <div class="Trafficguide">
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="9"></Header>
        <div class="body">
            <!-- <div v-html="html"></div> -->
            <!-- 以上为对接接口了 -->	            
            <h3>交通指引</h3>
            <!-- <div class="time">发布时间：2018-12-25</div> -->
            <img :src="require('@/assets/images/W020230223585145432560.jpg')"  :large="require('@/assets/images/W020230223585145432560.jpg')" preview="1" preview-text="" alt="" srcset="">
            <h4>地铁</h4>
            <div class="text">
            您可以乘坐地铁8号线到广交会展馆。从地铁新港东站A出入口可到广交会展馆A区，从地铁琶洲站A、B出入口可到广交会展馆B区，从地铁琶洲站C出入口向西步行300米可到广交会展馆C区。<br/>
            <br/>
            路线：机场北站/南站---新港东/琶洲站<br/>
            3号线（北延长线）机场北站（2号航站楼）/机场南站（1号航站楼）- 体育西路站（转乘3号线） - 客村站（转乘8号线）- 新港东站（广交会展馆A区）/琶洲站（广交会展馆B区和C区）<br/>
            <br/>
            路线：火车站到广交会展馆<br/>
            从广州火车站：乘坐地铁2号线（广州南站方向）至昌岗站，换8号线（万胜围站方向），在新港东站出口（A区）或琶洲站（B区或C）。<br/>
            从广州火车东站：乘坐地铁3号线（番禺广场站方向）到客村站，换8号线（万胜围站方向），在新港东站出口（A区）或琶洲站（B或C区）。<br/>
            从广州南站：乘坐地铁2号线（嘉禾望岗站方向）至昌岗站，换8号线（万胜围站方向），并在新港东路站（可到展馆A区）或琶洲站（可到展馆B区和C区）下车。<br/>
            </div>      
            <img :src="require('@/assets/images/gzdtxl.jpg')"  :large="require('@/assets/images/gzdtxl.jpg')" preview="1" preview-text="" alt="" srcset="">   
             <h4>出租车</h4>
            <h5>
                出租车是广州公共交通的重要组成部分，方便快捷,扬手即停，按表收费。<br/>
                温馨提示：出租车只能在展馆A区的展场中路出租车上客通道和展馆C区展馆东侧上客点上落客，其他路段不允许上落客。
            </h5>           
            <h4>自驾车</h4>
            <img :src="require('@/assets/images/zjcxlt.png')"  :large="require('@/assets/images/zjcxlt.png')" preview="1" preview-text="" alt="" srcset="">



            <img :src="require('@/assets/images/jtzy.png')"  :large="require('@/assets/images/jtzy.png')" preview="1" preview-text="" alt="" srcset="">

            <h4>展览馆位置及服务</h4>
            <h5>
            中国进出口商品交易会展馆B区 <br/>
            地址：中国广州海珠区阅江中路382号
            </h5>
            <el-row class="head">
                <el-col :span="8">服务项目</el-col>
                <el-col :span="8">地点</el-col>
                <el-col :span="8">服务内容</el-col>
            </el-row>
            <el-row class="body">
                <el-col :span="8">商务中心</el-col>
                <el-col :span="8">珠江散步道 9.2 馆门口（9-3 柜台）</el-col>
                <el-col :span="8">传真，复印，上网，打印及电话卡服务</el-col>
            </el-row>
            <el-row class="body">
                <el-col :span="8">中国邮政</el-col>
                <el-col :span="8">珠江散步道 13.2 馆门口（13-2 柜台）</el-col>
                <el-col :span="8">提供特快专递、邮政等服务</el-col>
            </el-row>
            <el-row class="body">
                <el-col :span="8">旅游公司</el-col>
                <el-col :span="8">珠江散步道 10.2 馆门口（10-4 柜台）</el-col>
                <el-col :span="8">订购飞机票、火车票等服务</el-col>
            </el-row>
        </div>

    </div>
</template>

<script>
    import {
        exhibitiondisplaycommonGetList,
    } from '@/api/exhibition'
export default {
    name: 'ExhibitionprojectTrafficguide',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
            html: '',
        };
    },

    mounted() {
        // this.exhibitiondisplaycommonGetList()
    },

    methods: {
            exhibitiondisplaycommonGetList() {
                var param2 = {
                    firstDirName: '观众服务',
                    secondDirName: '交通指引',
                }

                exhibitiondisplaycommonGetList(param2).then(res=>{
					var data = res.data.data
					var html = data[0].plateBody
					this.html = html

                })                
            },           
    },
};
</script>

<style lang="scss" scoped>
.Trafficguide {
    .body {
        max-width: 850px;  
        margin: 0 auto;  
    }
    .time {        
        height: 30px;
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        color: #000000;
        line-height: 30px;
        margin-top: 48px;

    }
    h3 {
        height: 48px;
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;        
    }
    h4 {
        height: 30px;
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #000000;
        line-height: 30px;
        margin-top: 48px;
    }
    h5 {
        font-size: 16px;
        text-align: justify;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        margin-top: 12px;
    }
    .text {
        width: 100%;
        max-width: 850px;  
        margin: 0 auto;  
        // height: 420px;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        text-align: justify;
    }
    .el-row {
        width: 100%;
        max-width: 850px;  
        margin: 0 auto;  
        height: 54px;    
        line-height: 54px;    
        border-top: 1px solid #D8D8D8;
        &:last-child {
            border-bottom: 1px solid #D8D8D8;
            margin-bottom: 48px;
        }
    }
    .head {
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #727272;
        margin-top: 25px;
    }
    .body {
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
    }
    img, .el-image {
        margin-top: 12px;
        width: 100%;
        max-width: 850px;
        margin-bottom: 12px;
    }
}
@media screen and (max-width: 1300px) { // 非pc端
    .el-row {
        // transform: scale(0.8); 
    }
    .head {
        font-size: 12px !important;
    }
    .body {
        font-size: 12px !important;
        line-height: 27px !important;   
        .el-col {
            &:nth-child(3) {
                text-indent: 15px;
            }
        }
    }
    h5 {
        padding: 0 10px !important;
    }
    .text {
        width: calc(100% - 20px) !important;
        padding: 0 10px !important;    
    }    

}
</style>