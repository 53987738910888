<template>
    <div class="Onlineshow">
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="Number(titleIndex)" :titleName="titleName"></Header>
    
        <!-- <OnlineShowDetail v-if="showDetail&&!showProductDetail&&!showProductList" @toDetail="toProdcutDetail"></OnlineShowDetail>
        <OnlineProductDetail v-if="showProductDetail&&!showDetail&&!showProductList"></OnlineProductDetail>
        <OnlineProductList v-if="showProductList&&!showDetail&&!showProductDetail" @toDetail="toProdcutDetail"></OnlineProductList> -->
        <h3>参展商推荐</h3>
        <div class="exibitors">
            <div class="exibox flex_column_cc" v-for="(item,i) in zhanshangList" :key="i" @click="toShowDetail(null, item.id)">
                <div class="img">
                    <img :src="item.logo" alt="">
                </div>
                <span>{{item.zsmc}}</span>
            </div>
            <Empty v-show="!zhanshangList.length"></Empty>
        </div>    
        <SelfPage :total="total" :size="12" @handleCurrentChange="handleCurrentChange" v-show="zhanshangList.length" prevText="上一页" nextText="下一页"></SelfPage>   

        <h3>参展商品</h3>
        <div class="productList">
            <!-- <div class="p_cell" v-for="(item, i) in categoryList" :key="i" @click="toShowDetail('product', item)">
                <img :src="item.img" alt="">
                <div class="p_title">（{{i+1}}）{{item.name}}</div>
            </div> -->
            <!-- 以上为对接口 -->
            <div class="p_cell" v-for="(item, i) in categoryList" :key="i" @click="toShowDetail('product', item)">
                <img :src="require(`@/assets/images/exhiRange${((i>12?0:i)+1)}.png`)" alt="">
                <div class="p_title">（{{i+1}}）{{item}}</div>
            </div>
        </div>             
    </div>
</template>

<script>
import {
    getExihibitionList,
    getCategory,
} from '@/api/home'
import OnlineShowDetail from './onlineShowDetail.vue'
import OnlineProductDetail from './onlineProductDetail.vue'
import OnlineProductList from './onlineProductList.vue'
export default {
    name: 'ExhibitionprojectOnlineshow',
    components: {
        OnlineShowDetail,
        OnlineProductDetail,
        OnlineProductList,
    },
    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
            showDetail: false,
            showProductDetail: false,
            showProductList: false,
            titleIndex: 14,
            titleName: null,
            zhanshangList: [],
            categoryList: [],
            page: 1,
            size: 12,
            total: 0,
        };
    },

    watch: {

    },

    mounted() {
        this.getExihibitionList()
        this.getCategory()
    },

    methods: {
        
        getCategory() {
            getCategory().then(res=>{

                // var param2 = {
                //     firstDirName: '展商服务',
                //     secondDirName: '展品范围',
                // }
                // var cellList = []
                // exhibitiondisplaycommonGetList(param2).then(res2=>{
                //     var data = res2.data.data
                //     data.map(r=>{
                //         if (r.firstDirName=='展商服务'&&r.secondDirName=='展品范围') {
                //             var obj = JSON.parse(r.plateBody)
                //             obj.form.fileList[0].url = JSON.parse(r.plateBody).form.fileList[0].response.data.link   
                //             obj.id = r.id    
                //             obj.imgurl = JSON.parse(r.plateBody).form.fileList[0].response.data.link   
                //             obj.h2 = JSON.parse(r.plateBody).form.title
                //             obj.h5 = JSON.parse(r.plateBody).form.content
                //             cellList.push(obj)
                //         }
                //     })
                //     var dataout = res.data
                //     var arr = []
                //     dataout.zhanqu.map((r, i)=>{
                //         arr.push({
                //             name: r,
                //             img: ''
                //         })
                //     })  
                //     arr.map(ar=>{
                //         cellList.map(ce=>{
                //             if (ar.name==ce.h2) {
                //                 ar.img = ce.imgurl
                //             }
                //         })
                //     })  
                //     this.categoryList = arr          
                // })  


                var data = res.data
                var arr = []
                data.zhanqu.map((r, i)=>{
                    arr.push({
                        name: r,
                        img: `@/assets/images/exhiRange${(i+1)}.png`
                    })
                })
                
                this.categoryList = data.zhanqu
            })
        },
        getExihibitionList() {
            var param = {
                pageindex: this.page,
                pagesize: this.size,
            }
            getExihibitionList(param).then(res=>{
                var data = res.data
                this.zhanshangList = data.zhanshang
                this.total = Number(data.total)
            })
        },
        handleCurrentChange(val) {
            this.page = val
            this.getExihibitionList()
            this.$root.$emit('toTop')
        },
        toShowDetail(type,id) {
            if (type == 'product') {
                this.titleName = {
                    chinese: id,
                    english: 'List of exhibits',
                }
                this.showProductList = true

                this.$router.push(`/home/OnlineProductList?name=${id}`)
            } else {

                this.$router.push(`/home/showDetail?id=${id}`)
                sessionStorage.setItem('currentNav', '线上展')

            }
            
        },
        toProdcutDetail() { // 访问产品详情页面
                this.titleName = null
                this.showDetail= false
                this.showProductList = false
                // sessionStorage.setItem('showDetail', false)
                // sessionStorage.setItem('titleName', null)
                // sessionStorage.setItem('showProductList', false)
                // sessionStorage.setItem('showProductDetail', true)

                this.titleIndex = 20
                this.showProductDetail= true
                this.$router.push(`/home/showProductDetail?currentNav=线上展&id=${2}`)
        },
    },
};
</script>

<style lang="scss" scoped>
.Onlineshow {

    h3 {
        height: 48px;
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;       
        padding-bottom: 32px;
        margin-top: 48px;
    }
    .exibitors {
        width: 960px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: flex-start;

        .exibox {
            width: 222px;
            height: 177px;
            background: #FFFFFF;
            cursor: pointer;
            // border-radius: 0px 0px 0px 0px;
            // border: 1px solid #CACACA;
            margin-bottom: 20px;
            // &:not(:nth-child(4n)) {
            //     margin-right: 20px;
            // }
            .img {
                width: 190px;
                height: 116px;
                display: flex;
                align-items: center;
            }
            img {
                width: 100%;
                max-height: 100%;
                object-fit: fill;
            }
            span {
                height: 46px;
                font-size: 14px;
                padding: 0 10px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #000000;
                line-height: 24px;
                margin-top: 6px;
                text-align: justify;

            }
        }

    }
    .productList {
        width: 960px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .p_cell {
            // width: 432px;
            margin-bottom: 72px;
            cursor: pointer;
            img {
                width: 432px;
                height: 180px;
            }
            .p_title {
                width: 321px;
                height: 26px;
                margin-left: 16px;
                margin-top: 16px;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #000000;
                line-height: 32px;
                background-image: url("~@/assets/images/p_titlebg.png");
                background-size: 100% 100%;
                text-align: left;
            }
        }
    }    

}
    @media screen and (max-width: 1300px) { // 非pc端
        .exibitors {
            width: 550px !important;
            .exibox {
                width: 160px !important;
                .img {
                    width: 150px !important;
                }
                span {
                    font-size: 12px !important;
                }
            }

        }
        .productList {
            width: 550px !important;
            justify-content: center !important;
            .p_cell {
                img {
                    // width: 100% !important;
                }
            }
        }

    }
    @media screen and (max-width: 550px) { // 非pc端
        .exibitors {
            width: 100% !important;
            .exibox {
                width: 150px !important;
                .img {
                    width: 120px !important;
                }
                span {
                    font-size: 11px !important;
                }
            }            
        }
        .productList {
            width: 350px !important;
            justify-content: center !important;
            .p_cell {
                img {
                    width: 100% !important;
                }
            }
        }

    }
</style>