// import Vue from 'vue'
import axios from 'axios'
import {
  MessageBox,
  Message
} from 'element-ui'
import router from '@/router'
// import {
//   getToken,
//   removeToken
// } from '@/utils/auth'


const baseURL = '/'
const env = process.env.NODE_ENV
// create an axios instance
// Vue.prototype.axios = axios
axios.defaults.timeout = 5000
const service = axios.create({
  baseURL: baseURL, // 生产环境切换这个ip
//   baseURL: env == 'production' ? 'https://r2.gzyct.com/bdpubmng/fysy/api' : '/api', // url = base url + request url  baseURL为空才能proxy代理不同的ip
  timeout: 20000 // request timeout
})

export const uploadUrl = `${baseURL}/file/upload`

// request interceptor
service.interceptors.request.use(
  config => {
    // 可以在此处判断请求config.url的不同，定向到不同的后端api接口上去  通过更改config.baseURL(生产环境可用)

    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.data.success == true || response.data.code == 200) {
      return res
    } else {
      Message({
        message: res.errorMsg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
    }


  },
  error => {
    // token失效，跳转登录页
    console.log(error);
    if (error.response.data.code == 401) {
      Message({
        message: error.response.data.msg || '服务器暂无响应，请稍后重试',
        type: 'error',
        duration: 2 * 1000
      })
      removeToken()
      router.push('/login')

    }
    if (!error.message.includes('timeout')) {
      Message({
        message: '服务器暂无响应，请稍后重试',
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
