<template>
	<div class="framework">
		<Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="3"></Header>
		<div class="main">
			<div class="content1">
				<div class="title">组织架构</div>
				<!-- <div class="content-block">
					<div class="company-block cb1">
						<div class="companys">
							<div class="unit-block">
								<div class="unit">{{form.name}}</div>
									<div class="company" v-for="(item, i) in form.orCotnent1" :key="i">{{item.content}}</div>
							</div>
							<div class="unit-block">
									<div class="unit">&nbsp;</div>
									<div class="company" v-for="(item, i) in form.orCotnent2" :key="i">{{item.content}}</div>
							</div>
						</div>						

					</div>
					<img class="content-image content-image1" :src="form.fileList[0].url" alt="">
				</div>
				<div class="content-block">
					<div></div>
					<div class="company-block cb2">
						<div class="companys">
							<div class="unit-block">
								<div class="unit">{{form2.name}}</div>
								<div class="company" v-for="(item, i) in form2.orCotnent1" :key="i">{{item.content}}</div>
							</div>
							<div class="unit-block">
									<div class="unit">&nbsp;</div>
									<div class="company" v-for="(item, i) in form2.orCotnent2" :key="i">{{item.content}}</div>
							</div>							
						</div>
					</div>
					<img class="content-image left-box" :src="form2.fileList2[0].url" alt="">
				</div> -->
				<!-- 以上为对接接口了 -->
				<div class="content-block">
					<div class="company-block cb1">
						<div class="companys">
							<div class="unit-block">
								<div class="unit">协办单位：</div>
									<div class="company">广州大湾区轨道交通产业投资集团有限公司</div>
									<div class="company">重庆市轨道交通（集团）有限公司</div>
									<div class="company">香港铁路有限公司</div>
									<div class="company">厦门轨道建设发展集团有限公司</div>
									<div class="company">大连公共交通建设投资集团有限公司</div>
									<div class="company">北京交通大学</div>
									<div class="company">中国铁道科学研究院集团有限公司</div>
									<div class="company">新誉集团有限公司</div>
							</div>
				
							<div class="unit-block">
									<div class="unit">&nbsp;</div>
									<div class="company">上海申通地铁集团有限公司</div>
									<div class="company">深圳市地铁集团有限公司</div>
									<div class="company">佛山市地铁集团有限公司</div>
									<div class="company">三亚市轨道交通有限公司</div>
									<div class="company">苏州市轨道交通集团有限公司</div>
									<div class="company">北京城建设计发展集团股份有限公司</div>
									<div class="company">中国城市规划设计研究院</div>
									<div class="company">广州地铁设计研究院股份有限公司</div>

							</div>
						</div>						

					</div>
					<img class="content-image content-image1" :src="require('@/assets/images/train@2x.png')" alt="">
				</div>
				<div class="content-block">
					<div></div>
					<div class="company-block cb2">
						<div class="companys">
							<div class="unit-block">
								<div class="unit">支持单位：</div>
								<div class="company">中国中铁股份有限公司</div>
								<div class="company">中国铁建股份有限公司</div>
								<div class="company">中国建筑股份有限公司</div>
								<div class="company">中国中车股份有限公司</div>
								<div class="company">广州工业投资控股集团有限公司</div>
								<div class="company">白云电气集团有限公司</div>
								<div class="company">佳都科技集团股份有限公司</div>
								<div class="company">中国铁路设计集团有限公司</div>
								<div class="company">广州轨道交通建设监理有限公司</div>
								<div class="company">广州轨道教育科技股份有限公司</div>
								<div class="company">成都运达科技股份有限公司</div>
								<div class="company">中国欧盟商会</div>
								<div class="company">越秀地产股份有限公司</div>
								<div class="company">北京九州一轨环境科技股份有限公司</div>
							</div>
						</div>
					</div>
					<img class="content-image left-box" :src="require('@/assets/images/train2.png')" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
    exhibitiondisplaycommonGetList,
} from '@/api/exhibition'
export default {
	name: 'Framework',
	data() {
		return {
			headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
			isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
			form: {
				fileList: [
					{url:''}
				],
			},
			form2: {
				fileList2: [{url:''}],
			},
		}
	},
    mounted() {
        var that = this
        this.$root.$on('upDateView', ()=>{
            that.isMobile = JSON.parse(sessionStorage.getItem('isMobile'))
        })
		// this.exhibitiondisplaycommonGetList()

    },	
    methods: {
        exhibitiondisplaycommonGetList() {
            var param = {
                firstDirName: '展会介绍',
                secondDirName: '组织架构',
            }
            this.fileList = []
            this.fileList2 = []
            exhibitiondisplaycommonGetList(param).then(res=>{
                var data = res.data.data
                data.map(r=>{
                    if (r.firstDirName=='展会介绍'&&r.secondDirName=='组织架构') {
                        if (r.plateName=='板块一') {
                            this.form = JSON.parse(r.plateBody).form
							var arr1 = []
							var arr2 = []
							this.form.orCotnent.map((or,i)=>{
								if ((i+1)%2==0) {
									arr1.push(or)
								} else {
									arr2.push(or)
								}
							})
							this.form.orCotnent1 = arr1
							this.form.orCotnent2 = arr2
                            var obj = JSON.parse(r.plateBody).fileList
                            obj[0].url = JSON.parse(r.plateBody).fileList[0].response.data.link
                            this.form.fileList = obj
                        }
                        if (r.plateName=='板块二') {
                            this.form2 = JSON.parse(r.plateBody).form2
							var arr1 = []
							var arr2 = []
							this.form2.orCotnent.map((or,i)=>{
								if ((i+1)%2==0) {
									arr1.push(or)
								} else {
									arr2.push(or)
								}
							})
							this.form2.orCotnent1 = arr1
							this.form2.orCotnent2 = arr2							
                            var obj = JSON.parse(r.plateBody).fileList2
                            obj[0].url = JSON.parse(r.plateBody).fileList2[0].response.data.link
                            this.form2.fileList2 = obj
                        }
                    }
                })

            })
        },
		
	}
}
</script>

<style lang='less' scoped>
.framework {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #EEE;
	
	.main {
		// width: 1440px;
		// margin: 0 auto;
		background-color: #FFF;

		.content1 {
			position: relative;
			width: 100%;
			.title {
				height: 48px;
				font-size: 26px;
				font-family: Source Han Sans CN-Bold, Source Han Sans CN;
				font-weight: 700;
				color: #0C266D;
				line-height: 48px;       
				margin: 48px 0;
			}

			.content-block {
				position: relative;
				display: flex;
				justify-content: space-between;
				width: 1440px;
				margin: 0 auto;
				min-height: 510px;
				padding-top: 60px;
				margin-bottom: 60px;
				box-sizing: border-box;

				.company-block {
					width: 948px;
					min-height: 450px;
					box-sizing: border-box;
					background-color: #F7F7F7;

					.companys {
						width: 100%;
						display: flex;
						text-align: left;
						color: #727272;
						font-size: 14px;
						line-height: 20px;

						.unit-block {
							margin: 40px 10px;

							.unit {
								margin: 10px 0;
								color: #000;
								font-size: 16px;
								font-weight: 700;
								line-height: 30px;
								font-family: Source Han Sans CN-Bold, Source Han Sans CN;
							}
							.company {
								margin: 10px 0;
								font-family: Source Han Sans CN-Bold, Source Han Sans CN;
							}
							.company-column {
								display: flex;
								flex-wrap: wrap;
								width: 100%;

								.com {
									width: 50%;
									padding-right: 10px;
									margin: 10px 0;
									box-sizing: border-box;
									font-family: Source Han Sans CN-Bold, Source Han Sans CN;
								}
							}
						}
					}
				}
				.cb1 {
					padding: 0 48px 0 165px;
				}
				.cb2 {
					padding: 0 48px 0 306px;
				}
				.content-image {
					position: absolute;
					top: 0;
					right: 120px;
					width: 600px;
					height: 450px;
					background-color: silver;
					z-index: 49;
				}
				.left-box {
					left: 120px;
				}
			}
		}
	}
}
 @media screen and (max-width: 1300px) { // 非pc端
	.main { 
		.content-block { 
			width: 100% !important;
		}
		.content-image { 
			// left: 0 !important;
			width: 300px !important;
			height: 225px !important;
		}
		.company-block {
			width: 550px !important;
			min-height: 225px !important;
			padding: 0 24px 0 133px !important;
		}
		.left-box {
			// right: 0px !important;
		}
	}
 }
  @media screen and (max-width: 780px) { // 非pc端
	.main { 
		.content-block { 
			width: 100% !important;
		}
		.content-image { 
			// left: 0 !important;
			width: 300px !important;
			height: 225px !important;
		}
		.content-image1 {
			left: 0 !important;
		}
		.company-block {
			width: 550px !important;
			min-height: 225px !important;
			padding: 150px 24px 0 20px !important;
		}
		.left-box {
			left: auto !important;
			right: 0px !important;
		}
	}
 }
</style>
