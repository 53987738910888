<template>
	<div class="about-us">
		<Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="4"></Header>
		<div class="main">
			<div class="content1">
				<div class="title">主办单位</div>
				<!-- <div :class="{'content-block':true, needReverse: (isMobile?true:i%2!=1), blocktype2: i%2==1, reverseDirect: (!isMobile&&i%2==1)}" v-for="(item, i) in cellList1" :key="i">
					<div class="exhibit">
						<div class="exhibit-title">{{item.form.sonTitle}}</div>
						<div class="exhibit-introduce">
							{{item.form.content}}
						</div>
					</div>
					<img class="content-image" :src="item.form.url" />
				</div> -->
				<!-- 以上为对接口 -->
				<div class="content-block needReverse">
					<div class="exhibit">
						<div class="exhibit-title">中国城市轨道交通协会</div>
						<div class="exhibit-introduce">
							中国城市轨道交通协会是在国家民政部登记注册的独立法人单位，是全国性、行业性、非营利性社会组织，是我国城市轨道交通行业国家一级协会，也是国家4A级协会。协会的登记管理机关是民政部，党建领导机关是中央和国家机关行业协会商会党委，外事工作由北京市人民政府外事办公室管理，接受国家发展和改革委员会、住房和城乡建设部、交通运输部、工业和信息化部、科技部、人力资源和社会保障部、市场监督管理总局、统计局等相关政府部门的业务指导。
						</div>
					</div>
					<img class="content-image" :src="require('@/assets/images/z5@2x(3).png')" />
				</div>
				<div class="content-block blocktype2">
					<img class="content-image" :src="require('@/assets/images/gzrailway.png')" />
					<div class="exhibit">
						<div class="exhibit-title">广州地铁集团有限公司</div>
						<div class="exhibit-introduce">
							广州地铁集团有限公司成立于1992年，是广州市政府全资大型国有企业。公司始终以 “ 建设好、运营好、经营好地铁，服务好城市，带动好产业 ” 为宗旨，主动担当、积极作为，业务实现从地铁新线规划建设到铁路建设投融资，从地铁线网到城际铁路、有轨电车全制式覆盖，从广州一地走向粤港澳大湾区、国内主要城市乃至 “ 一带一路 ” 沿线重要节点。
广州地铁集团始终坚持“全程为你 ” 的服务理念，精心组织运营，为乘客提供了安全优质的交通运输服务。2020年，广州地铁共安全运送乘客24.1亿人次，客运量居全国第二，占全市公共交通出行总量的57%。根据国际地铁协会（CoMET）披露，在全球38家大型地铁中，广州地铁多项主要运营指标保持行业领先。其中，10年平均伤亡率、连续4年车站犯罪事件发生率最低，运能利用度、运营服务可靠度、列车正点率行业领先。自2007年以来，广州地铁运营服务乘客满意度已连续多年位列全省交通行业前列，成为展示广州市两个文明建设成果的重要窗口。
未来，广州地铁集团将继续发挥一体化经营管理优势，主动承担 “ 提速广州、主导湾区、引领行业 ” 的使命，深化企业改革，推动数字化转型，持续提升轨道交通产业整体能级和技术创新能力。以“一张网、一张票、一串城”的理念，统筹推进广州都市圈城际铁路项目，全力推动“轨道上的大湾区”建设，努力构建结构合理、换乘高效、共建共享的世界级轨道交通，为广州以更实举措服务粤港澳大湾区建设，以更高质量实现老城市新活力、“四个出新出彩”，以更强担当推动形成“国内大循环为主体、国内国际双循环相互促进”的新发展格局作出新的更大贡献。
						</div>
					</div>
				</div>
			</div>

			<div class="content1">
				<div class="title">承办单位</div>
				<!-- <div :class="{'content-block':true, needReverse: (isMobile?true:i%2!=1), blocktype2: i%2==1, reverseDirect: (!isMobile&&i%2==1)}" v-for="(item, i) in cellList2" :key="i">
					<div class="exhibit">
						<div class="exhibit-title">{{item.form.sonTitle}}</div>
						<div class="exhibit-introduce">
							{{item.form.content}}
						</div>
					</div>
					<img class="content-image" :src="item.form.url" />
				</div> -->
				<!-- 以上为对接口 -->				
				<div class="content-block needReverse">
					<div class="exhibit">
						<div class="exhibit-title">北京中城轨会展有限公司</div>
						<div class="exhibit-introduce">
							北京中城轨会展有限公司，是中国城市轨道交通协会旗下专业公司，同时兼顾中国城市轨道交通协会传媒会展中心职能。公司以深耕行业、服务行业为宗旨，面向城轨业主和全产业链，提供线上线下会展服务及传播顾问服务。 从2020年开始，公司向全案传播、跨界服务转型，创建协会“城轨讲堂”线上教育平台，联合各方推出“TOD界”专题品牌及内容平台，从而以内容生产、展览、会议、考察、咨询、培训等多维度服务，为行业细分领域提供全案传播服务，助力行业科学发展、服务行业、大众和城市。
						</div>
					</div>
					<img :src="require('@/assets/images/北京中城轨会展有限公司.png')" class="content-image" />
				</div>
				<div class="content-block blocktype2">
					<img :src="require('@/assets/images/gzgj.png')" class="content-image" />
					<div class="exhibit">
						<div class="exhibit-title">广州市轨道交通产业联盟</div>
						<div class="exhibit-introduce">
							广州市轨道交通产业联盟成立于2017年10月26日，由广州地铁集团有限公司、白云电气集团有限公司、佳都新太科技股份有限公司发起，粤港澳大湾区与轨道交通相关的发展规划、咨询设计、工程建设、运营维护、装备制造、科研院所、教育培训、金融服务及相关增值服务等轨道交通全产业链的单位及个人自愿结成的地方性、行业性、互助性、非营利性的社会团体，接受广州市发展和改革委员会、广州市人民政府国有资产监督管理委员会的业务指导以及广州市民政局的监督管理，现有成员单位已过两百家。
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
    exhibitiondisplaycommonGetList,
} from '@/api/exhibition'
export default {
	name: 'AboutUs',
	data() {
		return {
			headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
			isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
			cellList1: [
				{form:{}}
			],
			cellList2: [{form:{}}],
		}
	},
    mounted() {
        // this.exhibitiondisplaycommonGetList()
    },
	methods: {
        exhibitiondisplaycommonGetList() {
            var param = {
                firstDirName: '展会介绍',
                secondDirName: '关于我们',
            }
            var arr1 = []
            var arr2 = []

            exhibitiondisplaycommonGetList(param).then(res=>{
                var data = res.data.data
                data.map(r=>{
                    if (r.firstDirName=='展会介绍'&&r.secondDirName=='关于我们') {
                        var obj = JSON.parse(r.plateBody)
                        if (obj.form.fileList.length) {
                            // obj.form.fileList[0].url = JSON.parse(r.plateBody).form.fileList[0].response.data.link  
							obj.form.url = JSON.parse(r.plateBody).form.fileList[0].response.data.link        
                        }
                        obj.isView = true
                        obj.id = r.id
						if (obj.form.title=='主办单位') {
							arr1.push(obj)
						} else {
							arr2.push(obj)
						}
                    }
                })

            })
			this.cellList1 = arr1
			this.cellList2 = arr2

        },		
	},
}
</script>

<style lang='less' scoped>
.about-us {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #EEE;
	.reverseDirect {
		flex-direction: row-reverse;
	}
    .maxWidth {
        width: 1440px;
        display: flex;
        justify-content: space-between;
    }
	.main {
		width: 100%;
		margin: 0 auto;
		background-color: #FFF;

		.content1 {
			width: 100%;
			.title {
				height: 48px;
				font-size: 26px;
				font-family: Source Han Sans CN-Bold, Source Han Sans CN;
				font-weight: 700;
				color: #0C266D;
				line-height: 48px;       
				margin: 48px 0 40px 0;
			}
			.content-block {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				padding: 136px 206px 64px 224px;
				box-sizing: border-box;
				background-color: #FFF;
				&:nth-child(2) {
					padding-top: 0;
				}

				.exhibit {
					max-width: 550px;

					.exhibit-title {
						width: 100%;
						color: #0B43BC;
						font-family: Source Han Sans CN-Regular, Source Han Sans CN;
						font-size: 24px;
						text-align: left;
						line-height: 48px;

					}
					.exhibit-introduce {
						width: 480px;
						max-height: 256px;
						overflow-y: auto;
						&::-webkit-scrollbar {
							// width: 2px;
							// background-color: #000;
						}
						margin-top: 12px;
						color: #000;
						font-family: Source Han Sans CN-Regular, Source Han Sans CN;
						font-size: 16px;
						// text-indent: 2em;
						text-align: justify;
						line-height: 32px;
					}
				}

				.content-image {
					width: 400px;
					height: 300px;
					background-color: silver;
				}
				.limitHeight {
					height: 100px;
				}
			}
			.blocktype2 {
				padding-top: 80px;
				padding-bottom: 80px;
				background-color: #F7F7F7;
			}
		}
	}

}
    @media screen and (max-width: 1300px) { // 非pc端
        .content-block {
            padding: 26px 46px 22px 58px !important;
        }
		.needReverse {
			display: flex !important;
			// flex-direction: column-reverse;
		}
		.exhibit-introduce {
            // width: 380px !important;
            min-height: 158px !important;
            margin-top: 6px !important;
        }
        .content-image {
            width: 400px !important;
            height: 300px !important;
        }
		.limitHeight {
			height: 50px !important;
		}
    }
    @media screen and (max-width: 950px) { // 非pc端
        .content-image {
            width: 300px !important;
            height: 250px !important;
        }
		.needReverse {
			display: flex !important;
			flex-direction: column-reverse;
		}
    }
    @media screen and (max-width: 800px) { // 非pc端
        .content-image {
            width: 250px !important;
            height: 180px !important;
        }
    }
    @media screen and (max-width: 750px) { // 非pc端
		.content-block {
			display: inline-block !important;
		}
		.needReverse {
			display: flex !important;
			flex-direction: column-reverse;
		}
		.exhibit-introduce {
            width: 100% !important;
			max-height: 100% !important;
			overflow-y: visible !important;
        }
    }
</style>
