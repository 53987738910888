<!--
 * @Author: huanggs huanggx@gzyct.com
 * @Date: 2023-04-27 09:26:06
 * @LastEditors: huanggs huanggx@gzyct.com
 * @LastEditTime: 2023-05-29 09:35:07
 * @FilePath: \exhibitionProject\src\components\selfPage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div>
          <el-pagination
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :prev-text="prevText"
            :next-text="nextText"
            :page-size="size"
            :total="total">
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: 'ExhibitionprojectSelfpage',
    props: {
        total: {
            type: Number,
            default: 0,
        },
        size: {
            type: Number,
            default: 10,
        },
        prevText: {
            type: String,
            default: '上一页',
        },
        nextText: {
            type: String,
            default: '下一页',
        },
    },
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        handleCurrentChange(val) {
            this.$emit('handleCurrentChange', val);
        },
    },
};
</script>

<style lang="scss" scoped>
.el-pagination {
    margin: 60px 0;
}
::v-deep .el-pager {
    .active {
        // width: 32px;
        // height: 32px;
        // line-height: 32px;
        color: #fff;
        background: #0B43BC;
    }
}
@media screen and (max-width: 1300px) { // 非pc端
    .el-pagination {
        margin: 30px 0;
    }
}
</style>