<template>
    <div>
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="1"></Header>
        <div class="content">
            <!-- <div class="content1">
                <h3>{{form.firstTitle}}</h3>
                <div class="module1" :style="{width: isMobile?'100%':'750px'}">
                    <el-row>
                        <el-col :span="14">
                            <div class="etop">
                                {{form.title1}}
                            </div>
                            <div class="etext">{{form.content1}}</div>
                        </el-col>
                        <el-col :span="10">
                            <div class="etop">
                                {{this.form.title2}}
                            </div>
                            <div class="etext">{{form.content2}}</div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="14">
                            <div class="etop">
                                {{form.title3}}
                            </div>
                            <div class="etext">
                                <span>{{form.content3}}</span>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <div class="etop">
                                {{form.title4}}
                            </div>
                            <div class="etext">
                                <span>{{form.content4}}</span>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row class="unLimitHeight">
                        <el-col :span="24">
                            <div class="etop">
                                
                            </div>
                            <div class="etext" v-html="form.content"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="content1">
                <h3>{{form2.firstTitle}}</h3>
                <div class="module2" :style="{width: isMobile?'calc(100% - 40px)':'1010px'}">
                    <div class="m2left">
                        <h3>{{form2.title2}}</h3>
                        <div class="m2cell" v-for="(item, i) in form2.titleItems" :key="i" :style="{marginRight: isMobile?'':'60px'}">
                            <div class="m2title">{{item.name}}</div>
                            <div class="m2c">{{item.content}}</div>
                        </div>
                    </div>
                    <div class="m2Right" :style="{width: isMobile?'100% ':'490px'}">
                        <img v-for="(item,i) in form2.fileList2" :key="i" :src="item.url" :style="{width: isMobile?'100% ':'490px'}" :large="item.url" preview="1" preview-text="" alt="" srcset="">
                    </div>
                </div>
            </div> -->
            <!-- 以上为对接口 -->
            <div class="content1">
                <h3>展会介绍</h3>
                <div class="module1" :style="{width: isMobile?'100%':'750px'}">
                    <el-row>
                        <el-col :span="14">
                            <div class="etop">
                                展览日期：
                            </div>
                            <div class="etext">2023年6月29日-7月1日</div>
                        </el-col>
                        <el-col :span="10">
                            <div class="etop">
                                展览地址：
                            </div>
                            <div class="etext">中国进出口商品交易会展馆B区(中国·广州)</div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="14">
                            <div class="etop">
                                主办单位：
                            </div>
                            <div class="etext">
                                <span>中国城市轨道交通协会</span>
                                <span>广州地铁集团有限公司</span>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <div class="etop">
                                承办单位：
                            </div>
                            <div class="etext">
                                <span>北京中城轨会展有限公司</span>
                                <span>广州市轨道交通产业联盟</span>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row class="unLimitHeight">
                        <el-col :span="24">
                            <div class="etop">
                                
                            </div>
                            <div class="etext">
                                “（首届）广州大湾区国际轨道交通产业发展论坛暨展览会”由中国城市轨道交通协会、广州地铁集团有限公司联合主办。同期还将举办“第二届中国城市轨道交通装备自主创新成果展示会”。将有超过10场论坛会议举办，汇集300余行业大咖,吸引超过6000名参会嘉宾。展览规模预计参展单位近300家，展出面积逾20,000 ㎡，专业观众超20,000人次。旨在响应国家大湾区发展战略，向全国、向世界展示大湾区轨道交通产业新生态、新技术、新格局。
分享大湾区轨道交通发展成果，拓展国内外地区交流合作平台；将行业优质企业集聚湾区，展示先进装备、先进技术、先进理念；行业专家、菁英思想碰撞交流，带动产业生态协同，实现融合发展；带动、促进区域协同创新共同体建设，助力现代化的综合交通运输体系的构建，推进具有国际竞争力的现代产业体系建设。
                            </div>
                        </el-col>
                    </el-row>
                    <!-- <div class="video"></div> -->
                </div>
                <!-- <div class="content1bg"></div> -->
            </div>
            <div class="content1">
                <h3>展区分布图</h3>
                <div class="module2" :style="{width: isMobile?'calc(100% - 40px)':'1010px'}">
                    <div class="m2left">
                        <h3>展品范围</h3>
                        <div class="m2cell" v-for="(item, i) in m2Data" :key="i" :style="{marginRight: isMobile?'':'60px'}">
                            <div class="m2title">{{item.title}}</div>
                            <div class="m2c">{{item.content}}</div>
                        </div>
                    </div>
                    <div class="m2Right" :style="{width: isMobile?'100% ':'490px'}">
                        <img :src="require('@/assets/images/mbz.png')" :style="{width: isMobile?'100% ':'490px'}" :large="require('@/assets/images/mbz.png')" preview="1" preview-text="" alt="" srcset="">
                        <img :src="require('@/assets/images/mbz2.png')" :style="{width: isMobile?'100% ':'490px'}" :large="require('@/assets/images/mbz2.png')" preview="1" preview-text="" alt="" srcset="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    exhibitiondisplaycommonGetList,
} from '@/api/exhibition'
    // 引入插件
    import {
      swiper,
      swiperSlide
    } from "vue-awesome-swiper";
    import "swiper/dist/css/swiper.css";
export default {
    name: 'ExhibitionprojectSituation',
    components: {
        swiper,
        swiperSlide
    },
    data() {
        return {
            secImgArr: [
                {},
                {},
            ],
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            m2Data: [
                {
                    title: '智能列车及先进车辆段配套装备',
                    content: '全面展示先进轨道交通及铁路机车车辆技术、车辆组件及零部件、机电设备装备、轨道工业制造装备及材料、车辆段设备等。',
                },
                {
                    title: '通信信号系统及城轨云',
                    content: '全面展示城轨行业的数字化、信息化建设成果，互联互通的全自动运行、人工智能技术。重点突出轨道交通 5G 应用技术、云计算、物联网等先进技术。',
                },
                {
                    title: '供电系统及智慧能源',
                    content: '全面展示轨道交通供电系统建设成果，重点突出“双碳”及利于打造特有产业链的创新性产品。',
                },
                {
                    title: '智慧车站、智能运输及配套装备',
                    content: '全面展示先进车站技术及关键配套部件与装备。重点突出电扶梯及新材料、自动售检票系统、屏蔽门安全系统以及其它地铁站点设备等。',
                },
                {
                    title: '先进轨道交通基础设施建设及施工技术',
                    content: '全面展示轨道交通基础设施建设的产业链及技术、工程监理与施工系统、工程检测与监测技术、建设管理系统等，推动“四新”成果应用推广。重点突出装配式建筑设计施工、盾构施工创新工艺、轨道减振降噪、新型防火防水材料等领域的展示。',
                },
                {
                    title: '设计、咨询、检测认证与安全管理综合',
                    content: '全面展示大湾区设计咨询领域优势、国家质量基础设施体系(NOI)标准化建设工作、轨道交通安全管理发展的重要成果，凸显大湾区设计咨询、检测认证、安全管理等领域的协同创新。',
                },
                {
                    title: '城轨人才',
                    content: '全面展示城轨行业人才培养、技能竞赛、师资培训、职业教育等内容。重点突出轨交企业及相关机构对轨道交通人力资源开发利用的成果。',
                },
                {
                    title: '轨道交通物资采购',
                    content: '邀请来自全国各地包括深圳、佛山、东莞、武汉、上海、杭州、沈阳、合肥等多家轨道交通业主单位共聚一堂，并在现场设置各业主单位驻场供需对接专区，充分释放业主单位采购新需求，践行“三优”采购标准，积极赋能供应商业务新增量。',
                },
                {
                    title: '轨道交通产业园',
                    content: '邀请包括广州白云、南沙、花都轨道交通相关产业园等全国各地的产业园区，综合展示园区的政策、配套、规划等内容。',
                },
                {
                    title: '站城融合（TOD）及配套服务',
                    content: '全面展示大湾区站城融合 TOD4.0 规划与实践探索成果，囊括 TOD 相关综合开发类、专业服务类、产业经营类项目与解决方案。重点突出广州地铁沿线地块的项目开发成果展示。',
                },
                {
                    title: '城轨文化、资源经营',
                    content: '全面展示相关文创产品、城轨文化出版物、城轨新闻媒体产品与服务，资源经营相关企业的创新、开发、运营、管理等优秀实践成果。',
                },
            ],
            swiperOption: {
                loop: false,
                autoplay: {
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                // 显示分页
                // pagination: {
                //   el: ".swiper-pagination",
                //   clickable: true //允许分页点击跳转
                // },
                // 设置点击箭头
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                }
            },    
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),         
            form: {},
            form2: {},
        };
    },

    mounted() {
        var that = this
        this.$root.$on('upDateView', ()=>{
            that.isMobile = JSON.parse(sessionStorage.getItem('isMobile'))
        })
        // this.exhibitiondisplaycommonGetList()
    },

    methods: {
        exhibitiondisplaycommonGetList() {
            var param = {
                firstDirName: '展会介绍',
                secondDirName: '展会概况',
            }
            exhibitiondisplaycommonGetList(param).then(res=>{
                var data = res.data.data
                data.map(r=>{
                    if (r.firstDirName=='展会介绍'&&r.secondDirName=='展会概况') {
                        if (r.plateName=='板块一') {
                            this.form = JSON.parse(r.plateBody).form
                            var obj = JSON.parse(r.plateBody).fileList
                            obj[0].url = JSON.parse(r.plateBody).fileList[0].response.data.link
                            this.form.fileList = obj
                            this.$forceUpdate();
                        }
                        if (r.plateName=='板块二') {
                            this.form2 = JSON.parse(r.plateBody).form2
                            var obj = JSON.parse(r.plateBody).fileList2
                            obj[0].url = JSON.parse(r.plateBody).fileList2[0].response.data.link
                            this.form2.fileList2 = obj
                            this.$forceUpdate();
                        }
                    }
                })

            })
        },          
    },
};
</script>

<style lang="scss" scoped>

// 更改轮播图翻页icon样式
::v-deep .swiper-button-prev {
    left: 32px !important;
}
::v-deep .swiper-button-next {
    right: 32px !important;
}
::v-deep .swiper-button-prev, ::v-deep .swiper-button-next {
    width: 28px !important;
    height: 28px !important;
}

.situation {
    background-color: #fff;
}
.content1 {
    position: relative;
    margin-bottom: 88px;

    h3 {
        height: 48px;
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;       
        margin-bottom: 96px;
        margin-top: 48px;
    }
    .module1 {
        margin: 0 auto;
        .el-col {
            text-align: left;
            height: 99px;
        }
        .etop {
            height: 30px;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            line-height: 30px;
        }
        .etext {
            // height: 20px;
            height: 100%;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #727272;
            line-height: 20px;
            text-align: justify;
            span {
                display: block;
            }
        }
        .video {
            position: absolute;
            width: 750px;
            height: 418px;
            background: #CACACA;
        }
        .unLimitHeight {
            .el-col {
                height: 100%;
            }
        }
    }
    .module2 {
        // width: 950px;
        margin: 0 auto;
        display: flex;
        .m2left {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .m2cell {
                margin-bottom: 24px;
                
                .m2title {
                    text-align: justify;
                    height: 30px;
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #000000;
                    line-height: 30px;
                }
                .m2c {
                    text-align: justify;
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #727272;
                    line-height: 20px;
                }
            }
            h3 {
                height: 48px;
                font-size: 24px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #0B43BC;
                line-height: 48px;
                text-align: justify;
                margin: 22px 0 60px 0;
            }
        }
        .m2Right {
            // width: 490px;
            img {
                // width: 490px;
                width: 100%;
                height: 378px;
                margin-bottom: 30px;
            }
        }
    }
    .content1bg {
        width: 100%;
        margin-top: 275px;
        height: 245px;
        background: #EEEEEE;
    }

}
.content2 {
    height: 539px;
    background: #F7F7F7;
    h3 {
        height: 48px;
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;       
        margin-bottom: 48px;
        margin-top: 48px;
    }
    .picWall {
        min-width: 1228px;
        height: 335px;
        padding: 0 70px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        img {
            width: 268px;
            height: 85px;
            // margin-right: 53px;
            margin-bottom: 40px;
        }
    }
}

@media screen and (max-width: 1300px) { // 非pc端
    .module1 {
        // width: 680px !important;
        .el-row {
            padding: 0 20px;
        }

    }
    .module2 {
        // width: 680px !important;
        flex-direction: column-reverse !important;
        padding: 0 20px;
        flex-direction: column;
        justify-content: flex-start;

        align-items: center;
        .m2Right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                width: 490px !important;
            }
        }
    }
    .content1 {
        margin-bottom: 0px;
    }
    .content2 {
        height: 100%;
        .picWall {
            padding: 0;
            height: 100%;
            min-width: calc(100% - 140px);
            &:nth-child(2n) { 
                margin-right: 0px !important;
            }
        }
    }

}
 @media screen and (max-width: 750px) { // 非pc端
    .module2 .m2Right {
        img {
            width: 100% !important;
        }
    }
 }
</style>