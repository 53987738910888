<template>
    <div class="maxwidth flex_column_cc">
        <img :src="require('@/assets/images/empty__image@2x.png')" alt="">
        <span>暂无数据</span>
    </div>
</template>

<script>
export default {
    name: 'ExhibitionprojectEmpty',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.maxwidth {
    width: 100%;
}
img {
    width: 160px;
    height: 173px;
}
</style>