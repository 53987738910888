<!--
 * @Author: huanggs huanggx@gzyct.com
 * @Date: 2023-05-15 10:51:24
 * @LastEditors: huanggs huanggx@gzyct.com
 * @LastEditTime: 2023-05-18 14:41:11
 * @FilePath: \exhibitionProject\src\views\contactUs.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="Contactus">
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="13"></Header>
        <!-- <div class="middle">
            <div v-html="html"></div>
        </div> -->
        <!-- 以上为对接接口了 -->	             
        <h3>联系方式</h3>
        <h4>招商咨询</h4>
        <div class="personList">
            <div class="list">
                <span>电话  010-83935791</span>
                <span>电话      020-62878361</span>
            </div>
            <div class="list">
                <span>李论 18600104403</span>
                <span>龙婷婷 18675955335</span>
            </div>
            <div class="list">
                <span>田甜 15901234263</span>
                <span>胡景明 15013149920</span>
            </div>
            <div class="list">
                <span>祁继 13811845831</span>
                <span>邹碧云 17620224467</span>
            </div>
            <div class="list">
                <span>丁桥 15011422050</span>
                <span>赖丽红 13710063796</span>
            </div>
        </div>
        <h4>展会咨询</h4>
        <div class="personList">
            <div class="list">
                <span>陈岩 13717655325</span>
                <span>陈菁 18620693085</span>
                <!-- <span>薄纯玉 18500059709</span> -->
            </div>
        </div>
        <h4>设计搭建咨询</h4>
        <div class="personList">
            <div class="list">
                <span>吴林峰 18501235598</span>
                <span>吴玲丽 13016031031</span>
            </div>
        </div>
    </div>
</template>

<script>
import {
    exhibitiondisplaycommonGetList,
} from '@/api/exhibition'
export default {
    name: 'ExhibitionprojectContactus',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
            html: '',
        };
    },

    mounted() {
        // this.exhibitiondisplaycommonGetList()
    },

    methods: {
            exhibitiondisplaycommonGetList() {
                var param2 = {
                    firstDirName: '联系我们',
                }

                exhibitiondisplaycommonGetList(param2).then(res=>{
					var data = res.data.data
					var html = data[0].plateBody
					this.html = html

                })                
            },         
    },
};
</script>

<style lang="scss" scoped>
.Contactus {
    .middle {
        max-width: 850px;
        margin: 0 auto;
        padding: 0 20px;

    }
    h3 {
        height: 48px;
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;       
        padding-bottom: 32px;
        margin-top: 48px;
    }
    h4 {
        height: 30px;
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #000000;
        line-height: 30px;
    }
    .personList {
        width: 960px;
        margin: 0 auto;
        margin-bottom: 48px;
        .list {
            margin-bottom: 12px;
            span {
                height: 30px;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #000000;
                line-height: 30px;
                &:nth-child(1) {
                    margin-right: 138px;
                }
            }
        }
        .list2 {
            margin-bottom: 12px;
            display: flex;
            justify-content: space-around;
            span {
                height: 30px;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #000000;
                line-height: 30px;
                display: flex;
                // &:nth-child(1),
                // &:nth-child(2) {
                //     margin-right: 138px;
                // }
            }
        }
    }
}
    @media screen and (max-width: 1300px) { // 非pc端
        .personList {
            width: 550px !important;

        }
    }
    @media screen and (max-width: 580px) { // 非pc端
        .personList {
            width: 100% !important;
            .list, .list2 {
                display: flex;
                flex-direction: column;
                justify-content: flex-start !important;
                align-content: center !important;
                align-items: center;
                span {
                    margin-right: 0 !important;
                }
            }

        }
    }
</style>