<template>
    <div>
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleName="titleName"></Header>
        <template v-if="compScreenWidth>1000">
            <div class="Onlineshowdetail">
                <div class="odTop">
                    <div class="odtLeft">
                        <h3>{{detailObj.zsmc}}</h3>
                        <span>展馆：{{detailObj.zhanguan}}</span>
                        <span>展区： {{detailObj.zhanqu}}</span>
                        <span>展位号：{{detailObj.zwh}}</span>
                        <!-- <h5>  &nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;</h5> -->
                    </div>
                    <div class="img">
                        <img :src="detailObj.logo" alt="">
                    </div>
                </div>
                <h4>公司简介</h4>
                <div class="text text2" v-html="detailObj.gsjj">
                </div>
                <div class="message">
                    <div class="m_cell" v-show="detailObj.dianhua">
                        <span>电话</span>
                        <span>{{detailObj.dianhua}}</span>
                    </div>
                    <div class="m_cell" v-show="detailObj.youjian">
                        <span>邮箱</span>
                        <span>{{detailObj.youjian}}</span>
                    </div>
                    <div class="m_cell" v-show="detailObj.wangzhi">
                        <span>官网</span>
                        <span>{{detailObj.wangzhi}}</span>
                    </div>
                    <div class="m_cell" v-show="detailObj.dizhi">
                        <span>地址</span>
                        <span>{{detailObj.dizhi}}</span>
                    </div>
                </div>
                <h3 class="mid">主营产品</h3>
                <div class="productCell">
                    <div class="pcell" v-for="(item, i) in detailProduct" :key="i" @click="toProductDetail(item.id)">
                        <div class="pic">
                            <img :src="item.logo" alt="">
                        </div>
                        <div class="msg">
                            <div class="mtitle">{{item.cpmc}}</div>
                            <div class="operate flex_fsbc">
                                <span>展位号：{{item.zwh}}</span>
                                <span>查看详情</span>
                            </div>
                        </div>
                    </div>
                    <Empty v-show="!detailProduct.length"></Empty>
                </div>
                <SelfPage :total="total" :size="3" @handleCurrentChange="handleCurrentChange" v-show="detailProduct.length" prevText="上一页" nextText="下一页"></SelfPage>
            </div>
        </template>
        <template v-else>
            <div class="mobileDetail">
                <h3>{{detailObj.zsmc}}</h3>
                <img :src="detailObj.logo" alt="">
                <div class="intro">
                    <span>展馆：{{detailObj.zhanguan}}</span>
                    <span>展区： {{detailObj.zhanqu}}</span>
                    <span>展位号：{{detailObj.zwh}}</span>
                </div>
                <div class="msg">
                    <h4>公司简介</h4>
                    <div class="text text2" v-html="detailObj.gsjj">
                    </div>
                </div>
                <div class="msg" v-show="detailObj.dianhua">
                    <h4>电话</h4>
                    <div class="text">
                        {{detailObj.dianhua}}
                    </div>
                </div>
                <div class="msg" v-show="detailObj.youjian">
                    <h4>邮箱</h4>
                    <div class="text">
                        {{detailObj.youjian}}
                    </div>
                </div>
                <div class="msg" v-show="detailObj.dizhi">
                    <h4>地址</h4>
                    <div class="text">
                        {{detailObj.dizhi}}
                    </div>
                </div>
            </div>
            <div class="m_introduce">
                <h3>
                    <img :src="require('@/assets/mobileImages/h3_left.png')" alt="">
                    <span>主营产品</span>
                    <img :src="require('@/assets/mobileImages/h3_right.png')" alt="">
                </h3>
                <div class="introduce">
                    <div class="m_i_cell" v-for="(item, i) in detailProduct" :key="i" @click="toProductDetail(item.id)">
                        <div class="mleft">
                            <div class="title">{{item.cpmc}}</div>
                            <div class="num">展位号：{{item.zwh}}</div>
                            <div class="detail">查看详情</div>
                        </div>
                        <img :src="item.logo" alt="">
                    </div>
                    <SelfPage :total="total" :size="3" @handleCurrentChange="handleCurrentChange" v-show="detailProduct.length" prevText="上一页" nextText="下一页"></SelfPage>
                    <Empty v-show="!detailProduct.length"></Empty>
                </div>                
            </div>            
        </template>


    </div>
</template>

<script>
import {
    getExihibitionDetail,
    getProducts,
} from '@/api/home'
export default {
    name: 'ExhibitionprojectOnlineshowdetail',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),   
            titleName:{
                chinese: '展商详情',
                english: 'Exhibitor details',
            },
            detailObj: {}, // 基本信息
            detailProduct: [], // 推荐展品
            page: 1,
            size: 3,
            total: 0,
        };
    },

    computed: {
        compScreenWidth() { // 计算屏幕宽度，进行适配代码
            return screen.width
        },
    },

    mounted() {
        this.getExihibitionDetail()
    },

    methods: {
        getExihibitionDetail() {
            getExihibitionDetail(this.$route.query.id).then(res=>{
                var data = res.data
                this.detailObj = data.zsxx
                // this.detailProduct = data.cpxx
                this.getProducts(data.zsxx.id)
            })
        },
        handleCurrentChange(v) {
            this.page = v
            this.getProducts(this.detailObj.id)
        },
        getProducts(id) {
            var param = {
                pageindex: this.page,
                pagesize: this.size,
                zsid: id,
            }
            getProducts(param).then(res=>{
                var data = res.data
                this.detailProduct = data.chanping
                this.total = Number(data.total)

            })
        },
        toProductDetail(id) {
            this.$router.push(`/home/showProductDetail?productId=${id}`)
            this.$root.$emit('toTop')
        },  
    },
};
</script>

<style lang="scss" scoped>
    .Onlineshowdetail {
        max-width: 940px;
        margin: 0 auto;
        h3 {
            font-size: 26px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 700;
            color: #0C266D;
            line-height: 48px;
            text-align: justify;
        }
        .odTop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 48px;
            .odtLeft {
                h5, span {
                    height: 32px;
                    font-size: 18px;
                    margin-top: 24px;
                    padding-bottom: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #0B43BC;
                    line-height: 32px;   
                    text-align: justify; 
                    border-bottom: 1px solid #EDF0F7; 
                }
                span {
                    display: block;
                    margin-top: 0px;
                    padding-bottom: 0px;
                    &:last-child {
                        margin-bottom: 10px;
                    }

                }
                margin-right: 10px;

            }
            .img {
                width: 50%;
                height: 100%;
                max-height: 250px;
                img {
                    width: 100%;
                    height: 250px;
                    object-fit: contain;
                }
                // width: 160px;
                // height: 160px;
            }
        }
        h4 {
            height: 26px;
            font-size: 18px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 700;
            color: #0C266D;
            line-height: 26px;
            text-align: justify;
        }  
        .text {
            // height: 96px;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            line-height: 32px;
            text-align: justify;
        }   
        .message {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 32px;
            border-bottom: 1px solid #EDF0F7;
            .m_cell {
                padding-bottom: 32px;
                span {
                    &:first-child {
                        height: 26px;
                        font-size: 18px;
                        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                        font-weight: 700;
                        color: #0C266D;
                        line-height: 26px;
                        margin-right: 12px;
                    }
                    &:last-child {
                        height: 32px;
                        font-size: 16px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #000000;
                        line-height: 32px;
                    }
                }

            }
        }   
        .mid {
            text-align: center;
            margin-top: 48px;
            margin-bottom: 21px;
        }
        .productCell {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: space-between;
            .pcell {
                width: 300px;
                height: 322px;
                cursor: pointer;
                &:not(:nth-child(3n)) {
                    margin-right: 20px;
                }
                margin-bottom: 20px;
                .pic {
                    background: #EEEEEE;
                    img {
                        width: 215px;
                        height: 150px;
                    }
                    padding: 30px;
                }
                .msg {
                    background: #FFFFFF;
                    padding: 12px;
                    .mtitle {
                        height: 56px;
                        font-size: 18px;
                        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                        font-weight: 700;
                        color: #222222;
                        line-height: 28px;
                        text-align: justify;
                    }
                    .operate {
                        
                        span {
                            height: 20px;
                            font-size: 15px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #0C266D;
                            line-height: 20px;
                            &:last-child {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

    }
    .mobileDetail {
        // min-width: 351px;
        max-width: 80%;
        margin: 20px auto;
        background: #FFFFFF;
        padding: 0 12px;
        padding-bottom: 20px;
        box-shadow: 0px 1px 0px 0px #E6E6E6;
        border-radius: 6px 6px 6px 6px;
        h3 {
            font-size: 26px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 700;
            color: #0C266D;
            line-height: 48px;
            text-align: center;
        }  
        img {
            // width: 100px;
            // height: 100px;
            width: 100%;
            background: #EEEEEE;       
            margin: 12px 0;     
        }   
        .intro {
            span {
                height: 30px;
                display: block;
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #0B43BC;
                line-height: 30px;    
            }
        }    
        .msg {
            h4 {
                height: 20px;
                font-size: 14px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: 700;
                color: #0C266D;
                line-height: 20px;
                margin-top: 20px;
            }
            .text {
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #000000;
                line-height: 18px;
                text-align: center;
            }
            .text2 {
                text-align: justify;
            }
        }   
        
    }
    .m_introduce {
        // width: 327px;
        width: 85%;
        // height: 318px;
        border-radius: 6px 6px 6px 6px;
        margin: 16px auto;
        padding: 12px;
        h3 {
            margin: 0 !important;
            img {
                width: 27px;
                height: 9px;
            }
            span {
                height: 21px;
                font-size: 17px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: 700;
                color: #0C266D;
                line-height: 24px;
                margin: 0 20px;
            }
        }
        .introduce {
            width: 100%;
            margin-top: 17px;
            .m_i_cell {
                padding: 9px 12px;
                margin-top: 12px;
                cursor: pointer;
                // min-width: 327px;
                max-width: 100%;
                height: 108px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;   
                display: flex;  
                justify-content: space-between;
                align-items: center;
                .mleft {
                    margin-right: 12px;
                    .title {
                        font-size: 16px;
                        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                        font-weight: 500;
                        color: #222229;
                        line-height: 22px;
                        text-align: justify;
                    }
                    .num {
                        font-size: 12px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #727272;
                        line-height: 16px; 
                        text-align: justify;      
                        margin-top: 7px;                
                    }
                    .detail {
                        font-size: 15px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #0B43BC;
                        line-height: 14px;
                        text-align: justify;
                        margin-top: 7px;        
                    }
                }    
                img {
                    width: 110px;
                    height: 84px;
                    border-radius: 2px 2px 2px 2px;
                }       
            }
        }

    }       
    @media screen and (max-width: 1300px) { // 非pc端

    }
</style>