<template>
    <div class="banner" :style="{backgroundColor: isMobile?'#F5F5F7':'', paddingTop: isMobile?'':'38px !important',}">
        <!-- 20230609隐藏搜索功能 -->
        <!-- <div class="topSearch" v-if="isMobile">
            <el-input v-model="searchInput" placeholder="搜索" @focus="searchFocus">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
        </div> -->
        <swiper :options="swiperOption1" v-if="!isMobile">
            <swiper-slide v-for="(item, i) in bannerList" :key="i">
                <img :src="item.imgurl" width="100%" @click="onClick(item)" />
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
        </swiper>
        <swiper class="mobileBanner" :options="swiperOption11" v-else>
            <swiper-slide v-for="(item, i) in bannerList2" :key="i">
                <img :src="item.imgurl" width="100%" @click="onClick(item)" />
            </swiper-slide>
        </swiper>
        <!-- 移动端首页导航 -->
        <div class="navList" v-if="isMobile">
            <div class="ncell flex_column_cc" @click="handleToNav('展会概况')">
                <img :src="require('@/assets/mobileImages/n1.png')" alt="">
                <span>展会介绍</span>
            </div>
            <div class="ncell flex_column_cc" @click="handleToNav('论坛/观展报名')">
                <img :src="require('@/assets/mobileImages/n2.png')" alt="">
                <span>论坛/观展报名</span>
            </div>
            <div class="ncell flex_column_cc" @click="handleToNav('参展流程')">
                <img :src="require('@/assets/mobileImages/n3.png')" alt="">
                <span>参展流程</span>
            </div>
            <div class="ncell flex_column_cc" @click="handleToNav('展品范围')">
                <img :src="require('@/assets/mobileImages/n4.png')" alt="">
                <span>展品范围</span>
            </div>
            <div class="ncell flex_column_cc" @click="handleToNav('展会直播')">
                <img :src="require('@/assets/mobileImages/n5.png')" alt="">
                <span>展会直播</span>
            </div>
            <div class="ncell flex_column_cc" @click="handleToNav('线上展')">
                <img :src="require('@/assets/mobileImages/n6.png')" alt="">
                <span>线上展</span>
            </div>
            <div class="ncell flex_column_cc" @click="handleToNav('资讯报道')">
                <img :src="require('@/assets/mobileImages/n7.png')" alt="">
                <span>资讯报道</span>
            </div>
            <div class="ncell flex_column_cc" @click="handleToNav('关于我们')">
                <img :src="require('@/assets/mobileImages/n8.png')" alt="">
                <span>关于我们</span>
            </div>
        </div>
        <!-- 展会简介 -->
        <template v-if="isMobile">
            <div class="m_introduce">
                <h3>
                    <img :src="require('@/assets/mobileImages/h3_left.png')" alt="">
                    <span>展会简介</span>
                    <img :src="require('@/assets/mobileImages/h3_right.png')" alt="">
                </h3>
                <div class="introduce">
                    <p>
                        “（首届）广州大湾区国际轨道交通产业发展论坛暨展览会”由中国城市轨道交通协会、广州地铁集团有限公司联合主办。同期还将举办“第二届中国城市轨道交通装备自主创新成果展示会”。将有超过10场论坛会议举办，汇集300余行业大咖,吸引超过6000名参会嘉宾。展览规模预计参展单位近300家，展出面积逾20,000㎡，专业观众超20,000人次。旨在响应国家大湾区发展战略，向全国、向世界展示大湾区轨道交通产业新生态、新技术、新格局。
                    </p>
                    <p>
                        分享大湾区轨道交通发展成果，拓展国内外地区交流合作平台；将行业优质企业集聚湾区，展示先进装备、先进技术、先进理念；行业专家、菁英思想碰撞交流，带动产业生态协同，实现融合发展；带动、促进区域协同创新共同体建设，助力现代化的综合交通运输体系的构建，推进具有国际竞争力的现代产业体系建设。
                    </p>                    
                <!-- <div class="introduce" v-html="content"> -->

                </div>
            </div>
        </template>
        <template v-else>
            <h3>展会简介</h3>
            <div class="introduce">
                <p>
                    “（首届）广州大湾区国际轨道交通产业发展论坛暨展览会”由中国城市轨道交通协会、广州地铁集团有限公司联合主办。同期还将举办“第二届中国城市轨道交通装备自主创新成果展示会”。将有超过10场论坛会议举办，汇集300余行业大咖,吸引超过6000名参会嘉宾。展览规模预计参展单位近300家，展出面积逾20,000㎡，专业观众超20,000人次。旨在响应国家大湾区发展战略，向全国、向世界展示大湾区轨道交通产业新生态、新技术、新格局。
                </p>
                <p>
                    分享大湾区轨道交通发展成果，拓展国内外地区交流合作平台；将行业优质企业集聚湾区，展示先进装备、先进技术、先进理念；行业专家、菁英思想碰撞交流，带动产业生态协同，实现融合发展；带动、促进区域协同创新共同体建设，助力现代化的综合交通运输体系的构建，推进具有国际竞争力的现代产业体系建设。
                </p>
            <!-- <div class="introduce" v-html="content"> -->
            </div>
        </template>
        <!-- 气泡数据 -->
        <template v-if="isMobile">
            <div class="m_num mnum1">
                <div class="m_numCell">
                    <div class="m_cell flex_column_cc">
                        <div class="flex_fec">
                            <span class="m_first">20,000</span>
                            <span class="m_sec">㎡</span>
                        </div>
                        <span class="numCellSon">展示空间</span>
                    </div>
                </div>
                <div class="m_numCell">
                    <div class="m_cell flex_column_cc">
                        <div class="flex_fec">
                            <span class="m_sec fs18px">近</span>
                            <span class="m_first">300</span>
                        </div>
                        <span class="numCellSon">展商参展</span>
                    </div>
                </div>
                <div class="m_numCell">
                    <div class="m_cell flex_column_cc">
                        <div class="flex_fec">
                            <span class="m_first">20000</span>
                            <span class="m_sec">+</span>
                        </div>
                        <span class="numCellSon">专业观众</span>
                    </div>
                </div>
                <div class="m_numCell">
                    <div class="m_cell flex_column_cc">
                        <div class="flex_fec">
                            <span class="m_first">5</span>
                            <span class="m_sec"></span>
                        </div>
                        <span class="numCellSon">列城轨实车</span>
                    </div>
                </div>
            </div>
            <div class="m_num mnum2">
                <div class="m_numCell">
                    <div class="m_cell flex_column_cc">
                        <div class="flex_fec">
                            <span class="m_first">10</span>
                            <span class="m_sec">+</span>
                        </div>
                        <span class="numCellSon">论坛会议</span>
                    </div>
                </div>
                <div class="m_numCell">
                    <div class="m_cell flex_column_cc">
                        <div class="flex_fec">
                            <span class="m_first">300</span>
                            <span class="m_sec">+</span>
                        </div>
                        <span class="numCellSon">行业大咖</span>
                    </div>
                </div>
                <div class="m_numCell">
                    <div class="m_cell flex_column_cc">
                        <div class="flex_fec">
                            <span class="m_first">6000</span>
                            <span class="m_sec">+</span>
                        </div>
                        <span class="numCellSon">参会代表</span>
                    </div>
                </div>
                <div class="m_numCell">
                    <div class="m_cell flex_column_cc">
                        <div class="flex_fec">
                            <span class="m_first">10</span>
                            <span class="m_sec">+</span>
                        </div>
                        <span class="numCellSon">配套活动</span>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="num flex_fcc">
                <div class="numCell flex_column_cc">
                    <div class="cell flex_fec">
                        <span>20,000</span>
                        <span>㎡</span>
                    </div>
                    <span>展示空间</span>
                </div>
                <div class="numCell flex_column_cc">
                    <div class="cell flex_fec flex_reverse">
                        <span>300</span>
                        <span class="fs18px">近</span>
                    </div>
                    <span>展商参展</span>
                </div>
                <div class="numCell flex_column_cc">
                    <div class="cell flex_fec">
                        <span>20000</span>
                        <span>+</span>
                    </div>
                    <span>专业观众</span>
                </div>
                <div class="numCell flex_column_cc">
                    <div class="cell flex_fcc">
                        <span>5</span>
                        <span></span>
                    </div>
                    <span>列城轨实车</span>
                </div>
            </div>
            <div class="num flex_fcc">
                <div class="numCell flex_column_cc">
                    <div class="cell flex_fcc">
                        <span>10</span>
                        <span>+</span>
                    </div>
                    <span>论坛会议</span>
                </div>
                <div class="numCell flex_column_cc">
                    <div class="cell flex_fec">
                        <span>300</span>
                        <span>+</span>
                    </div>
                    <span>行业大咖</span>
                </div>
                <div class="numCell flex_column_cc">
                    <div class="cell flex_fec">
                        <span>6000</span>
                        <span>+</span>
                    </div>
                    <span>参会代表</span>
                </div>
                <div class="numCell flex_column_cc">
                    <div class="cell flex_fcc">
                        <span>10</span>
                        <span>+</span>
                    </div>
                    <span>配套活动</span>
                </div>
            </div>
        </template>

        <h3 v-if="!isMobile">展区分类</h3>
        <swiper :options="swiperOption2" v-if="!isMobile">
            <swiper-slide v-for="(item, i) in imgList" :key="i">
                <div class="picIntroduce flex_fcc">
                    <div class="img" @mouseover="mouseover(i, k)" @mouseout="mouseout(i, k)" v-for="(it, k) in item"
                        :key="k">
                        <div class="imgContent">
                            <img :src="it.imgurl" />
                        </div>
                        <transition appear>
                            <!-- <div class="intro" v-show="it.show"> -->
                            <div class="intro">
                                <div class="h2">{{it.h2}}</div>
                                <div class="h5">{{it.h5}}</div>
                            </div>
                        </transition>
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-button-prev swiper-button-black" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-black" slot="button-next"></div>
        </swiper>
        <div class="range" v-else>
            <h3>
                <img :src="require('@/assets/mobileImages/h3_left.png')" alt="">
                <span>展区分类</span>
                <img :src="require('@/assets/mobileImages/h3_right.png')" alt="">
            </h3>
            <el-divider></el-divider>
            <div class="picList">
                <!-- <img v-for="(item, i) in mimgList" :key="i" :src="item.url" alt=""
                    @click="toExhibit(item.h2)"> -->
                <img v-for="(item, i) in 11" :key="i" :src="require(`@/assets/mobileImages/z${i}.png`)" alt=""
                    @click="toExhibit(i)">
            </div>
        </div>

        <h3 :style="{margin: isMobile?'12px 0 !important':''}">资讯报道</h3>
        <swiper :options="swiperOption3" v-if="!isMobile">
            <swiper-slide v-for="(item, i) in reportList" :key="i">
                <div class="reportBox flex_fcc">
                    <div class="report" v-for="(ik, k) in item" :key="k" @click="handleDetail(ik.url)">
                        <div class="img">
                            <img :src="ik.image||require('@/assets/images/banner.png')" width="100%" />
                        </div>
                        <h4>{{ik.title}}</h4>
                        <div class="text">
                            {{ik.content}}
                        </div>
                        <div class="time flex_fsbc">
                            <span>{{ik.time}}</span>
                            <span>详情</span>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev swiper-button-black" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-black" slot="button-next"></div>
        </swiper>
        <div class="reportList" v-else>
            <div class="rlcell flex_fsbc" v-for="(item, i) in reportList[0]" :key="i">
                <div class="rlcleft">
                    <h4 @click="handleDetail(item.url)">{{item.title}}</h4>
                    <div class="content">
                        {{item.content}}
                    </div>
                    <div class="time">{{item.time}}</div>
                </div>
                <img class="rlcright" :src="item.image||require('@/assets/images/banner.png')" />
            </div>
        </div>

        <!-- 关于我们     -->
        <template v-if="isMobile">
            <div class="m_aboutus">
                <h3>关于我们</h3>
                <div class="m_abusMid">
                    <div class="m_abmleft">
                        <div class="m_unit">
                            <span class="m_title">主办单位</span>
                            <div class="m_name">
                                <span>中国城市轨道交通协会</span>
                                <span>广州地铁集团有限公司</span>
                            </div>
                        </div>
                        <div class="m_unit">
                            <span class="m_title">承办单位</span>
                            <div class="m_name">
                                <span>北京中城轨会展有限公司</span>
                                <span>广州市轨道交通产业联盟</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="aboutus">
                <h3>关于我们</h3>
                <div class="abusMid">
                    <div class="abmleft">
                        <div class="unit">
                            <span class="title">主办单位：</span>
                            <div class="name">
                                <span>中国城市轨道交通协会</span>
                                <span>广州地铁集团有限公司</span>
                            </div>
                        </div>
                        <div class="unit">
                            <span class="title">承办单位:</span>
                            <div class="name">
                                <span>北京中城轨会展有限公司</span>
                                <span>广州市轨道交通产业联盟</span>
                            </div>
                        </div>
                    </div>
                    <div class="abmright">
                        <img class="l1" :src="require('@/assets/images/z5@2x(3).png')" alt="">
                        <!-- <img class="r1" :src="require('@/assets/images/z39@2x.png')" alt=""> -->
                        <img class="r2" :src="require('@/assets/images/gzrailway.png')" alt="">
                    </div>
                </div>
            </div>
        </template>


    </div>
</template>

<script>
    import http_mock from '@/api/mockRequest.js'
    import {
        exhibitiondisplaycommonGetList,
    } from '@/api/exhibition'
    // 引入插件
    import {
        swiper,
        swiperSlide
    } from "vue-awesome-swiper";
    import "swiper/dist/css/swiper.css";
    export default {
        name: 'ExhibitionprojectBanner',
        components: {
            swiper,
            swiperSlide
        },
        data() {
            return {
                bannerList: [{
                        imgurl: require('@/assets/images/banner.png'),
                        type: 'image',
                        show: false,
                    },
                ],
                bannerList2: [{
                        imgurl: require('@/assets/mobileImages/m_banner.png'),
                        type: 'image',
                        show: false,
                    },
                    // {
                    //     imgurl: require('@/assets/images/banner.png'),
                    //     show: false,
                    // },                    
                ],
                imgList: [
                    [{
                            imgurl: require('@/assets/images/exhiRange1.png'),
                            show: false,
                            h2: '1. 智能列车及先进车辆段配套装备',
                            h5: '全面展示先进轨道交通及铁路机车车辆技术、车辆组件及零部件、机电设备装备、轨道工业制造装备及材料、车辆段设备等。',
                        },
                        {
                            imgurl: require('@/assets/images/exhiRange2.png'),
                            show: false,
                            h2: '2. 通信信号系统及城轨云',
                            h5: '全面展示城轨行业的数字化、信息化建设成果，互联互通的全自动运行、人工智能技术。重点突出轨道交通 5G 应用技术、云计算、物联网等先进技术。',
                        },
                        {
                            imgurl: require('@/assets/images/exhiRange3.png'),
                            show: false,
                            h2: '3. 供电系统及智慧能源',
                            h5: '全面展示轨道交通供电系统建设成果，重点突出“双碳”及利于打造特有产业链的创新性产品。',
                        },
                        {
                            imgurl: require('@/assets/images/exhiRange4.png'),
                            show: false,
                            h2: '4. 智慧车站、智能运输及配套装备',
                            h5: '全面展示先进车站技术及关键配套部件与装备。重点突出电扶梯及新材料、自动售检票系统、屏蔽门安全系统以及其它地铁站点设备等。',
                        },

                    ],
                    [{
                            imgurl: require('@/assets/images/exhiRange5.png'),
                            show: false,
                            h2: '5. 先进轨道交通基础设施建设及施工技术',
                            h5: '全面展示轨道交通基础设施建设的产业链及技术、工程监理与施工系统、工程检测与监测技术、建设管理系统等，推动“四新”成果应用推广。重点突出装配式建筑设计施工、盾构施工创新工艺、轨道减振降噪、新型防火防水材料等领域的展示。',
                        },
                        {
                            imgurl: require('@/assets/images/exhiRange6.png'),
                            show: false,
                            h2: '6. 设计、咨询、检测认证与安全管理综合',
                            h5: '全面展示大湾区设计咨询领域优势、国家质量基础设施体系（NQI）标准化建设工作、轨道交通安全管理发展的重要成果，凸显大湾区设计咨询、检测认证、安全管理等领域的协同创新。',
                        },
                        {
                            imgurl: require('@/assets/images/exhiRange7.png'),
                            show: false,
                            h2: '7. 城轨人才',
                            h5: '全面展示城轨行业人才培养、技能竞赛、师资培训、职业教育等内容。重点突出轨交企业及相关机构对轨道交通人力资源开发利用的成果。',
                        },
                        {
                            imgurl: require('@/assets/images/exhiRange8.png'),
                            show: false,
                            h2: '8. 轨道交通物资采购',
                            h5: '邀请来自全国各地包括深圳、佛山、东莞、武汉、上海、杭州、沈阳、合肥等多家轨道交通业主单位共聚一堂，并在现场设置各业主单位驻场供需对接专区，充分释放业主单位采购新需求，践行“三优”采购标准，积极赋能供应商业务新增量。',
                        },
                    ],
                    [{
                            imgurl: require('@/assets/images/exhiRange9.png'),
                            show: false,
                            h2: '9. 轨道交通产业园',
                            h5: '邀请包括广州白云、南沙、花都轨道交通相关产业园等全国各地的产业园区，综合展示园区的政策、配套、规划等内容。',
                        },
                        {
                            imgurl: require('@/assets/images/exhiRange10.png'),
                            show: false,
                            h2: '10. 站城融合（TOD）及配套服务',
                            h5: '全面展示大湾区站城融合 TOD4.0 规划与实践探索成果，囊括 TOD 相关综合开发类、专业服务类、产业经营类项目与解决方案。重点突出广州地铁沿线地块的项目开发成果展示。',
                        },
                        {
                            imgurl: require('@/assets/images/exhiRange11.png'),
                            show: false,
                            h2: '11. 城轨文化、资源经营',
                            h5: '全面展示相关文创产品、城轨文化出版物、城轨新闻媒体产品与服务，资源经营相关企业的创新、开发、运营、管理等优秀实践成果。',
                        },
                        // {
                        //     imgurl: require('@/assets/images/exhiRange12.png'),
                        //     show: false,
                        //     h2: '12. 户外展区',
                        //     h5: '',
                        // },

                    ],
                ],
                swiperOption1: {
                    loop: false,
                    autoplay: {
                        delay: 5000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false
                    },
                    // 显示分页
                    // pagination: {
                    //   el: ".swiper-pagination",
                    //   clickable: true //允许分页点击跳转
                    // },
                    // 设置点击箭头
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }
                },
                swiperOption11: {
                    loop: false,
                    autoplay: {
                        delay: 5000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false
                    },
                    // 显示分页
                    // pagination: {
                    //   el: ".swiper-pagination",
                    //   clickable: true //允许分页点击跳转
                    // },
                    // 设置点击箭头
                    // navigation: {
                    //     nextEl: ".swiper-button-next",
                    //     prevEl: ".swiper-button-prev"
                    // }
                },
                swiperOption2: {
                    loop: false,
                    autoplay: {
                        delay: 8000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }
                },
                swiperOption22: {
                    loop: false,
                    autoplay: {
                        delay: 8000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false
                    },

                    pagination: {
                        el: '.swiper-pagination', //与slot="pagination"处 class 一致
                        clickable: true, //轮播按钮支持点击
                    }
                },
                swiperOption3: {
                    loop: false,
                    autoplay: {
                        delay: 9000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }
                },
                reportList: [],
                mimgList: [],
                isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
                searchInput: '',
                content: '',
            };
        },

        mounted() {
            var that = this
            this.$root.$on('upDateView', () => {
                that.isMobile = JSON.parse(sessionStorage.getItem('isMobile'))
            })
            this.getMockData()
            // this.exhibitiondisplaycommonGetList()
        },

        methods: {
            exhibitiondisplaycommonGetList() {
                var param = {
                    firstDirName: '展会介绍',
                    secondDirName: '展会概况',
                }

                exhibitiondisplaycommonGetList(param).then(res=>{
                    var data = res.data.data
                    data.map(r=>{
                        if (r.firstDirName=='展会介绍'&&r.secondDirName=='展会概况') {
                            if (r.plateName=='板块一') {
                                this.content = JSON.parse(r.plateBody).form.content
                            }

                        }
                    })
                })

                var param2 = {
                    firstDirName: '展商服务',
                    secondDirName: '展品范围',
                }
                var cellList = []

                exhibitiondisplaycommonGetList(param2).then(res=>{
                    var data = res.data.data
                    data.map(r=>{
                        if (r.firstDirName=='展商服务'&&r.secondDirName=='展品范围') {
                            var obj = JSON.parse(r.plateBody)
                            obj.form.fileList[0].url = JSON.parse(r.plateBody).form.fileList[0].response.data.link   
                            obj.id = r.id    
                            obj.imgurl = JSON.parse(r.plateBody).form.fileList[0].response.data.link   
                            obj.h2 = JSON.parse(r.plateBody).form.title
                            obj.h5 = JSON.parse(r.plateBody).form.content
                            cellList.push(obj)
                        }
                    })
                    this.mimgList = cellList
                    // 二维数组转换
                    var arr = []
                    var secIndex = 0
                    arr[secIndex] = []
                    cellList.map((r, i)=>{ // 分为一页3条数据
                        if ((i+1) % 3 == 0) { // 余10
                            arr[secIndex].push(r)
                            secIndex += 1 // 二维数组下标
                            arr[secIndex] = []
                        } else {
                            arr[secIndex].push(r)
                        }
                    })
                    arr[arr.length - 1].length == 0 ? arr.splice(arr.length - 1, 1) : '' // 去除最后一个为空的数组                    
                    this.imgList = arr

                })                
            },                
            getMockData() {
                http_mock('getReport/list').then(response => {
                    var data = response._data.data
                    var arr = []
                    var secIndex = 0
                    arr[secIndex] = []
                    data.map((r, i)=>{ // 分为一页3条数据
                        if ((i+1) % 3 == 0) { // 余10
                            arr[secIndex].push(r)
                            secIndex += 1 // 二维数组下标
                            arr[secIndex] = []
                        } else {
                            arr[secIndex].push(r)
                        }
                    })
                    arr[arr.length - 1].length == 0 ? arr.splice(arr.length - 1, 1) : '' // 去除最后一个为空的数组
                    this.reportList = arr
                })
            },            
            toExhibit(i) {
                return
                var name = ''
                // name = i
                switch (JSON.stringify(i)) {
                    case '0':
                        name = '智能列车及先进车辆段配套装备'
                        break;
                    case '1':
                        name = '通信信号系统及城轨云'
                        break;
                    case '2':
                        name = '智慧车站、智能运输及配套装备'
                        break;
                    case '3':
                        name = '供电系统及智慧能源'
                        break;
                    case '4':
                        name = '粤港澳大湾区轨道交通成果'
                        break;
                    case '5':
                        name = '中国城市轨道交通装备自主创新'
                        break;
                    case '6':
                        name = '站城融合（TOD）及配套服务；城轨文化、资源经营'
                        break;
                    case '7':
                        name = '先进轨道交通基础设施建设及施工技术'
                        break;
                    case '8':
                        name = '设计、咨询、检测认证与安全管理综合'
                        break;
                    case '9':
                        name = '广州市轨道交通产业联盟综合'
                        break;
                    case '10':
                        name = '物资采购供应商'
                        break;
                    case '11':
                        name = '户外展区'
                        break;

                    default:
                        break;
                }
                this.$router.push(`/home/OnlineProductList?name=${name}`)
            },
            searchFocus() {
                this.$router.push(`/searchResult?currentNav=${this.$route.query.currentNav}`)
            },
            handleToNav(val) {
                var url = ''
                sessionStorage.setItem('currentNav', val)

                switch (val) {
                    case '首页':
                        url = '/home/banner'
                        break;
                    case '展会概况':
                        url = '/home/situation'
                        break;
                    case '亮点推荐':
                        url = '/home/highlight'
                        break;
                    case '组织架构':
                        url = '/home/framework'
                        break;
                    case '关于我们':
                        url = '/home/aboutus'
                        break;
                    case '下载中心':
                        url = '/home/downloadCenter'
                        break;
                    case '展位预定':
                        url = '/home/exhibitsSiteOrder'
                        break;
                    case '展品范围':
                        url = '/home/exhibitionRange'
                        break;
                    case '参展流程':
                        url = '/home/joinExhibitionPath'
                        break;
                    case '特装搭建':
                        url = '/home/equipBuild'
                        break;
                    case '展商登录':
                        // 跳转第三方
                        window.open('http://gzgdzs.zbase.cn/login_zs.html?zhid=1')
                        return
                        break;
                    case '展商交通指引':
                        // url = '/home/trafficGuide'
                        url = '/home/audienceTrafficGuide'
                        break;
                    case '观众交通指引':
                        url = '/home/audienceTrafficGuide'
                        break;
                    case '论坛/观展报名':
                        // 跳转第三方
                        window.open('http://xt.zbase.cn/1326/home.html')
                        return
                        break;
                    case '参观指南':
                        window.open('http://www.gbatransit.com/resource/参观指南-电子版.pdf', '_self')
                        return
                        url = '/home/visitGuide'
                        break;
                    case '同期活动':
                        url = '/home/sameActivity'
                        break;
                    case '展会直播':
                        // 跳转第三方
                        window.open('https://wx.vzan.com/live/page/980809753?v=1687752770127', '_self')
                        return                   
                        url = '/home/forumLive'
                        break;
                    case '合作媒体':
                        url = '/home/cooperateMedia'
                        break;
                    case '资讯报道':
                        url = '/home/consultReport'
                        break;
                    case '媒体矩阵':
                        url = '/home/mediamatrix'
                        break;
                    case '精彩图集':
                        url = '/home/wnderfulPic'
                        break;
                    case '线上展':
                        url = '/home/onlineShow'
                        break;
                    case '联系我们':
                        url = '/home/contactUs'
                        break;

                    default:
                        url = '/home'
                        break;
                }
                this.$router.push(url)

            },
            onClick() {

            },
            handleDetail(url) {
                // this.isDetail = true
                window.open(url)
            },
            mouseover(i, k) {
                this.imgList[i][k].show = true
            },
            mouseout(i, k) {
                this.imgList[i][k].show = false
            },
            mouseover2(i, k) {
                this.imgList[i][k].show = true
            },
            mouseout2(i, k) {
                this.imgList[i][k].show = false
            },
        },
    };
</script>

<style lang="scss" scoped>
    .flex_reverse {
        flex-direction: row-reverse;
    }
	.fs18px {
		font-size: 18px !important;
	}
    /* 进入：始状态 */
    .v-enter {
        transform: translateY(100%);
    }

    /* 进入：末状态 */
    .v-enter-to {
        transform: translateY(0);
    }

    /* 进入动画 */
    .v-enter-active {
        transition: 1s;
    }

    /* 离开：始状态 */
    .v-leave {
        transform: translateY(0);
    }

    /* 离开：末状态 */
    .v-leave-to {
        transform: translateY(100%);
    }

    /* 离开动画 */
    .v-leave-active {
        transition: 1s;
    }


    .banner {
        // margin-top: 38px;
        background-color: #fff;

        h3 {
            height: 21px;
            font-size: 20px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 700;
            color: #0C266D;
            line-height: 24px;
            margin: 70px 0 32px 0;
        }

        .range {
            width: 85%;
            margin: 0 auto;
            margin-top: 12px;
            padding: 12px 16px;
            background: #FAFAFC;
            box-shadow: 0px 1px 0px 0px #E6E6E6;
            border-radius: 6px 6px 6px 6px;

            h3 {
                margin: 0 !important;

                img {
                    width: 27px;
                    height: 9px;
                }

                span {
                    height: 21px;
                    font-size: 17px;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: 700;
                    color: #0C266D;
                    line-height: 24px;
                    margin: 0 20px;
                }
            }

            .el-divider--horizontal {
                margin: 15px 0 10px 0 !important;
            }

            .picList {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                img {
                    width: 152px;
                    height: 90px;
                    margin-bottom: 16px;
                }
            }
        }

        .introduce {
            width: 960px;
            // height: 160px;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            line-height: 28px;
            margin: 0 auto;
            text-align: justify;

            p {
                font-size: 16px;
            }
        }

        .num {
            margin-top: 60px;

            .numCell {
                width: 116px;

                .cell {
                    width: 116px;
                    height: 116px;
                    border-radius: 50%;
                    background: rgba(101, 188, 98, .1);

                    span {
                        &:nth-child(1) {
                            height: 48px;
                            font-size: 44px;
                            font-family: D-DIN-Bold, D-DIN;
                            font-weight: 700;
                            color: rgba(0, 0, 0, 0.898);
                            line-height: 48px;
                        }

                        &:nth-child(2) {
                            margin-left: 4px;
                            height: 48px;
                            font-size: 18px;
                            font-family: D-DIN-Regular, D-DIN;
                            font-weight: 400;
                            color: #3D3D3D;
                            line-height: 58px;
                        }
                    }
                }

                span {
                    margin-top: 9px;
                    height: 21px;
                    font-size: 17px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #727272;
                    line-height: 24px;
                }

                &:nth-child(2n) {
                    .cell {
                        background: rgba(237, 240, 247, 1);
                    }
                }

                &:nth-child(2) {
                    margin: 0 148px;

                    .cell {
                        justify-content: center !important;
                        align-items: center !important;
                    }
                }

                &:nth-child(3) {
                    margin-right: 148px;

                    .cell {
                        justify-content: center !important;
                        align-items: center !important;
                    }
                }
            }
        }

        .picIntroduce {
            .img {
                margin-right: 30px;
            }

            .imgContent {
                width: 300px;
                height: 300px;
            }

            img {
                max-width: 280px;
                object-fit: fill;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    // margin-right: 30px;
                }

                position: inherit;
            }

            .intro {
                width: 276px;
                // height: 125px;
                padding: 12px;
                background: rgba(12, 38, 109, 0.6);
                position: fixed;
                bottom: 0;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;


                .h2 {
                    // height: 24px;
                    font-size: 17px;
                    font-weight: 700;
                    color: #FFFFFF;
                    line-height: 24px;
                    text-align: left;
                    margin-bottom: 12px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }

                .h5 {
                    text-align: justify;
                    height: 88px;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 15px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 6;
                    overflow: hidden;
                }
            }
        }

        .aboutus {
            height: 540px;
            margin-top: 60px;
            background: #F7F7F7;

            h3 {
                margin: 36px 0 0 0;
                padding-top: 36px;
            }

            .abusMid {
                width: 870px;
                height: 290px;
                margin-top: 71px !important;
                margin: 0 auto;
                display: flex;

                .abmleft {
                    margin-right: 160px;
                }

                .abmright {
                    position: relative;

                    .l1 {
                        width: 327px;
                        height: 255px;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .r1 {
                        width: 68px;
                        height: 60px;
                        position: absolute;
                        top: 59px;
                        left: 347px;
                    }

                    .r2 {
                        // width: 207px;
                        // height: 169px;
                        width: 327px;
                        height: 255px;                        
                        position: absolute;
                        // top: 139px;
                        top: 0px;
                        left: 347px;

                    }
                }

                .unit {
                    text-align: left;

                    &:nth-child(1) {
                        margin-bottom: 60px;
                    }

                    .title {
                        height: 30px;
                        font-size: 16px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #000000;
                        line-height: 30px;
                    }

                    .name {
                        margin-top: 10px;
                        font-size: 14px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #727272;
                        line-height: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;

                        span {
                            margin-bottom: 10px;
                        }

                    }

                }
            }
        }

        .reportBox {}

        .report {
            cursor: pointer;
            width: 381px;
            margin-bottom: 40px;

            &:nth-child(1),
            &:nth-child(2) {
                margin-right: 72PX;
            }

            img {
                width: 381px;
                height: 209px;
                // img {
                //     width: 100%;
                //     max-height: 100%;
                // }
            }

            h4 {
                height: 64px;
                font-size: 24px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: 700;
                color: #000000;
                line-height: 32px;
                text-align: left;
                margin: 15px 0 12px 0;
                /* Firefox */
                display: -moz-box;
                -moz-box-orient: vertical;
                -moz-line-clamp: 2;
                overflow: hidden;

                /* Safari, Opera, and Chrome */
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;

                /* W3C */
                display: box;
                box-orient: vertical;
                line-clamp: 2;
                overflow: hidden;
            }

            .text {
                height: 57px;
                text-align: left;
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #727272;
                line-height: 20px;
                margin-bottom: 10px;
                /* Firefox */
                display: -moz-box;
                -moz-box-orient: vertical;
                -moz-line-clamp: 3;
                overflow: hidden;

                /* Safari, Opera, and Chrome */
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;

                /* W3C */
                display: box;
                box-orient: vertical;
                line-clamp: 3;
                overflow: hidden;

            }

            .time {
                span {
                    height: 20px;
                    font-size: 12px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #0C266D;
                    line-height: 20px;
                }
            }


        }
    }

    .swiper-slide {
        img {
            height: 70%;
        }
    }

    // 移动端代码开始
    .topSearch {
        // width: 375px;
        // height: 56px;
        width: 100%;
        line-height: 56px;
        background: #FFFFFF;
        margin: 0 auto;
        margin-top: 0px;

        .el-input {
            // width: 351px;
            width: 90%;
            height: 40px;
            border-radius: 6px 6px 6px 6px;

            ::v-deep .el-input__inner {
                background: #F5F5F7;
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #888889;
            }
        }
    }

    .navList {
        // width: 321px;
        // height: 150px;
        width: 85%;
        background: #FAFAFC;
        box-shadow: 0px 1px 0px 0px #E6E6E6;
        border-radius: 6px 6px 6px 6px;
        margin: 17px 12px;
        padding: 8px 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;

        .ncell {
            width: 67px;
            height: 70px;

            &:not(:nth-child(4n)) {
                margin-right: 16px;
            }

            img {
                width: 30px;
                height: 30px;
            }

            span {
                height: 16px;
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #222229;
                line-height: 16px;
            }
        }
    }

    .m_introduce {
        // width: 327px;
        width: 85%;
        // height: 318px;
        background: #FAFAFC;
        box-shadow: 0px 1px 0px 0px #E6E6E6;
        border-radius: 6px 6px 6px 6px;
        margin: 16px auto;
        padding: 12px;

        h3 {
            margin: 0 !important;

            img {
                width: 27px;
                height: 9px;
            }

            span {
                height: 21px;
                font-size: 17px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: 700;
                color: #0C266D;
                line-height: 24px;
                margin: 0 20px;
            }
        }

        .introduce {
            width: 100%;
            margin-top: 17px;
        }

        p {
            font-size: 12px !important;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            line-height: 18px;
            text-indent: 25px;

            &:nth-child(2) {
                margin-top: 20px;
            }
        }
    }

    .m_num {
        // width: 351px;
        width: 92%;
        // height: 181px;
        background: #FAFAFC;
        box-shadow: 0px 1px 0px 0px #E6E6E6;
        border-radius: 6px 6px 6px 6px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: space-around;

        .m_numCell {
            height: 70px;
            margin: 0 42px;
            margin-bottom: 10px;

            .m_cell {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                background: rgba(101, 188, 98, .1);
                justify-content: flex-end;

                .m_first {
                    height: 26px;
                    font-size: 24px;
                    font-family: D-DIN-Bold, D-DIN;
                    font-weight: 700;
                    color: rgba(0, 0, 0, 0.898);
                    line-height: 26px;
                }

                .m_sec {
                    height: 20px;
                    font-size: 18px;
                    font-family: D-DIN-Regular, D-DIN;
                    font-weight: 400;
                    color: #3D3D3D;
                    line-height: 20px;
                }
            }

            .numCellSon {
                height: 21px;
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #727272;
                line-height: 24px;

            }

            &:nth-child(2n) {
                .m_cell {
                    background: rgba(237, 240, 247, 1);
                }
            }

            &:nth-child(3),
            &:nth-child(4) {
                // margin-top: 24px;                       
            }

        }
    }

    .mnum1 {
        padding: 31px 0 0px 0;
    }

    .mnum2 {
        padding: 0px 0 31px 0;
    }

    .m_aboutus {
        // height: 510px;
        width: 90%;
        margin: 0 auto;
        // margin-top: 60px;
        background: #F7F7F7;

        h3 {
            margin: 36px 0 0 0;
            padding-top: 36px;
        }

        .m_abusMid {
            height: 290px;
            margin-top: 20px !important;
            margin: 0 auto;
            display: flex;
            justify-content: center;

            .m_abmleft {
                // margin-right: 160px;
            }

            .m_abmright {
                position: relative;

                .l1 {
                    width: 327px;
                    height: 255px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .r1 {
                    width: 68px;
                    height: 60px;
                    position: absolute;
                    top: 59px;
                    left: 347px;
                }

                .r2 {
                    width: 207px;
                    height: 169px;
                    position: absolute;
                    top: 139px;
                    left: 347px;

                }
            }

            .m_unit {
                text-align: center;

                &:nth-child(1) {
                    margin-bottom: 60px;
                }

                .m_title {
                    height: 30px;
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #000000;
                    line-height: 30px;
                }

                .m_name {
                    margin-top: 10px;
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #727272;
                    line-height: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;

                    span {
                        margin-bottom: 10px;
                    }

                }

            }
        }
    }

    .m_report {
        width: 200px !important;

        img {
            width: 180px !important;
            height: 120px !important;
        }

        h4 {
            font-size: 16px !important;
        }

        .text {
            display: none;
        }
    }

    .reportList {
        .rlcell {
            width: 950px;
            // height: 110px;
            margin: 0 auto;
            padding: 20px 0;

            &:nth-child(1) {
                border-top: 1px solid #D8D8D8;
            }

            border-bottom: 1px solid #D8D8D8;
        }

        .rlcleft {
            width: 75%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            h4 {
                cursor: pointer;
                // height: 32px;
                font-size: 24px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: 700;
                color: #000000;
                line-height: 32px;
                text-align: left;

                // /* Firefox */
                // display:-moz-box;
                // -moz-box-orient:vertical;
                // -moz-line-clamp: 1;
                // overflow: hidden;

                // /* Safari, Opera, and Chrome */
                // display:-webkit-box;
                // -webkit-box-orient: vertical;
                // -webkit-line-clamp: 1;
                // overflow: hidden;

                // /* W3C */
                // display:box;
                // box-orient:vertical;
                // line-clamp: 1;
                // overflow: hidden;
            }

            .content {
                margin: 18px 0 8px 0;
                height: 40px;
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #727272;
                line-height: 20px;
                text-align: left;

                /* Firefox */
                display: -moz-box;
                -moz-box-orient: vertical;
                -moz-line-clamp: 2;
                overflow: hidden;

                /* Safari, Opera, and Chrome */
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;

                /* W3C */
                display: box;
                box-orient: vertical;
                line-clamp: 2;
                overflow: hidden;
            }

            .time {
                text-align: left;
                height: 20px;
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #0C266D;
                line-height: 20px;
            }
        }

        .rlcright {
            width: 192px;
            height: 129px;
        }
    }


    .mobileBanner {
        margin: 10px 12px;
    }

    // 移动端代码结束


    // 更改轮播图翻页icon样式
    ::v-deep .swiper-button-prev {
        left: 70px !important;
    }

    ::v-deep .swiper-button-next {
        right: 70px !important;
    }

    ::v-deep .swiper-button-prev,
    ::v-deep .swiper-button-next {
        width: 28px !important;
        height: 28px !important;
    }

    @media screen and (max-width: 1300px) {

        // 非pc端
        .banner {
            margin-top: 0px;
        }

        .rlcell {
            width: 550px !important;
        }

        .picIntroduce {
            img {

                // width: 150px !important;
                // height: 150px !important;
                object-fit:fill,
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    margin-right: 0px !important;
                }
            }
        }


    }

    @media screen and (max-width: 650px) {

        // 非pc端
        .rlcell {
            width: calc(100% - 24px) !important;
            border-top: none !important;
            // height: 108px;
            background: #FFFFFF;
            padding: 9px 12px !important;
            border-radius: 4px 4px 4px 4px;
            margin-bottom: 12px !important;

            h4 {
                font-size: 16px !important;
                line-height: 22px !important;
            }

            .rlcleft {
                .content {
                    height: 16px !important;
                    font-size: 12px !important;
                    /* Firefox */
                    -moz-line-clamp: 1 !important;

                    /* Safari, Opera, and Chrome */
                    -webkit-line-clamp: 1 !important;

                    /* W3C */
                    line-clamp: 1 !important;

                    margin: 7px 0 0 0 !important;
                }
            }

            .rlcright {
                width: 110px !important;
                height: 84px !important;
                border-radius: 2px 2px 2px 2px;
                margin-left: 12px;
            }
        }
    }

    @media screen and (max-width: 320px) {

        // 非pc端
        .banner {
            .picList {
                justify-content: center !important;
            }

            // margin-top: 0px;
            .m_numCell {
                // transform: scale(0.5);
                margin: 0 22px !important;
            }
        }

    }
</style>