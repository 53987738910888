import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Banner from '../components/banner.vue'
import SearchResult from '../views/searchResult.vue'
import ShowDetail from '../views/OnlineExhibition/onlineShowDetail.vue'
import showProductDetail from '../views/OnlineExhibition/onlineProductDetail.vue'
import OnlineProductList from '../views/OnlineExhibition/onlineProductList.vue'
import Situation from '../views/exhibitionIntroduce/situation.vue'
import Highlight from '../views/exhibitionIntroduce/highlight.vue'
import Framework from '../views/exhibitionIntroduce/framework.vue'
import Aboutus from '../views/exhibitionIntroduce/aboutus.vue'
import DownloadCenter from '../views/exhibitionIntroduce/downloadCenter.vue'
import ExhibitsSiteOrder from '../views/exhibitionService/exhibitsSiteOrder.vue'
import ExhibitionRange from '../views/exhibitionService/exhibitionRange.vue'
import JoinExhibitionPath from '../views/exhibitionService/joinExhibitionPath'
import EquipBuild from '../views/exhibitionService/equipBuild.vue'
import TrafficGuide from '../views/exhibitionService/trafficGuide'
import AudienceTrafficGuide from '../views/audienceService/trafficGuide'
import VisitGuide from '../views/audienceService/visitGuide.vue'
import SameActivity from '../views/audienceService/sameActivity.vue'
import ForumLive from '../views/mediaCenter/forumLive.vue'
import CooperateMedia from '../views/mediaCenter/cooperateMedia.vue'
import ConsultReport from '../views/mediaCenter/consultReport.vue'
import Mediamatrix from '../views/mediaCenter/Mediamatrix.vue'
import WonderfulPic from '../views/wonderfulPic.vue'
import OnlineShow from '../views/OnlineExhibition/onlineShow.vue'
import ContactUs from '../views/contactUs.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/home/banner',

  },
  {
    path: '/home',
    name: 'HomeBanner',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "banner",
      name: 'Banner',
      component: Banner,
    }] 
  },
  {
    path: '/home',
    name: 'HomeSituation',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "situation",
      name: 'situation',
      component: Situation,
    }] 
  },
  {
    path: '/home',
    name: 'HomeHighlight',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "highlight",
      name: 'Highlight',
      component: Highlight,
    }] 
  },
  {
    path: '/home',
    name: 'HomeFramework',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "framework",
      name: 'framework',
      component: Framework,
    }] 
  },
  {
    path: '/home',
    name: 'HomeAboutus',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "aboutus",
      name: 'aboutus',
      component: Aboutus,
    }] 
  },
  {
    path: '/home',
    name: 'HomeDownloadCenter',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "downloadCenter",
      name: 'downloadCenter',
      component: DownloadCenter,
    }] 
  },
  {
    path: '/home',
    name: 'HomeExhibitsSiteOrder',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "exhibitsSiteOrder",
      name: 'exhibitsSiteOrder',
      component: ExhibitsSiteOrder,
    }] 
  },
  {
    path: '/home',
    name: 'HomeExhibitionRange',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "exhibitionRange",
      name: 'exhibitionRange',
      component: ExhibitionRange,
    }] 
  },
  {
    path: '/home',
    name: 'HomeJoinExhibitionPath',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "joinExhibitionPath",
      name: 'joinExhibitionPath',
      component: JoinExhibitionPath,
    }] 
  },
  {
    path: '/home',
    name: 'HomeEquipBuild',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "equipBuild",
      name: 'equipBuild',
      component: EquipBuild,
    }] 
  },
  {
    path: '/home',
    name: 'HomeTrafficGuide',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "trafficGuide",
      name: 'trafficGuide',
      component: TrafficGuide,
    }] 
  },
  {
    path: '/home',
    name: 'HomeAudienceTrafficGuide',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "audienceTrafficGuide",
      name: 'audienceTrafficGuide',
      component: AudienceTrafficGuide,
    }] 
  },
  {
    path: '/home',
    name: 'HomeVisitGuide',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "visitGuide",
      name: 'visitGuide',
      component: VisitGuide,
    }] 
  },
  {
    path: '/home',
    name: 'HomeSameActivity',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "sameActivity",
      name: 'sameActivity',
      component: SameActivity,
    }] 
  },
  {
    path: '/home',
    name: 'HomeForumLive',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "forumLive",
      name: 'forumLive',
      component: ForumLive,
    }] 
  },
  {
    path: '/home',
    name: 'HomeCooperateMedia',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "cooperateMedia",
      name: 'cooperateMedia',
      component: CooperateMedia,
    }] 
  },
  {
    path: '/home',
    name: 'HomeConsultReport',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "consultReport",
      name: 'consultReport',
      component: ConsultReport,
    }] 
  },
  {
    path: '/home',
    name: 'HomeMediamatrix',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "mediamatrix",
      name: 'mediamatrix',
      component: Mediamatrix,
    }] 
  },
  {
    path: '/home',
    name: 'HomeWonderfulPic',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "WnderfulPic",
      name: 'WnderfulPic',
      component: WonderfulPic,
    }] 
  },
  {
    path: '/home',
    name: 'HomeOnlineShow',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "onlineShow",
      name: 'onlineShow',
      component: OnlineShow,
    }] 
  },
  {
    path: '/home',
    name: 'HomeContactUs',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "contactUs",
      name: 'contactUs',
      component: ContactUs,
    }] 
  },
  {
    path: '/searchResult',
    name: 'searchResult',
    component: SearchResult
  },
  {
    path: '/home',
    name: 'HomeShowDetail',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "showDetail",
      name: 'showDetail',
      component: ShowDetail,
    }]    
  },
  {
    path: '/home',
    name: 'HomeOnlineProductList',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "onlineProductList",
      name: 'onlineProductList',
      component: OnlineProductList,
    }]    
  },
  {
    path: '/home',
    name: 'HomeshowProductDetail',
    component: Home,
    meta: {
      keepAlive: true,
    },
    children: [{
      path: "showProductDetail",
      name: 'showProductDetail',
      component: showProductDetail,
    }]    
  },
]

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})

export default router
