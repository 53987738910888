<!--
 * @Author: huanggs huanggx@gzyct.com
 * @Date: 2023-05-15 10:51:24
 * @LastEditors: huanggs huanggx@gzyct.com
 * @LastEditTime: 2023-06-21 09:54:50
 * @FilePath: \exhibitionProject\src\views\exhibitionIntroduce\downloadCenter.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div>
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="0"></Header>
        <h3>下载中心</h3>
        <div class="content">
            <!-- <div class="list" v-for="(item, i) in data" :key="i">
                <h4>{{item.typeName}}</h4>
                <div class="file" v-for="(it, y) in item.fileList" :key="y">
                    <div class="cell flex_fsfe">
                        <img :src="require('@/assets/images/folder-download-line3@2x.png')" alt="">
                        <a target="_blank" :href="it.response.data.link">{{it.name}}</a>
                    </div>
                </div>
            </div> -->
            <!-- 以上为对接了接口 -->

            <div class="list">
                <h4>展后报告</h4>
                <div class="file">
                    <div class="cell flex_fsfe">
                        <img :src="require('@/assets/images/folder-download-line3@2x.png')" alt="">
                        <a target="_blank" href="http://www.gbatransit.com/resource/首届广州大湾区轨道交通产业发展论坛暨展览会展后报告.pdf">首届广州大湾区轨道交通产业发展论坛暨展览会展后报告.pdf（33.2mb）</a>
                        <!-- <a target="_blank" href="#">参展手册.pdf（18.5mb）</a> -->
                    </div>
                </div>
            </div>
            <div class="list">
                <h4>参展商手册</h4>
                <div class="file">
                    <div class="cell flex_fsfe">
                        <img :src="require('@/assets/images/folder-download-line3@2x.png')" alt="">
                        <a target="_blank" href="http://www.gbatransit.com/resource/%E5%8F%82%E5%B1%95%E6%89%8B%E5%86%8C.pdf">参展商手册.pdf（21.7mb）</a>
                        <!-- <a target="_blank" href="#">参展手册.pdf（18.5mb）</a> -->
                    </div>
                </div>
            </div>
            <div class="list">
                <h4>展会邀请函</h4>
                <div class="file">
                    <div class="cell flex_fsfe">
                        <img :src="require('@/assets/images/folder-download-line3@2x.png')" alt="">
                        <a target="_blank" href="http://www.gbatransit.com/resource/广州大湾区国际轨道交通产业发展论坛暨展览会邀请函20230609.pdf">【协会】（首届）广州大湾区国际轨道交通产业发展论坛暨展览会邀请函20230621.pdf（2.5mb）</a>                       
                    </div>
                    <div class="cell flex_fsfe">
                        <img :src="require('@/assets/images/folder-download-line3@2x.png')" alt="">
                        <a target="_blank" href="http://www.gbatransit.com/resource/%E9%A6%96%E5%B1%8A%E5%B9%BF%E5%B7%9E%E5%A4%A7%E6%B9%BE%E5%8C%BA%E8%BD%A8%E9%81%93%E4%BA%A4%E9%80%9A%E4%BA%A7%E4%B8%9A%E5%8F%91%E5%B1%95%E8%AE%BA%E5%9D%9B%E6%9A%A8%E5%B1%95%E8%A7%88%E9%82%80%E8%AF%B7%E5%87%BD.pdf">首届广州大湾区国际轨道交通产业发展论坛暨展览会邀请函20230601.pdf（1.9mb）</a>                    
                    </div>
                    <div class="cell flex_fsfe">
                        <img :src="require('@/assets/images/folder-download-line3@2x.png')" alt="">
                        <a target="_blank" href="http://www.gbatransit.com/resource/%E5%B9%BF%E5%B7%9E%E5%A4%A7%E6%B9%BE%E5%8C%BA%E8%BD%A8%E9%81%93%E4%BA%A4%E9%80%9A%E8%AE%BA%E5%9D%9B%E6%9A%A8%E5%B1%95%E8%A7%88%E4%BC%9A%E9%82%80%E8%AF%B7%E5%87%BD.pdf">首届广州大湾区国际轨道交通产业发展论坛暨展览会邀请函20230104.pdf（1mb）</a>                    
                    </div>                    
                    <!-- <div class="cell flex_fsfe">
                        <img :src="require('@/assets/images/folder-download-line3@2x.png')" alt="">
                        <a href="#">模板资料.pdf（18.5mb）</a>                    
                    </div>
                    <div class="cell flex_fsfe">
                        <img :src="require('@/assets/images/folder-download-line3@2x.png')" alt="">
                        <a href="#">模板资料.pdf（18.5mb）</a>                    
                    </div> -->
                </div>
            </div>
            <!-- <div class="list">
                <h4>展馆平面图</h4>
                <div class="file">
                    <div class="cell flex_fsfe">
                        <img :src="require('@/assets/images/folder-download-line3@2x.png')" alt="">
                        <a href="#">展馆平面图.pdf（18.5mb）</a>
                    </div>
                </div>
            </div>             -->
            <div class="list">
                <h4>招商手册</h4>
                <div class="file">
                    <div class="cell flex_fsfe">
                        <img :src="require('@/assets/images/folder-download-line3@2x.png')" alt="">
                        <a target="_blank" href="http://gbatransit.com/resource/%E9%A6%96%E5%B1%8A%E5%B9%BF%E5%B7%9E%E5%9B%BD%E9%99%85%E8%BD%A8%E9%81%93%E4%BA%A4%E9%80%9A%E5%B1%95%E6%8B%9B%E5%95%86%E6%89%8B%E5%86%8C.pdf">首届广州大湾区国际轨道交通产业发展论坛暨展览会招商手册.pdf（15.0mb）</a>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>

<script>
    import {
        downloadListExpand,
    } from '@/api/exhibition'
export default {
    name: 'ExhibitionprojectDownloadcenter',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
            data: [],
        };
    },

    mounted() {
        // this.exhibitiondisplaycommonGetList()
    },

    methods: {
            exhibitiondisplaycommonGetList() {
                downloadListExpand().then(res=>{
                    var data = res.data.data
                    data.map(r=>{
                        r.fileList = JSON.parse(r.fileList)
                    })
                    this.data = data
                })             
            },          
    },
};
</script>

<style lang="scss" scoped>
    h3 {
        height: 48px;
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;       
        margin-bottom: 96px;
        margin-top: 48px;
    }
    .content {
        width: 900px;
        margin: 0 auto;
        .list {
            h4 {
                height: 32px;
                font-size: 18px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: 700;
                color: #000000;
                line-height: 32px;
                text-align: left;
            }
            .file {
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #0B43BC;
                line-height: 20px;   
                padding-bottom: 10px;
                margin-bottom: 10px; 
                border-bottom: 1px solid #D8D8D8;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                .cell {
                    margin-right: 50px;
                    margin-bottom: 10px;
                }
                img {
                    width: 24px;
                    height: 24px;
                    margin-right: 12px;
                }
                a {
                    text-decoration: underline;
                }            
            }
        }
        // .cell {
        //     margin-bottom: 20px;
        //     img {
        //         width: 32px;
        //         height: 32px;
        //         margin-right: 10px;
        //     }
        //     a {
        //         text-decoration: underline;
        //     }
        // }
    }
    @media screen and (max-width: 1300px) { // 非pc端
        .content {
            width: 90% !important;
            margin: 0 auto;
        }
    }
    @media screen and (max-width: 950px) { // 非pc端
        .content {
            width: 90% !important;
            margin: 0 auto;
            .list {
                .file {
                    height: 100%;
                    flex-direction: column;
                    .cell {
                        align-items: center;
                    }
                }
            }
        }
    }
</style>