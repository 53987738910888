<template>
  <div class="home" @click="clickHome">
    <div class="exhibitionIndex">
      <!-- 顶部导航 -->
      <Navigation @handleShowExpand="handleShowExpand" @handleNavEvent="handleNavEvent" :activeIndex="currentNav"></Navigation>
      <keep-alive>
        <router-view class="avue-view" v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <router-view class="avue-view" v-if="!$route.meta.keepAlive"/>        

      <Footer @handleNavEvent="handleNavEvent"></Footer>
    </div>

    <!-- 移动端下拉导航 -->
    <div class="downList" v-if="showExpand" @click.stop>
      <el-menu
        id="elMenu"
        unique-opened
        default-active="展会介绍"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @select="handleSelect"
        @close="handleClose">
          <el-menu-item index="首页">
            <span slot="title">首页</span>
          </el-menu-item>
          <el-submenu index="展会介绍">
            <template slot="title">
              <span>展会介绍</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="展会概况">展会概况</el-menu-item>
              <el-menu-item index="亮点推荐">亮点推荐</el-menu-item>
              <el-menu-item index="组织架构">组织架构</el-menu-item>
              <el-menu-item index="关于我们">关于我们</el-menu-item>
              <el-menu-item index="下载中心">下载中心</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="展商服务">
            <template slot="title">
              <span>展商服务</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="展位预定" v-if="false">展位预定</el-menu-item>
              <el-menu-item index="展品范围">展品范围</el-menu-item>
              <el-menu-item index="参展流程">参展流程</el-menu-item>
              <el-menu-item index="特装搭建">特装搭建</el-menu-item>
              <el-menu-item index="展商登录">展商登录</el-menu-item>
              <el-menu-item index="展商交通指引">交通指引</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="观众服务">
            <template slot="title">
              <span>观众服务</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="论坛/观展报名">论坛/观展报名</el-menu-item>
              <el-menu-item index="参观指南">参观指南</el-menu-item>
              <el-menu-item index="同期活动">同期活动</el-menu-item>
              <el-menu-item index="观众交通指引">交通指引</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="媒体中心">
            <template slot="title">
              <span>媒体中心</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="展会直播">展会直播</el-menu-item>
              <el-menu-item index="合作媒体">合作媒体</el-menu-item>
              <el-menu-item index="资讯报道">资讯报道</el-menu-item>
              <el-menu-item index="媒体矩阵">媒体矩阵</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="精彩图集">
            <span slot="title">精彩图集</span>
          </el-menu-item>
          <el-menu-item index="线上展">
            <span slot="title">线上展</span>
          </el-menu-item>
          <el-menu-item index="联系我们">
            <span slot="title">联系我们</span>
          </el-menu-item>
      </el-menu>
      <!-- 20230609隐藏搜索功能 -->
        <!-- <div class="navSearch flex_fcc">
          <el-input v-model="searchValue" placeholder="请输入搜索内容" @focus="handleJump"></el-input>
          <el-button type="primary">搜索</el-button>
        </div> -->
    </div>
  </div>
</template>

<script>


  // import {
  //   dateFormat
  // } from "@/util/date";
  // import * as echarts from 'echarts'
  // import "echarts-extension-amap";

  export default {
    name: 'Login',
    components: {

    },
    data() {
      return {
        showExpand: false,
        currentNav: sessionStorage.getItem('currentNav')||'首页',
        searchValue: '',
        backPosition: 50,
        screenWidth: screen.width,
        isMobile: false,
      }
    },
    watch: {
      screenWidth(val) {
        if (val < 1300) {
          sessionStorage.setItem('isMobile', true)
          this.isMobile = true
        } else {
          sessionStorage.setItem('isMobile', false)
          this.isMobile = false
        }
        this.$root.$emit('upDateView')
      },
      // '$route.query.currentNav'(v) {
      //   console.log('首页的currentNav', v);
      //   // this.currentNav = v
      //   sessionStorage.setItem('currentNav',v)
      // },
      
    },
    beforeCreate() {
      var isWindow = false // 判断是否为移动端
      var system = navigator.userAgent.toLowerCase();
      console.log('当前的设备', system);
      //判断android ios windows
      var android = system.indexOf("android");
      var iphone = system.indexOf("iphone");
      var ipad = system.indexOf("ipad");
      var windows = system.indexOf("windows");
      var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
      if (android !== -1) {
        console.log("android")
      }
      if (iphone !== -1 || ipad !== -1 || isMac) {
        console.log("ios")
      }
      if (windows !== -1) {
        console.log("windows")
        isWindow = true
      }

      if (isWindow) { // 为pc端
        sessionStorage.setItem('isMobile', false)
        return
      }

      if (screen.width <= 1024) { 
        sessionStorage.setItem('isMobile', true)
      } else {
        sessionStorage.setItem('isMobile', false)
      }
      this.$root.$emit('upDateView')
    },
    mounted() {
      var that = this
      


      window.onresize = ()=>(()=>{
        // 响应式代码
        that.screenWidth = screen.width

      })()

    },
    methods: {
      clickHome() {
        this.showExpand = false
      },
      handleJump() {
        this.$router.push(`/searchResult?currentNav=${this.currentNav}`)
      },
      handleShowExpand(val) {
        this.showExpand = val
      },
      handleNavEvent(val) {
        var url= ''
        sessionStorage.setItem('currentNav', val)
        switch (val) {
          case '首页':
            url = '/home/banner'
            break;
          case '展会概况':
            url = '/home/situation'
            break;
          case '亮点推荐':
            url = '/home/highlight'
            break;
          case '组织架构':
            url = '/home/framework'
            break;
          case '关于我们':
            url = '/home/aboutus'
            break;
          case '下载中心':
            url = '/home/downloadCenter'
            break;
          case '展位预定':
            url = '/home/exhibitsSiteOrder'
            break;
          case '展品范围':
            url = '/home/exhibitionRange'
            break;
          case '参展流程':
            url = '/home/joinExhibitionPath'
            break;
          case '特装搭建':
            url = '/home/equipBuild'
            break;
          case '展商登录':
            // 跳转第三方
            window.open('http://gzgdzs.zbase.cn/login_zs.html?zhid=1')
            return
            break;
          case '展商交通指引':
            // url = '/home/trafficGuide'
            url = '/home/audienceTrafficGuide'
            break;            
          case '观众交通指引':
            url = '/home/audienceTrafficGuide'
            break;   
          case '论坛/观展报名':
            // 跳转第三方
            window.open('http://xt.zbase.cn/1326/home.html')
            return
            break;           
          case '参观指南':
            window.open('http://www.gbatransit.com/resource/参观指南-电子版.pdf', '_self')
            return
            url = '/home/visitGuide'
            break;         
          case '同期活动':
            url = '/home/sameActivity'
            break;         
          case '展会直播':
            // 跳转第三方
            window.open('https://wx.vzan.com/live/page/980809753?v=1687752770127', '_self')
            return                
            url = '/home/forumLive'
            break;         
          case '合作媒体':
            url = '/home/cooperateMedia'
            break;         
          case '资讯报道':
            url = '/home/consultReport'
            break;         
          case '媒体矩阵':
            url = '/home/mediamatrix'
            break;         
          case '精彩图集':
            url = '/home/wnderfulPic'
            break;         
          case '线上展':
            url = '/home/onlineShow'
            break;         
          case '联系我们':
            url = '/home/contactUs'
            break;         

          default:
            url = '/home'
            break;
        }
        this.$router.push(url)

      },
      handleClose() {

      },
      handleOpen(v) {
        // console.log(v);
      },
      handleSelect(v) {
        this.handleNavEvent(v)
        this.showExpand = false
      },
    }
  }
</script>

<style lang="scss" scoped>
.avue-view {
  // min-height: 100vh;
}
.home {
  position: relative;
  width: 100%;
  // height: 100vh;
  //   overflow-y: scroll;
    // &::-webkit-scrollbar {
    //     width: 0;
    // }
  // background-color: #fff;
}
.exhibitionIndex {
  // background-color: #fff;
  height: 100vh;
  // overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}
@media screen and (max-width: 1300px) { // 处于移动端

  .downList {
    position: absolute;
    top: 66px;
    z-index: 1000;
    width: calc(100% - 40px);
    padding: 0 20px;
    background: #FFFFFF;
    // display: none;
    ::v-deep .el-menu-item {
      border-bottom: 1px solid #D8D8D8;
      display: flex;
      height: 36px;
      line-height: 36px;
      justify-content: flex-start;
    }
    ::v-deep .el-submenu__title {
      display: flex;
      height: 36px;
      line-height: 36px;
      justify-content: flex-start;
      
    }
    ::v-deep .el-menu-item-group .is-opened .el-submenu__title{
      border-bottom: 1px solid #D8D8D8;
    }
    ::v-deep .el-submenu {
      border-bottom: 1px solid #D8D8D8;
    }
    ::v-deep .el-menu-item-group {
      border-top: 1px solid #D8D8D8;
    }
    ::v-deep .el-menu-item-group__title {
      display: none;
    }
    ul li {
      font-size: 14px;
      &:last-child {
        border-bottom: none !important;
      }
    }
    .el-menu {
      border-right: none;
    }
    .navSearch {
      height: 53px;
      border-top: 1px solid #D8D8D8;
      .el-input {
        // width: 221px;
      }
      ::v-deep .el-input__inner {
        // width: 221px;
        height: 32px;
        background: rgba(255,255,255,0.2);
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #D8D8D8;
      }
      .el-button {
        width: 87px;
        height: 32px;
        line-height: 10px;
        background: #65BC62;
        border-radius: 0px 4px 4px 0px;
        font-size: 18px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
      ::v-deep .el-button--primary {
        border: none !important;
      }
      .el-icon-s-fold {
        color: #fff;
        width: 24px;
        height: 24px;
        display: none;
      }
    }
  }  
  
}

</style>