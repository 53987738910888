<template>
    <div class="exhibitsSiteOrder">
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="6"></Header>
        <div class="orderForm">
            <el-form ref="orderForm" :model="orderForm" label-width="80px">
                <el-form-item label="公司名称" prop="companyName" :rules="[{ required: true, message: '请输入', trigger: 'blur' },]">
                    <el-input v-model="orderForm.companyName" placeholder="请输入公司名称"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="contactor" :rules="[{ required: true, message: '请输入', trigger: 'blur' },]">
                    <el-input v-model="orderForm.contactor" placeholder="请输入联系人"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone" :rules="[{ required: true, message: '请输入', trigger: 'blur' },]">
                    <el-input v-model="orderForm.phone" placeholder="请输入联系电话"></el-input>
                </el-form-item>
                <el-form-item label="所属领域">
                    <el-input v-model="orderForm.domain" placeholder="请输入所属领域"></el-input>
                </el-form-item>
                <el-form-item label="展位类型">
                    <el-select v-model="orderForm.stallType" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="展位面积">
                    <el-input v-model="orderForm.stallArea" placeholder="请输入展位面积"></el-input>
                </el-form-item>
                <el-form-item label="留言内容">
                    <el-input type="textarea" :rows="5" v-model="orderForm.message" placeholder="请输入留言内容"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-radio v-model="orderForm.advice" label="0">我要参展</el-radio>
                    <el-radio v-model="orderForm.advice" label="1">信息咨询</el-radio>
                    <el-radio v-model="orderForm.advice" label="2">其他</el-radio>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">提交申请</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import {
    exhibitionstallappointApply,
} from '@/api/exhibition'
export default {
    name: 'ExhibitionprojectExhibitsSiteOrder',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
            orderForm: {
                type: '',
                advice: '',
            },
            value: '1',
            options: [
                {
                    label: '标准展位（最小9平方米）',
                    value: '0'
                },
                {
                    label: '光地展位（最小36平方米）',
                    value: '1'
                },
            ],
        };
    },

    mounted() {
        
    },

    methods: {
        onSubmit() {
            this.$refs.orderForm.validate((valid)=>{
                if (valid) {
                    exhibitionstallappointApply(this.orderForm).then(res=>{
                        this.orderForm = {
                            type: '',
                            radio: '',
                        },
                        this.$refs.orderForm.resetFields()  
                        this.$message.success('提交成功！')

                    })
                }
            })


        },
    },
};
</script>

<style lang="scss" scoped>
.exhibitsSiteOrder {
    .orderForm {
        width: 950px;
        margin: 0 auto;
        margin-top: 32px;
        .el-select {
            width: 100%;
        }
        ::v-deep .el-radio__input.is-checked+.el-radio__label {
            color: #0941B8;
        }
        ::v-deep .el-radio__input.is-checked .el-radio__inner {
            border-color: #0941B8;
            background: #0941B8;
        }
        ::v-deep .el-button--primary {
            background-color: #0941B8;
            border-color: #0941B8;
        }
    }
}
@media screen and (max-width: 1300px) { // 非pc端
    .orderForm {
        width: 550px !important;
        .el-form {
            ::v-deep .el-form-item__label {
                width: 100px !important;
            }
            ::v-deep .el-form-item__content {
                margin-left: 100px !important;
            }
        }
    }
}
@media screen and (max-width: 650px) { // 非pc端
    .orderForm {
        width: 90% !important;
        .el-form {
            // ::v-deep .el-form-item__label {
            //     width: 100px !important;
            // }
            // ::v-deep .el-form-item__content {
            //     margin-left: 100px !important;
            // }
        }
    }
}
</style>