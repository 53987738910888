import { render, staticRenderFns } from "./onlineProductDetail.vue?vue&type=template&id=42a1e409&scoped=true&"
import script from "./onlineProductDetail.vue?vue&type=script&lang=js&"
export * from "./onlineProductDetail.vue?vue&type=script&lang=js&"
import style0 from "./onlineProductDetail.vue?vue&type=style&index=0&id=42a1e409&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42a1e409",
  null
  
)

export default component.exports