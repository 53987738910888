<template>
    <div class="sameActivity">
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="16"></Header>
        <div class="middle">
            <!-- <div v-html="html"></div> -->
            <!-- 以上为对接接口了 -->	            
            <h3>同期活动</h3>
            <h4>（1）产业发展论坛</h4>
            <h5>
                <strong>活动内容：</strong>以智慧化、信息化、绿色化为主线，组织举办 1 场主论坛、8 场分论坛、多场专题论坛和沙龙（详见《( 首届 ) 
                广州大湾区国际轨道交通产业发展论坛》）。主论坛以“绿智轨交 创新产业 迈向轨交高质量跨越式发展之路 ”为主题，
                同时举办包括自主化创新成果展示、装备制造发展、人才发展战略数智化创新、以 TOD 促进大湾区城市协同发展、信
                息化赋能智慧城轨创新、轨道交通安全管理等方向的分论坛，为我国轨道交通可持续发展提供创新思路。<br/>
                <strong>活动时间：</strong>6 月 29-30 日 09:00-20:00<br/>
                <strong>活动地点：</strong> 馆主会场、分会场 1-4、粤大金融城酒店<br/>
            </h5>
            <img :src="require('@/assets/images/activityDate.jpg')" alt="">


            <h4>（2）开幕仪式</h4>
            <h5>
                <strong>活动内容：</strong>邀请行业代表参加，省市领导及政府有关部门负责人出席并致辞，向业界展示推介广州轨道交通资源与成就。<br/>
                <strong>活动时间：</strong>6 月 29 日 09:00-9:10<br/>
                <strong>活动地点：</strong>10.1 馆主会场<br/>
            </h5>
            <img :src="require('@/assets/images/sameActivity1.png')" alt="">

            <h4>（3）组委会巡馆</h4>
            <h5>
                <strong>活动内容：</strong>组委会将分批次组织主办方领导、各地城轨业主 VIP 嘉宾、特邀嘉宾等对参展的优秀展位进行寻访交流。<br/>
                <strong>活动时间：</strong>6 月 29-30 日 09:00-18:00<br/>
                <strong>活动地点：</strong>户外展区、9.1 馆、10.1 馆<br/>
            </h5>
            <img :src="require('@/assets/images/excellentPics/1.jpg')" alt="">
            <img :src="require('@/assets/images/excellentPics/2.jpg')" alt="">
            <img :src="require('@/assets/images/excellentPics/3.jpg')" alt="">

            <h4>（4）新技术发布签约会</h4>
            <h5>
            <strong>活动内容：</strong>向企业、展商征集最新前沿技术的成果发布需求和交易签约需求，通过组委会严格的审核，获批项目在展期
            中重点展示亮相。<br/>
            <strong>活动时间：</strong>6 月 29-30 日 09:00-18:00<br/>
            <strong>活动地点：</strong>10.1 馆主会场、10.1 馆展位号 10T401 广州地铁、9.1 馆技术交流区、8 号会议室南厅分会场 3   <br/>             
            </h5>
            <img :src="require('@/assets/images/signPic1.png')" alt="">
            <img :src="require('@/assets/images/signPic2.png')" alt="">
            <img :src="require('@/assets/images/signPic3.png')" alt="">

            <h4>（5）专业参观团</h4>
            <h5>
                <strong>活动内容：</strong>面向各业主单位、院校及相关单位发出“组团参观征集令”，分批次由专人带团参观，提供了解新技术、新产
                品的一站式参观服务。<br/>
                <strong>活动时间：</strong>6 月 29 日 -7 月 1 日 09:00-17:00<br/>
                <strong>活动地点：</strong>户外展区、9.1 馆、10.1 馆<br/>
            </h5>
            <!-- <img :src="require('@/assets/images/excellentPics/4.jpg')" alt=""> -->
            <img :src="require('@/assets/images/excellentPics/5.jpg')" alt="">

            <h4>（6）院校研学团</h4>
            <h5>
                <strong>活动内容：</strong>组织院校轨交专业老师和学生观摩展会，参加城市轨道交通数字化与人才发展论坛，赴广州地铁博物馆参观
考察，与行业企业形成良性互动，学习行业创新引领成果。<br/>
                <strong>活动时间：</strong> 6 月 29 日 14:00-17:00、6 月 30 日 09:00-17:00、7 月 1 日 09:00-12:00<br/>
                <strong>活动地点：</strong> 户外展区、9.1 馆、10.1 馆<br/>
            </h5>
            <img :src="require('@/assets/images/excellentPics/4.jpg')" alt="">            
            <img :src="require('@/assets/images/excellentPics/19.jpg')" alt="">  
             
            <h4>（7）寻馆之旅</h4>
            <h5>
                <strong>活动内容：</strong>面向全体观众开放的趣味互动活动，寻找主题展位打卡，收集纪念印章并参与互动抽奖，赢取活动礼品。<br/>
                <strong>活动时间：</strong> 6 月 29 日 -7 月 1 日 09:00-17:00<br/>
                <strong>活动地点：</strong> 户外展区、9.1 馆、10.1 馆 / 活动大本营 B 区 19 会议室<br/>
            </h5>

            <h4>（8）“羊角粉”征集令</h4>
            <h5>
                <strong>活动内容：</strong>羊角君带队，组织羊角粉参加展会互动活动，科普城轨知识。<br/>
                <strong>活动时间：</strong> 7 月 1 日 09:00-12:00<br/>
                <strong>活动地点：</strong> 户外展区、9.1 馆、10.1 馆<br/>                
            </h5>
            <img :src="require('@/assets/images/excellentPics/11.jpg')" alt="">
            <img :src="require('@/assets/images/excellentPics/12.jpg')" alt="">
            <img :src="require('@/assets/images/excellentPics/13.jpg')" alt="">
            <img :src="require('@/assets/images/excellentPics/14.jpg')" alt="">   

            <h4>（9）小主播团</h4>
            <h5>
                <strong>活动内容：</strong><br/>
                1、特色小宣讲员：舞台展示模块，主要通过童声，宣传轨道交通科技发展、轨道交通安全、相关知识科普等。<br/>
                2、专属小记者团：采访模块，采访重点展商和主办方等相关领导。<br/>
                3、特色小导览员：研学实践模块，对各类轨道展品的介绍和解说，在部分互动环节，给予孩子们实践体验机会。<br/>
                <strong>活动时间：</strong> 7 月 1 日 09:00-12:00<br/>
                <strong>活动地点：</strong> 户外展区、9.1 馆、10.1 馆<br/>                    
            </h5>
            <img :src="require('@/assets/images/excellentPics/24.jpg')" alt="">            
            <img :src="require('@/assets/images/excellentPics/25.jpg')" alt="">    

            <h4>（10）玩转直播间</h4>
            <h5>
                <strong>活动内容：</strong>展会设置采访直播中心，对展会进行全方位采访报道与实时全网直播，对行业内领军人物及重量级嘉宾进行
人物专访，带领观众线上巡馆、直播论坛、直播带货等。<br/>
                <strong>活动时间：</strong>6 月 29 日 -7 月 1 日 09:00-17:00<br/>
                <strong>活动地点：</strong> 采访直播中心<br/>                   
            </h5>
            <img :src="require('@/assets/images/excellentPics/10.jpg')" alt="">
            <img :src="require('@/assets/images/excellentPics/10-2.png')" alt="">

            <h4>（11）最美展位评选</h4>
            <h5>
                <strong>活动内容：</strong>组织“最美展位评选”，推出线上投票活动，展示优秀展商，为展位引流。<br/>
                <strong>活动时间：</strong>6 月 29 日 00:00-7 月 1 日 23:59<br/>
                <strong>活动地点：</strong> 线上<br/>                  
            </h5>
            <img :src="require('@/assets/images/excellentPics/22.jpg')" alt="">              

            <h4>（12）寻找幸运锦鲤</h4>
            <h5>
                <strong>活动内容：</strong>引导展商及观众转发活动信息并集赞，引流关注展会信息。抽取锦鲤，赢取大礼包。<br/>
                <strong>活动时间：</strong>6 月 28 日 00:00-7 月 1 日 23:59<br/>
                <strong>活动地点：</strong> 线上<br/>                  
            </h5>
            <img :src="require('@/assets/images/excellentPics/23.jpg')" alt="">    




            <!-- <h4>（4）轨道技术(产品)首发活动暨推介会</h4>
            <h5>为拓宽企业多元合作渠道，展会将搭建轨道交通行业资源、产品与资本的对接合作平台，邀请行业相关政府部门、龙头企业共同参与，加强产业链企业资源的互联互通,推动产业链企业聚合形成集聚效应,加速广州轨道交通产业提质增效。</h5>            
            <img :src="require('@/assets/images/excellentPics/6.jpg')" alt="">
            <h4>（5）轨道交通专业人才对接会</h4>
            <h5>作为轨道交通专业人才培养体系建设分论坛配套活动，活动将为校企搭建人才交流平台，邀请广州市内城市轨道专业院校、行业重点企业参与，现场举行企业宣讲会、校企签约仪式、人才供需见面会等活动，助推轨道交通产业快速发展、转型升级，助力将广州打造成为人才汇聚首选地和最佳发展地。</h5>            
            <img :src="require('@/assets/images/excellentPics/8.jpg')" alt="">
            <h4>（6）大湾区轨道交通成果参观考察</h4>
            <h5>组织重要展商和观众对广州轨道交通产业发展情况进行参观考察，深入了解广州轨交行业建设成果，加深广州与其他城市的交流合作。</h5>
            <img :src="require('@/assets/images/excellentPics/9.jpg')" alt="">

            <h4>（9）高新技术论坛</h4>
            <h5>以智慧化、信息化、绿色化为主线，组织举办1场主论坛、8场分论坛。主论坛以“绿智轨交创新产业 迈向轨交高质量跨越式发展之路 ”为主题，同时举办包括自主化创新成果展示、轨道交通供应链管理发展、装备制造发展、人才发展战略数智化创新、以TOD促进大湾区城市协同发展、信息化赋能智慧城轨创新、轨道交通安全管理等方向的分论坛，为我国轨道交通可持续发展提供创新思路。</h5>
            <img :src="require('@/assets/images/excellentPics/15.jpg')" alt="">
            <img :src="require('@/assets/images/excellentPics/16.jpg')" alt="">
            <h4>（10）供需交流会：轨道交通物资采购专区</h4>
            <h5>现场设置轨道交通物资采购专区，桥接供需双方，促进产品交流，促成市场交易。</h5>
            <img :src="require('@/assets/images/excellentPics/17.jpg')" alt="">

         
            <h4>（13）巡馆之旅</h4>
            <h5>面向全体观众开放的趣味互动活动，寻找主题展馆打卡，收集纪念徽章和纪念品。</h5>
            <img :src="require('@/assets/images/excellentPics/20.jpg')" alt="">            
            <img :src="require('@/assets/images/excellentPics/21.jpg')" alt="">   
          
         -->
          
        </div>
        

        <!-- <img :src="require('@/assets/images/sameActivity.png')" alt=""> -->
    </div>
</template>

<script>
    import {
        exhibitiondisplaycommonGetList,
    } from '@/api/exhibition'
export default {
    name: 'ExhibitionprojectSameActivity',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
            html: '',
        };
    },

    mounted() {
        // this.exhibitiondisplaycommonGetList()
    },

    methods: {
            exhibitiondisplaycommonGetList() {
                var param2 = {
                    firstDirName: '观众服务',
                    secondDirName: '同期活动',
                }

                exhibitiondisplaycommonGetList(param2).then(res=>{
					var data = res.data.data
					var html = data[0].plateBody
					this.html = html

                })                
            },          
    },
};
</script>

<style lang="scss" scoped>
.sameActivity {
    img {
        width: 100%;
    }
    .middle {
        max-width: 850px;
        margin: 0 auto;
        padding: 0 20px;
        img {
            width: 600px;
        }
    }
    h3 {
        height: 48px;
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;       
        margin-bottom: 48px;
        margin-top: 48px;
    }
    h4 {
        height: 30px;
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #000000;
        line-height: 30px;
        margin-top: 48px;
    }
    h5 {
        // height: 60px;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        text-align: justify;
        margin: 12px 0;
    }
}

@media screen and (max-width: 1300px) { // 非pc端
    .middle {

        img {
            width: 100% !important;
        }
    }

}
</style>