const TokenKey = 'saber-access-token'
const RefreshTokenKey = 'saber-refresh-token'
export function getToken() {
    return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
    return sessionStorage.setItem(TokenKey, token)
}

export function getRefreshToken() {
  return sessionStorage.getItem(RefreshTokenKey)
}

export function setRefreshToken(token) {
  return sessionStorage.setItem(RefreshTokenKey, token)
}

export function removeToken() {
    return sessionStorage.removeItem(TokenKey)
}

export function removeRefreshToken() {
  return sessionStorage.removeItem(RefreshTokenKey)
}

