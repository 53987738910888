<!--
 * @Author: huanggs huanggx@gzyct.com
 * @Date: 2023-05-15 10:51:24
 * @LastEditors: huanggs huanggx@gzyct.com
 * @LastEditTime: 2023-06-21 15:21:57
 * @FilePath: \exhibitionProject\src\views\mediaCenter\consultReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="Consultreport">
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="10"></Header>
        <template v-if="!isDetail">
            <h3>资讯报道</h3>
            <!-- <div class="reportList">
                <div class="rlcell flex_fsbc" v-for="(item, i) in reportList" :key="i">
                    <div class="rlcleft">
                        <h4 @click="handleDetail(item.bodyContent, item.bodyType)">{{item.title}}</h4>
                        <div class="content">
                            {{item.preContent}}
                        </div>
                        <div class="time">{{item.createTime}}</div>
                    </div>
                    <img class="rlcright" :src="item.imageurl||require('@/assets/images/banner.png')" />
                </div>
            </div>
            <SelfPage @handleCurrentChange="handleCurrentChange" :total="total" :size="size"></SelfPage> -->
            <!-- 以上为对接接口了 -->
            <div class="reportList">
                <div class="rlcell flex_fsbc" v-for="(item, i) in reportList[currentPage-1]" :key="i">
                    <div class="rlcleft">
                        <h4 @click="handleDetail(item.url, 0)">{{item.title}}</h4>
                        <div class="content">
                            {{item.content}}
                        </div>
                        <div class="time">{{item.time}}</div>
                    </div>
                    <img class="rlcright" :src="item.image||require('@/assets/images/banner.png')" />
                </div>
            </div>
            <SelfPage @handleCurrentChange="handleCurrentChange" :total="total" :size="size"></SelfPage>
        </template>
        <template v-else>
            <div class="body">
                <el-button icon="el-icon-back" type="text" @click="isDetail=false">返回</el-button>
                <div v-html="html"></div>
            </div>
            <!-- 以上为对接接口 -->
            <h3 class="detailH3">标题文字标题文字标题文字标题文字标题文字标题文字标题文字</h3>
            <div class="detailTime">时间：2023-02-08</div>
            <div class="detailContent">
                <div class="text">
                    正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容
                </div>
                <img :src="require('@/assets/images/logo.png')" alt="">
                <img :src="require('@/assets/images/logo.png')" alt="">
                <div class="text">正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容正文内容</div>
            </div>
        </template>

    </div>
</template>

<script>
import {
    exhibitiondisplaynewsList,
} from '@/api/exhibition'
import http_mock from '@/api/mockRequest.js'
export default {
    name: 'ExhibitionprojectConsultreport',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
            isDetail: false,
            currentPage: 1,
            size: 10,
            total: 0,
            reportList: [],
            html: '',
        };
    },

    mounted() {
        this.getMockData()
        // this.exhibitiondisplaynewsList()
    },

    methods: {
        exhibitiondisplaynewsList() {
            var param = {
                current: this.currentPage,
                size: this.size,
            }
            exhibitiondisplaynewsList(param).then(res=>{
                var data = res.data.data
                var arr = []
                data.records.map(r=>{
                    r.imageurl = JSON.parse(r.indexImageUrl)[0].response.data.link
                    if (r.status==0) {
                        arr.push(r)
                    }
                })
                this.reportList = arr
                this.total = arr.length
            })
        },         
        getMockData() {
            http_mock('getReport/list').then(response => {
                var data = response._data.data
                var arr = []
                var secIndex = 0
                arr[secIndex] = []
                data.map((r, i)=>{ // 分为一页十条数据
                    if ((i+1) % 10 == 0) { // 余10
                        arr[secIndex].push(r)
                        secIndex += 1 // 二维数组下标
                        arr[secIndex] = []
                    } else {
                        arr[secIndex].push(r)
                    }
                })
                arr[arr.length - 1].length == 0 ? arr.splice(arr.length - 1, 1) : '' // 去除最后一个为空的数组
                this.total = data.length
                this.reportList = arr
            })
        },
        handleDetail(url, type) {
            if (type=='0') { // 第三方链接
                window.open(url)
            } else {
                this.isDetail = true
                this.html = url
            }
        },
        handleCurrentChange(val) {
            this.currentPage = val
            // this.exhibitiondisplaynewsList()
            this.$root.$emit('toTop')
        },
    },
};
</script>

<style lang="scss" scoped>
    .body {
        max-width: 850px;  
        margin: 0 auto;  
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
    .Consultreport {
        .detailBtn {
            width: 100%;
            display: flex;
            justify-content: flex-start;
        }
        .reportList {
            .rlcell {
                width: 950px;
                // height: 110px;
                margin: 0 auto;
                padding: 20px 0;
                &:nth-child(1) {
                    border-top: 1px solid #D8D8D8;
                }
                border-bottom: 1px solid #D8D8D8;
            }
            .rlcleft {
                width: 75%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                h4 {
                    cursor: pointer;
                    // height: 32px;
                    font-size: 24px;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: 700;
                    color: #000000;
                    line-height: 32px;
                    text-align: left;

                    // /* Firefox */
                    // display:-moz-box;
                    // -moz-box-orient:vertical;
                    // -moz-line-clamp: 1;
                    // overflow: hidden;

                    // /* Safari, Opera, and Chrome */
                    // display:-webkit-box;
                    // -webkit-box-orient: vertical;
                    // -webkit-line-clamp: 1;
                    // overflow: hidden;

                    // /* W3C */
                    // display:box;
                    // box-orient:vertical;
                    // line-clamp: 1;
                    // overflow: hidden;
                }
                .content {
                    margin: 18px 0 8px 0;
                    height: 40px;
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #727272;
                    line-height: 20px;
                    text-align: left;

                    /* Firefox */
                    display:-moz-box;
                    -moz-box-orient:vertical;
                    -moz-line-clamp: 2;
                    overflow: hidden;

                    /* Safari, Opera, and Chrome */
                    display:-webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;

                    /* W3C */
                    display:box;
                    box-orient:vertical;
                    line-clamp: 2;
                    overflow: hidden;
                }
                .time {
                    text-align: left;
                    height: 20px;
                    font-size: 12px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #0C266D;
                    line-height: 20px;
                }
            }
            .rlcright {
                width: 192px;
                height: 129px;
            }
        }
    }
    h3 {
        height: 48px;
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;       
        padding-bottom: 32px;
        margin-top: 48px;
    }
    .detailH3 {
        padding-bottom: 24px;
        margin-top: 48px;
    }
    .detailTime {
        height: 20px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #727272;
        line-height: 20px;
        margin-bottom: 20px;
    }
    .detailContent {
        width: 950px;
        margin: 0 auto;
        padding-top: 20px;
        margin-bottom: 48px;
        border-top: 1px solid #0B43BC;
        .text {
            // height: 120px;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            line-height: 30px;
            text-align: left;
        }
        img {
            width: 850px;
            height: 638px;
        }
    }
    @media screen and (max-width: 1300px) { // 非pc端
        .rlcell {
            width: 550px !important;
                h4 {
                    // font-size: 16px !important;
                }
                .content { 
                    // font-size: 10px !important;
                }
        }
        .detailContent {
            width: 550px !important;
            img {
                width: 425px !important;
                height: 320px !important;
            }
        }
    }
    @media screen and (max-width: 650px) { // 非pc端
        .rlcell {
            width: 351px !important;
            border-top: none !important;
            // height: 108px;
            background: #FFFFFF;
            padding: 9px 12px !important;
            border-radius: 4px 4px 4px 4px;
            margin-bottom: 12px !important;
                h4 {
                    font-size: 16px !important;
                    line-height: 22px !important;
                }
                .rlcleft {
                    .content { 
                        height: 16px !important;
                        font-size: 12px !important;
                        /* Firefox */
                        -moz-line-clamp: 1 !important;

                        /* Safari, Opera, and Chrome */
                        -webkit-line-clamp: 1!important;

                        /* W3C */
                        line-clamp: 1!important;

                        margin: 7px 0 0 0 !important;
                    }
                }
                
                .rlcright {
                    width: 110px !important;
                    height: 84px !important;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 12px;
                }                
        }
        .detailContent {
            width: 550px !important;
            img {
                width: 425px !important;
                height: 320px !important;
            }
        }
    }
</style>