<template>
    <div class="searchresult">
        <div class="top">
            <div class="back flex_fsc" @click="toBack">
                <img :src="require('@/assets/images/arrow-left-s-line@2x.png')" alt="">
                <span>返回首页</span>
            </div>
            <div class="navSearch flex_fcc">
                <el-input v-model="searchValue" placeholder="请输入" clearable></el-input>
                <el-button type="primary">搜索</el-button>
            </div>   
        </div>
        <h3>搜索结果</h3>         
        <divl class="result">
            <div class="rleft">
                <span>按模块搜索：</span>
                <span>资讯报道(3)</span>
                <span>展品范围(0)</span>
            </div>
            <div class="rright">共找到3条结果</div>
        </divl>
        <div class="resultList" v-for="i in 3" :key="i">
            <div class="rleft">
                展会2023xxxx
            </div>
            <div class="rright flex_fcc" >
                <img :src="require('@/assets/images/arrow-left-s-line 1@2x.png')" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExhibitionprojectSearchresult',

    data() {
        return {
            currentNav: this.$route.query.currentNav,
            searchValue: '',
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
        };
    },

    mounted() {
    },

    methods: {
        toBack() {
            this.$router.push(`/home/banner`)
            sessionStorage.setItem('currentNav', '首页')
        },
    },
};
</script>

<style lang="scss" scoped>
.searchresult {
    h3 {
        height: 48px;
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;       
        padding-bottom: 32px;
        margin-top: 48px;
    }
    .result, .resultList {
        width: 950px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #D8D8D8;
        .rleft, .rright {
            height: 42px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #727272;
            line-height: 42px;
            span {
                cursor: pointer;
                &:hover {
                    color: #000000 ;
                }
                margin-right: 20px;
            }
            img {
                width: 24px;
                height: 24px;
            }
        }
    }
    .resultList {
        cursor: pointer;
        padding: 8px 0;
        &:hover {
            background-color: aliceblue;
        }cursor: pointer;
        &:hover {
            background-color: aliceblue;
        }
        .rleft {
            height: 32px;
            font-size: 18px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #000000;
            line-height: 32px;
        }
    }
}
.top {
    // width: 1440px;
    height: 160px;
    background: #0A2162;
    padding: 21px 25px 0;
    .back {
        cursor: pointer;
        img {
            width: 24px;
            height: 24px;
        }
        span {
            height: 20px;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
        }
    }
    .navSearch {
      width: 600px;
      height: 53px;
      margin: 0 auto;
      .el-input {
        width: 600px;
      }
      ::v-deep .el-input__inner {
        width: 600px;
        height: 32px;
        color: #fff !important;
        background: rgba(255,255,255,0.2);
        border-radius: 4px 0px 0px 4px;
        border: 0px solid rgba(0,0,0,0.14);
      }
      .el-button {
        width: 87px;
        height: 32px;
        line-height: 10px;
        background: #65BC62;
        border-radius: 0px 4px 4px 0px;
        font-size: 18px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
      ::v-deep .el-button--primary {
        border: none !important;
      }
      .el-icon-s-fold {
        color: #fff;
        width: 24px;
        height: 24px;
        display: none;
      }
    }    
}
@media screen and (max-width: 1300px) { // 非pc端
    .navSearch {
        width: 280px !important;
        ::v-deep .el-input__inner {
            width: 280px !important;
        }
        .el-button {
            font-size: 15px !important;
        }
    }
    .result, .resultList {
        padding: 0 10px !important;
        width: calc(100% - 20px) !important;
        .rleft, .rright {
            font-size: 12px !important;
            span {
                margin-right: 8px !important;
            }
        }
    }
}
</style>