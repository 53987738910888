<template>
	<div class="highlight">
		<Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="2"></Header>
		<div class="main">
			<template v-if="isMobile">
				<div class="m_content1">
					<div class="title">亮点推荐</div>
                    <!-- <div v-html="html"></div> -->
                    <!-- 以上为对接接口了 -->					
					<div class="content-block1">
						<img class="content-image" :src="require('@/assets/images/20230526.png')" alt="">
						<div class="light-place">
							<div class="light-point">
								<div class="light-number">
									<div class="light-count">20,000</div>
									<div class="count-text">㎡</div>
								</div>
								<div class="light-name">展示空间</div>
							</div>
							<div class="light-point">
								<div class="light-number">
									<div class="light-count">10</div>
									<div class="count-text">+</div>
								</div>
								<div class="light-name">主题展区</div>
							</div>
							<div class="light-point">
								<div class="light-number">
									<div class="count-text fs18px">近</div>
									<div class="light-count">300</div>
								</div>
								<div class="light-name">展商参展</div>
							</div>
							<div class="light-point">
								<div class="light-number">
									<div class="light-count">20,000</div>
									<div class="count-text">+</div>
								</div>
								<div class="light-name">专业观众</div>
							</div>
							<div class="light-point">
								<div class="light-number">
									<div class="light-count">5</div>
									<div class="count-text"></div>
								</div>
								<div class="light-name">列城轨实车</div>
							</div>
						</div>
						<div class="content-bottom"></div>
					</div>
					<div class="content-block2">
						<img class="content-image" :src="require('@/assets/images/202305261044.png')" alt="">
						<div class="region-block">
							<div class="regions">
								<div class="region-cover">
									<div class="region">
										<div class="region-number">
											<div class="region-count">10</div>
											<div class="count-text">+</div>
										</div>
										<div class="region-name">论坛会议</div>
									</div>
								</div>
								<div class="region-cover">
									<div class="region before-line">
										<div class="region-number">
											<div class="region-count">300</div>
											<div class="count-text">+</div>
										</div>
										<div class="region-name">行业大咖</div>
									</div>
								</div>
								<div class="region-cover">
									<div class="region">
										<div class="region-number">
											<div class="region-count">6000</div>
											<div class="count-text">+</div>
										</div>
										<div class="region-name">参会代表</div>
									</div>
								</div>
								<div class="region-cover">
									<div class="region before-line">
										<div class="region-number">
											<div class="region-count">10</div>
											<div class="count-text">+</div>
										</div>
										<div class="region-name">配套活动</div>
									</div>
								</div>
								<div class="region-cover">
									<div class="region before-line">
										<div class="region-number">
											<div class="region-count">50万</div>
											<div class="count-text">+</div>
										</div>
										<div class="region-name">官方媒体平台矩阵覆盖行业受众</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="content1">
					<div class="title">亮点推荐</div>
                    <!-- <div v-html="html"></div> -->
                    <!-- 以上为对接接口了 -->					
					<div class="content-block1">
						<div class="light-place">
							<div class="light-point">
								<div class="light-number">
									<div class="light-count">20,000</div>
									<div class="count-text">㎡</div>
								</div>
								<div class="light-name">展示空间</div>
							</div>
							<div class="light-point">
								<div class="light-number">
									<div class="light-count">10</div>
									<div class="count-text">+</div>
								</div>
								<div class="light-name">主题展区</div>
							</div>
							<div class="light-point">
								<div class="light-number">
									<div class="count-text fs18px">近</div>
									<div class="light-count">300</div>
								</div>
								<div class="light-name">展商参展</div>
							</div>
							<div class="light-point">
								<div class="light-number">
									<div class="light-count">20,000</div>
									<div class="count-text">+</div>
								</div>
								<div class="light-name">专业观众</div>
							</div>
							<div class="light-point">
								<div class="light-number">
									<div class="light-count">5</div>
									<div class="count-text"></div>
								</div>
								<div class="light-name">列城轨实车</div>
							</div>
						</div>
						<img class="content-image" :src="require('@/assets/images/20230526.png')" alt="">
						<div class="content-bottom"></div>
					</div>
					<div class="content-block2">
						<div></div>
						<div class="region-block">
							<div class="regions">
								<div class="region-cover">
									<div class="region">
										<div class="region-number">
											<div class="region-count">10</div>
											<div class="count-text">+</div>
										</div>
										<div class="region-name">论坛会议</div>
									</div>
								</div>
								<div class="region-cover">
									<div class="region before-line">
										<div class="region-number">
											<div class="region-count">300</div>
											<div class="count-text">+</div>
										</div>
										<div class="region-name">行业大咖</div>
									</div>
								</div>
								<div class="region-cover">
									<div class="region">
										<div class="region-number">
											<div class="region-count">6000</div>
											<div class="count-text">+</div>
										</div>
										<div class="region-name">参会代表</div>
									</div>
								</div>
								<div class="region-cover">
									<div class="region before-line">
										<div class="region-number">
											<div class="region-count">10</div>
											<div class="count-text">+</div>
										</div>
										<div class="region-name">配套活动</div>
									</div>
								</div>
								<div class="region-cover">
									<div class="region before-line">
										<div class="region-number">
											<div class="region-count">50万</div>
											<div class="count-text">+</div>
										</div>
										<div class="region-name">官方媒体平台矩阵覆盖行业受众</div>
									</div>
								</div>
							</div>
						</div>
						<img class="content-image" :src="require('@/assets/images/202305261044.png')" alt="">
					</div>
				</div>
			</template>

		</div>
	</div>
</template>

<script>
import {
    exhibitiondisplaycommonGetList,
} from '@/api/exhibition'
export default {
	name: 'Highlight',
	data() {
		return {
			headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
			isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
			html: '',
		}
	},
    mounted() {
        var that = this
        this.$root.$on('upDateView', ()=>{
            that.isMobile = JSON.parse(sessionStorage.getItem('isMobile'))
        })
		// this.exhibitiondisplaycommonGetList()
    },	
	methods: {
        exhibitiondisplaycommonGetList() {
            var param = {
                firstDirName: '展会介绍',
                secondDirName: '亮点推荐',
            }
            exhibitiondisplaycommonGetList(param).then(res=>{
                var data = res.data.data
                var html = data[0].plateBody
                this.html = html

            })          
        },   		
	},
}
</script>

<style lang='less' scoped>
.highlight {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #EEE;
	.fs18px {
		font-size: 18px !important;
	}
	.main {
		background-color: #FFF;

		.content1 {
			position: relative;
			width: 1440px;
			margin: 0 auto;
			.title {
				height: 48px;
				font-size: 26px;
				font-family: Source Han Sans CN-Bold, Source Han Sans CN;
				font-weight: 700;
				color: #0C266D;
				line-height: 48px;       
				margin: 48px 0;
			}

			.content-block1 {
				position: relative;
				display: flex;
				justify-content: space-between;
				width: 100%;
				min-height: 545px;
				padding: 0 120px;
				box-sizing: border-box;

				.light-place {
					width: 600px;
					// padding: 40px 50px 32px 184px;
					box-sizing: border-box;
					z-index: 49;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-content: space-evenly;					

					.light-point {
						width: 30%;
    					height: 20%;
						// margin: 100px 0;
						text-align: center;

						.light-number {
							display: flex;
							justify-content: center;
							align-items: baseline;
							.light-count {
								font-size: 44px;
								font-family: Source Han Sans CN-Bold, Source Han Sans CN;
								font-weight: 700;
								color: #0B43BC;
								line-height: 48px;
							}
							.count-text {
								margin-left: 4px;
								font-size: 26px;
								font-family: Source Han Sans CN-Bold, Source Han Sans CN;
								color: #3D3D3D;
								line-height: 33px;
							}
						}
						.light-name {
							margin-top: 16px;
							font-size: 24px;
							font-family: Source Han Sans CN-Bold, Source Han Sans CN;
							color: #000;
							line-height: 24px;
						}
					}
				}

				.content-image {
					width: 600px;
					height: 450px;
					background-color: silver;
					z-index: 49;
				}
				.content-bottom {
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 379px;
					background-color: #F7F7F7;
					z-index: 1;
				}
			}

			.content-block2 {
				position: relative;
				display: flex;
				justify-content: space-between;
				width: 100%;
				min-height: 558px;
				padding-top: 60px;
				margin: 80px 0 60px 0;
				box-sizing: border-box;

				.region-block {
					width: 948px;
					min-height: 498px;
					padding: 0 35px  0 253px;
					box-sizing: border-box;
					background-color: #F7F7F7;

					.regions {
						display: flex;
						justify-content: center;
						flex-wrap: wrap;
						width: 100%;
						padding: 67px 0;

						.region-cover {
							width: 50%;
							padding: 46px 0;

							.region {
								position: relative;
								width: 100%;
								padding: 0 10px 0 100px;
								box-sizing: border-box;
								text-align: left;
								overflow: hidden;
								
								.region-number {
									display: flex;
									align-items: flex-end;
									.region-count {
										font-size: 44px;
										font-family: Source Han Sans CN-Bold, Source Han Sans CN;
										font-weight: 700;
										color: #0B43BC;
										line-height: 48px;
									}
									.count-text {
										margin-left: 4px;
										margin-bottom: 7px;
										font-size: 18px;
										font-family: Source Han Sans CN-Bold, Source Han Sans CN;
										color: #3D3D3D;
										line-height: 26px;
									}
									
								}
								.region-name {
									margin-top: 12px;
									font-size: 24px;
									font-family: Source Han Sans CN-Bold, Source Han Sans CN;
									color: #000;
									line-height: 24px;
								}
							}
							.before-line {
								&::before {
									position: absolute;
									top: 0;
									left: 0;
									content: '';
									width: 1px;
									height: 100%;
									background-color: #D8D8D8;
								}
							}
							&:last-child {
								width: 338px;
								.region {
									padding: 0 ;
									.region-number {
										justify-content: center;
									}
								}
								.before-line {
									&::before {
										display: none;
									}
								}
							}
						}
					}
				}
				
				.content-image {
					position: absolute;
					top: 0;
					left: 120px;
					width: 600px;
					height: 450px;
					background-color: silver;
					z-index: 49;
				}
			}
		}
		.m_content1 {
			.title {
				height: 48px;
				font-size: 26px;
				font-family: Source Han Sans CN-Bold, Source Han Sans CN;
				font-weight: 700;
				color: #0C266D;
				line-height: 48px;       
				margin: 28px 0;
			}			
			.light-place {
				// max-width: 600px;
				// padding: 40px 50px 32px 184px;
				box-sizing: border-box;
				z-index: 49;

				.light-point {
					width: 100%;
					margin: 50px 0;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					.light-number {
						display: flex;
						align-items: baseline;
						.light-count {
							font-size: 44px;
							font-family: Source Han Sans CN-Bold, Source Han Sans CN;
							font-weight: 700;
							color: #0B43BC;
							line-height: 48px;
						}
						.count-text {
							margin-left: 4px;
							font-size: 26px;
							font-family: Source Han Sans CN-Bold, Source Han Sans CN;
							color: #3D3D3D;
							line-height: 33px;
						}
					}
					.light-name {
						margin-top: 16px;
						font-size: 24px;
						font-family: Source Han Sans CN-Bold, Source Han Sans CN;
						color: #000;
						line-height: 24px;
					}
				}
			}	
			.region-block {
				// width: 948px;
				min-height: 498px;
				// padding: 0 35px  0 253px;
				box-sizing: border-box;
				background-color: #F7F7F7;

				.regions {
					display: flex;
					justify-content: center;
					flex-wrap: wrap;
					width: 90%;
					margin: 0 auto;
					// padding: 67px 0;

					.region-cover {
						width: 50%;
						padding: 46px 0;

						.region {
							position: relative;
							width: 100%;
							padding: 0 10px 0 10px;
							box-sizing: border-box;
							text-align: left;
							overflow: hidden;
							
							.region-number {
								display: flex;
								align-items: flex-end;
								.region-count {
									font-size: 44px;
									font-family: Source Han Sans CN-Bold, Source Han Sans CN;
									font-weight: 700;
									color: #0B43BC;
									line-height: 48px;
								}
								.count-text {
									margin-left: 4px;
									margin-bottom: 7px;
									font-size: 18px;
									font-family: Source Han Sans CN-Bold, Source Han Sans CN;
									color: #3D3D3D;
									line-height: 26px;
								}
							}
							.region-name {
								margin-top: 12px;
								font-size: 24px;
								font-family: Source Han Sans CN-Bold, Source Han Sans CN;
								color: #000;
								line-height: 24px;
							}
						}
						.before-line {
							&::before {
								position: absolute;
								top: 0;
								left: 0;
								content: '';
								width: 1px;
								height: 100%;
								background-color: #D8D8D8;
							}
						}
						&:last-child {
							width: 338px;
							.region {
								padding: 0 ;
								.region-number {
									justify-content: center;
								}
							}
							.before-line {
								&::before {
									display: none;
								}
							}
						}
					}
				}
			}				
		}
	}
}
    @media screen and (max-width: 1300px) { // 非pc端
        .content1 {
            width: 100% !important;
			.content-block1 {
				padding: 0 30px !important;
				.light-place { 
					// max-width: 300px !important;
					padding: 20px 25px 16px 53px !important;
					.light-point {
						// margin: 50px 25px 100px 25px!important;
						margin: 5% 5% 6% 8%!important;
					}
				}
				.content-image { 

				}
				.content-bottom { 
					bottom: 60px !important;
				}
			}

			.content-block2 {
				.region-block {
					background-color: #fff !important;
					// padding: 0 0 0 125px !important;
				}
				.regions {
					padding: 67px 20px !important;
					background-color: #F7F7F7 !important;
					.region-cover {
							padding: 23px 0 !important;
					}
				}
				.content-image {
					left: 30px !important;
				}
			}

        }
		.content-image {
			// width: 90% !important;
			height: 225px !important;
		}
    }
	@media screen and (max-width: 750px) {
			.content-block1 {
				padding: 0 30px !important;
				.light-place { 
					// max-width: 300px !important;
				}
			}
	}
</style>
