<!--
 * @Author: huanggs huanggx@gzyct.com
 * @Date: 2023-04-27 17:55:58
 * @LastEditors: huanggs huanggx@gzyct.com
 * @LastEditTime: 2023-06-08 14:42:57
 * @FilePath: \exhibitionProject\src\components\header.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div v-if="headImg!=''" class="header" :style="{'background-image': 'url('+ headImg+')'}">
        <div class="headLeft flex_fsc">
            <span></span>
            <div class="flex_column_cc text">
                <span>{{titleName?titleName.chinese:title[titleIndex].chinese}}</span>
                <span>{{titleName?titleName.english:title[titleIndex].english}}</span>
            </div>
        </div>
		<div class="qrImg" v-if="!isMobile">
			<img :src="require('@/assets/images/qrImg.png')" alt="">
		</div>
    </div>
</template>

<script>
const title = [
	{
		chinese: "下载中心",
		english: "Download Center",
	},
	{
		chinese: "展会概况",
		english: "Overview of the exhibition",
	},
	{
		chinese: "亮点推荐",
		english: "Highlight recommendation",
	},
	{
		chinese: "组织架构",
		english: "Organizational structure",
	},
	{
		chinese: "关于我们",
		english: "About us",
	},
	{
		chinese: "特装搭建",
		english: "Custom-built construction",
	},
	{
		chinese: "展位预定",
		english: "exhibits sites' order",
	},
	{
		chinese: "展品范围",
		english: "Range of exhibits",
	},
	{
		chinese: "参展流程",
		english: "Exhibiting process",
	},
	{
		chinese: "交通指引",
		english: "Transportation directions",
	},
	{
		chinese: "资讯报道",
		english: "Information reports",
	},
	{
		chinese: "合作媒体",
		english: "Partner media",
	},
	{
		chinese: "展会直播",
		english: "Forum live",
	},
	{
		chinese: "联系我们",
		english: "Contact us",
	},
	{
		chinese: "线上展",
		english: "Online exhibition",
	},
	{
		chinese: "精彩图集",
		english: "Photo gallery",
	},
	{
		chinese: "同期活动",
		english: "Concurrent events",
	},
	{
		chinese: "媒体矩阵",
		english: "Media matrix",
	},
	{
		chinese: "参观指南",
		english: "Visit Guide",
	},
	{
		chinese: "展商详情",
		english: "Exhibitor details",
	},
	{
		chinese: "展品详情",
		english: "Details of the exhibits",
	},
]
export default {
    name: 'ExhibitionprojectHeader',
    props: {
        headImg: {
            type: String,
            default: ''
        },
        titleIndex: {
            type: Number,
			default: 0,
        },
		titleName: {
			type: Object,
			default: null
		},
    },
    data() {
        return {
			title,
			isMobile: JSON.parse(sessionStorage.getItem('isMobile')) || false,
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.header {
    margin-top: 40px;
    height: 185px;
    // background-image: url('../assets/images/header.png');
    background-size: 100% 100%;
    padding-left: 240px;
    padding-top: 58px;
	position: relative;
	.qrImg {
		position: absolute;
		right: 80px;
		bottom: 6%;

		img {
			width: 100px;
			height: 100px;
		}
	}
    
    .headLeft {
        > span {
            display: inline-block;
            width: 10px;
            height: 60px;
            background: url('~@/assets/images/rectange.png') 100% 100%;
        }
        .text {
            margin-left: 16px;
			align-items: flex-start;
            span {
                &:first-child {
                    height: 48px;
                    font-size: 36px;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: 700;
                    color: #FFFFFF;
                    line-height: 48px;
                }
                &:last-child {
                    height: 20px;
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) { // 非pc端
    .header {
        padding-left: 60px;
		height: 98px;
		padding-top: 0;
		margin-top: 12px;
		display: flex;	
		.headLeft {
			.text {
				margin-left: 10px;
				span {
					&:first-child {
						font-size: 16px;
					}
					&:last-child {
						font-size: 10px;
					}
				}
			}
		}	
    }
}

@media screen and (max-width: 1500px) { // 非pc端
	.qrImg {


		img {
			width: 80px !important;
			height: 80px !important;
		}
	}
}
</style>