import request from '@/api/request'
let baseUrl = process.env.NODE_ENV == 'development' ? '' : '/apigw'
//展商列表
export function getExihibitionList(param) {
  return request({
    url: baseUrl +'/gw/index.ashx?method=dg_zhanshang',
    method: 'get',
    params: {
      ...param
    }
  })
}
//展商列表
export function getExihibitionDetail(id) {
  return request({
    url: baseUrl +`/gw/index.ashx?method=dg_zhanshang_id&id=${id}`,
    method: 'get',
    params: {}
  })
}
//展品列表
export function getProducts(param) {
  return request({
    url: baseUrl +`/gw/index.ashx?method=dg_chanping`,
    method: 'get',
    params: {
      ...param
    }
  })
}
//展品详情
export function getProductsDetail(id) {
  return request({
    url: baseUrl +`/gw/index.ashx?method=dg_chanping_id`,
    method: 'get',
    params: {
      id
    }
  })
}
//产品的展区分类接口
export function getCategory() {
  return request({
    url: baseUrl +`/gw/index.ashx?method=dg_zhanqu`,
    method: 'get',
    params: {
      
    }
  })
}
