<template>
    <div class="Equipbuild">
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="5"></Header>
		<!-- <div v-html="html"></div> -->
		<!-- 以上为对接接口了 -->		
		<!-- <img class="temImg" :src="require('@/assets/images/tzdj.jpg')" alt=""> -->
		<h3>服务流程</h3>
		<h4>前期沟通</h4>
		<h5>立项后安排专人接洽，积极沟通，获得参展资料，了解设计搭建需求</h5>
		<h4>方案设计</h4>
		<h5>根据前期沟通需求，定制展台设计方案供客户选择</h5>
		<h4>项目签约</h4>
		<h5>达成合作意向，确定报价，签订合同</h5>
		<h4>设计制作</h4>
		<h5>最迟在展前 25 天确认设计方案，完成主办、主场、展馆等各项手续</h5>
		<h4>施工制作</h4>
		<h5>根据设计方案工厂下料制作</h5>
		<h4>现场施工</h4>
		<h5>现场展览搭建，处理现场追加、变更项目，配合展品进场</h5>
		<h4>确定验收</h4>
		<h5>根据要求进行项目验收并交付客户使用</h5>
		<h4>展后撤场</h4>
		<h5>配合客户撤展离场</h5>
		<h4>业务类型</h4>
		<h5>2023年我们为参展商提供展具设计搭建服务业务：标准化特装展具和定制化特装展具</h5>
        <h4>招商咨询</h4>
        <div class="personList">
            <div class="list">
                <span>电话  010-83935791</span>
                <span>电话      020-62878361</span>
            </div>
            <div class="list">
                <span>李论 18600104403</span>
                <span>龙婷婷 18675955335</span>
            </div>
            <div class="list">
                <span>田甜 15901234263</span>
                <span>胡景明 15013149920</span>
            </div>
            <div class="list">
                <span>祁继 13811845831</span>
                <span>邹碧云 17620224467</span>
            </div>
            <div class="list">
                <span>丁桥 15011422050</span>
                <span>赖丽红 13710063796</span>
            </div>
        </div>
        <h4>设计搭建咨询</h4>
        <div class="personList">
            <div class="list">
                <span>吴林峰 18501235598</span>
                <span>吴玲丽 13016031031</span>
            </div>
        </div>		
		<!-- <h4>如有特装搭建需求，请联系下方推荐特装搭建服务商：</h4>
		<table border="1">
			<tr>
				<th colspan="2">广州裕飞展览策划有限公司</th>
			</tr>
			<tr>
				<td>联系人</td>
				<td>吴玲丽</td>
			</tr>
			<tr>
				<td>联系方式</td>
				<td>13016031031</td>
			</tr>
			<tr>
				<td>E-mail</td>
				<td>280522994@qq.com</td>
			</tr>
			<tr>
				<td>地址</td>
				<td>广州市天河区黄埔大道中666号1510室</td>
			</tr>
		</table>
		<table border="1">
			<tr>
				<th colspan="2">广东美刻会展服务有限公司</th>
			</tr>
			<tr>
				<td>联系人</td>
				<td>叶栋辉</td>
			</tr>
			<tr>
				<td>联系方式</td>
				<td>13929534142</td>
			</tr>
			<tr>
				<td>E-mail</td>
				<td>378854381@qq.com</td>
			</tr>
			<tr>
				<td>地址</td>
				<td>广州市番禺区新造镇和平路1号良仓新造创意园14仓101</td>
			</tr>
		</table>
		<table border="1">
			<tr>
				<th colspan="2">毕加展览有限公司</th>
			</tr>
			<tr>
				<td>联系人</td>
				<td>刘玉梅</td>
			</tr>
			<tr>
				<td>联系方式</td>
				<td>13632370352</td>
			</tr>
			<tr>
				<td>E-mail</td>
				<td>790033787@qq.com</td>
			</tr>
			<tr>
				<td>地址</td>
				<td>广州天河区中山大道金鱼街1号珠村国际机械D303</td>
			</tr>
		</table> -->
		<div class="main" v-if="false">
			<div class="content1">
				<div class="title">特装搭建</div>
				<div class="content-block">
					<div class="exhibit">
						<div class="exhibit-title">北京中城轨会展有限公司</div>
						<div class="exhibit-introduce">
							联系人：吴林峰18501235598
						</div>
					</div>
					<div class="content-image"></div>
				</div>
				<div class="content-block blocktype2">
					<div class="content-image"></div>
					<div class="exhibit">
						<div class="exhibit-title">广州裕飞展览策划有限公司</div>
						<div class="exhibit-introduce">
							联系人：吴玲丽13016031031
						</div>
					</div>
				</div>
			</div>
		</div>        
    </div>
</template>

<script>
    import {
        exhibitiondisplaycommonGetList,
    } from '@/api/exhibition'
export default {
    name: 'ExhibitionprojectEquipbuild',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
			isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
			tableData: [],
			html: '',
        };
    },

    mounted() {
		// this.exhibitiondisplaycommonGetList()        
    },

    methods: {
            exhibitiondisplaycommonGetList() {
                var param2 = {
                    firstDirName: '展商服务',
                    secondDirName: '特装搭建',
                }

                exhibitiondisplaycommonGetList(param2).then(res=>{
					var data = res.data.data
					var html = data[0].plateBody
					this.html = html

                })                
            },	        
    },
};
</script>

<style lang="scss" scoped>
.Equipbuild {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #EEE;    
	table {
		width: 600px;
		margin: 32px auto;
		th {
			height: 40px;
			background: #EDF0F7;
			border: 1px solid #C9C6C6;
			font-size: 16px;
			font-family: Source Han Sans CN-Regular, Source Han Sans CN;
			font-weight: 400;
			color: #000000;
			line-height: 40px;
			text-align: center;
		}
		td {
			height: 30px;
			font-size: 16px;
			font-family: Source Han Sans CN-Regular, Source Han Sans CN;
			font-weight: 400;
			color: #000000;
			line-height: 30px;
			border: 1px solid #C9C6C6;
		}
	}
	h3 {
		height: 48px;
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;       
        padding-bottom: 32px;
        margin-top: 48px;
	}
	h4 {
		height: 30px;
		font-size: 16px;
		font-family: Source Han Sans CN-Bold, Source Han Sans CN;
		font-weight: 700;
		color: #000000;
		line-height: 30px;		
	}
	h5 {
		font-size: 16px;
		font-family: Source Han Sans CN-Regular, Source Han Sans CN;
		font-weight: 400;
		color: #000000;
		line-height: 30px;         
		margin-bottom: 48px;   
	}	
	.temImg {
		width: 50%;
		margin: 20px auto;
	}
	.main {
        width: 100%;
		margin: 0 auto;
		background-color: #FFF;

		.title {
			height: 48px;
			font-size: 26px;
			font-family: Source Han Sans CN-Bold, Source Han Sans CN;
			font-weight: 700;
			color: #0C266D;
			line-height: 48px;       
			margin: 48px 0 40px 0;
		}

		.content1 {
			width: 100%;
			.content-block {
				display: flex;
				justify-content: space-between;
				width: 100%;
				padding: 136px 206px 64px 224px;
				box-sizing: border-box;
				background-color: #FFF;
				&:nth-child(2) {
					padding-top: 0;
				}

				.exhibit {
					max-width: 550px;

					.exhibit-title {
						width: 100%;
						color: #0B43BC;
						font-family: Source Han Sans CN-Regular, Source Han Sans CN;
						font-size: 24px;
						text-align: left;
						line-height: 48px;

					}
					.exhibit-introduce {
						width: 480px;
						min-height: 256px;
						margin-top: 12px;
						color: #000;
						font-family: Source Han Sans CN-Regular, Source Han Sans CN;
						font-size: 16px;
						text-indent: 2em;
						text-align: left;
						line-height: 32px;
					}
				}

				.content-image {
					width: 400px;
					height: 300px;
					background-color: silver;
				}
			}
			.blocktype2 {
				padding-top: 80px;
				padding-bottom: 80px;
				background-color: #F7F7F7;
			}
		}
	}
    .personList {
        width: 960px;
        margin: 0 auto;
        margin-bottom: 48px;
        .list {
            margin-bottom: 12px;
            span {
                height: 30px;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #000000;
                line-height: 30px;
                &:nth-child(1) {
                    margin-right: 138px;
                }
            }
        }
        .list2 {
            margin-bottom: 12px;
            display: flex;
            justify-content: space-around;
            span {
                height: 30px;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #000000;
                line-height: 30px;
                display: flex;
                // &:nth-child(1),
                // &:nth-child(2) {
                //     margin-right: 138px;
                // }
            }
        }
    }	
}
    @media screen and (max-width: 1300px) { // 非pc端
        .Equipbuild {
			table {
				width: 100% !important;
				min-width: 375px !important;
				max-width: 600px !important;
			}
			.personList {
				// width: 550px !important;

			}			
		}
    }
    @media screen and (max-width: 580px) { // 非pc端
        .personList {
            width: 100% !important;
            .list, .list2 {
                display: flex;
                flex-direction: column;
                justify-content: flex-start !important;
                align-content: center !important;
                align-items: center;
                span {
                    margin-right: 0 !important;
                }
            }

        }
    }	
</style>