<!--
 * @Author: huanggs huanggx@gzyct.com
 * @Date: 2023-05-15 10:51:24
 * @LastEditors: huanggs huanggx@gzyct.com
 * @LastEditTime: 2023-06-21 10:44:22
 * @FilePath: \exhibitionProject\src\views\exhibitionService\joinExhibitionPath.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="Joinexhibitionpath">
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="8"></Header>
            <!-- 移动端代码  -->
            <template v-if="isMobile">
                <div class="m_introduce">
                    <h3>
                        <img :src="require('@/assets/mobileImages/h3_left.png')" alt="">
                        <span>参展流程</span>
                        <img :src="require('@/assets/mobileImages/h3_right.png')" alt="">
                    </h3>
                    <div class="introduce">
                        <!-- <div v-html="html"></div> -->
                        <!-- 以上为对接接口了 -->                        
                        <div class="title">第一步： 了解展会基本信息</div>
                        <div class="m_content">展区平面图：</div>
                        <img :src="require('@/assets/images/exhibition1.png')"  :large="require('@/assets/images/exhibition1.png')" preview="1" preview-text="" alt="" srcset="">
                         <div class="m_content">展会布局图：</div>
                        <img :src="require('@/assets/images/exihibition2.png')"  :large="require('@/assets/images/exihibition2.png')" preview="1" preview-text="" alt="" srcset="">
                        <h5 style="margin: 48px 0 12px 0;">展位价格及展区说明：</h5>
                        <a href="#" @click="toDownload">提示：点击进入下载中心“招商手册”类目下载“首届广州轨交展招商手册”</a>
                        <img :src="require('@/assets/images/exhibition3.png')"  :large="require('@/assets/images/exhibition3.png')" preview="1" preview-text="" alt="" srcset="">
                        <h5 style="margin: 48px 0 12px 0;">标准展位配置：</h5>
                        <div class="text">
                            搭建规格3*3*3.5mH，展板2.5mH，展位楣板（写有展位编号、公司中英文名称）、1张咨询桌及2张折椅、4支射灯（2支在楣板，2支安装于展位内）、1个3A插座（最高500W，仅用于电视机、电脑、饮水机等一般家用设备，严禁用于展示设备机械接驳或照明接驳）、1个垃圾篓。
                        </div>
                        <img :src="require('@/assets/images/exihibition4.png')"  :large="require('@/assets/images/exihibition4.png')" preview="1" preview-text="" alt="" srcset="">
                        <h4 style="margin: 48px 0 0 0;">第二步：挑选展区/展位，填写展位申请表</h4>
                        <!-- <span class="a">
                            请<a href="#" @click="toSingup">点击链接报名</a>或联系组委会索取2023年广州展展位申请表
                        </span> -->
                        <span class="a">
                            请联系组委会索取2023年广州展展位申请表
                        </span>
                        <h4 style="margin: 48px 0 0 0;">第三步：签订参展合同</h4>
                        <h5>请按要求填写组委会提供的“参展合同”并阅读“合同条款”，签字盖章后传至组委会，按照合同条款完成展位费支付即可享有相应权益。</h5>
                        <h4 style="margin: 48px 0 0 0;">第四步：选择展台设计搭建或媒体传播服务</h4>
                        <h5>组委会提供“一站式”的参展新体验，可根据需求选择展台设计搭建服务和媒体传播服务，沟通明确服务需求后，签订“服务合同”。</h5>
                        <h4 style="margin: 48px 0 0 0;">第五步：提交资料</h4>
                        <h5>查看“参展商手册”并按规定在截止日期前向组委会及指定供应商提交基本信息。</h5>

                        <h4 style="margin: 48px 0 0 0;">招商咨询</h4>
                        <div class="personList">
                            <div class="list">
                                <span>电话  010-83935791</span>
                                <span>电话      020-62878361</span>
                            </div>
                            <div class="list">
                                <span>李论 18600104403</span>
                                <span>龙婷婷 18675955335</span>
                            </div>
                            <div class="list">
                                <span>田甜 15901234263</span>
                                <span>胡景明 15013149920</span>
                            </div>
                            <div class="list">
                                <span>祁继 13811845831</span>
                                <span>邹碧云 17620224467</span>
                            </div>
                            <div class="list">
                                <span>丁桥 15011422050</span>
                                <span>赖丽红 13710063796</span>
                            </div>
                        </div>
                        <h4>展会咨询</h4>
                        <div class="personList">
                            <div class="list">
                                <span>陈岩 13717655325</span>
                                <span>陈菁 18620693085</span>
                                <!-- <span>薄纯玉 18500059709</span> -->
                            </div>
                        </div>
                        <h4>设计搭建咨询</h4>
                        <div class="personList">
                            <div class="list">
                                <span>吴林峰 18501235598</span>
                                <span>吴玲丽 13016031031</span>
                            </div>
                        </div>                                            
                    </div>                
                </div>            
            </template>
            <template v-else>
                <div class="middle">
                    <h3>参展流程</h3>
                    <!-- <div v-html="html"></div> -->
                    <!-- 以上为对接接口了 -->

                    <h4>第一步： 了解展会基本信息</h4>
                    <h5 style="margin: 12px 0;">展馆平面图：</h5>
                    <img :src="require('@/assets/images/exhibition1.png')"  :large="require('@/assets/images/exhibition1.png')" preview="1" preview-text="" alt="" srcset="">
                    <h5 style="margin: 48px 0 12px 0;">展会布局图：</h5>
                    <img :src="require('@/assets/images/exihibition2.png')"  :large="require('@/assets/images/exihibition2.png')" preview="1" preview-text="" alt="" srcset="">
                    <h5 style="margin: 48px 0 12px 0;">展位价格及展区说明：</h5>
                    <a href="#" @click="toDownload">提示：点击进入下载中心“招商手册”类目下载“首届广州轨交展招商手册”</a>
                    <img :src="require('@/assets/images/exhibition3.png')"  :large="require('@/assets/images/exhibition3.png')" preview="1" preview-text="" alt="" srcset="">
                    <h5 style="margin: 48px 0 12px 0;">标摊展位配置：</h5>
                    <div class="text">
                        搭建规格3*3*3.5mH，展板2.5mH，展位楣板（写有展位编号、公司中英文名称）、1张咨询桌及2张折椅、4支射灯（2支在楣板，2
                        支安装于展位内）、1个3A插座（最高500W，仅用于电视机、电脑、饮水机等一般家用设备，严禁用于展示设备机械接驳或照明接驳）、
                        1个垃圾篓。
                    </div>
                    <img :src="require('@/assets/images/exihibition4.png')"  :large="require('@/assets/images/exihibition4.png')" preview="1" preview-text="" alt="" srcset="">
                    <h4 style="margin: 48px 0 0 0;">第二步：挑选展区/展位，填写展位申请表</h4>
                    <!-- <span class="a">
                        请<a href="#" @click="toSingup">点击链接报名</a>或联系组委会索取2023年广州展展位申请表
                    </span> -->
                    <span class="a">
                        请联系组委会索取2023年广州展展位申请表
                    </span>
                    <h4 style="margin: 48px 0 0 0;">第三步：签订参展合同</h4>
                    <h5>请按要求填写组委会提供的“参展合同”并阅读“合同条款”，签字盖章后传至组委会，按照合同条款完成展位费支付即可享有相应权益。</h5>
                    <h4 style="margin: 48px 0 0 0;">第四步：选择展台设计搭建或媒体传播服务</h4>
                    <h5>组委会提供“一站式”的参展新体验，可根据需求选择展台设计搭建服务和媒体传播服务，沟通明确服务需求后，签订“服务合同”。</h5>
                    <h4 style="margin: 48px 0 0 0;">第五步：提交资料</h4>
                    <h5>查看“参展商手册”并按规定在截止日期前向组委会及指定供应商提交基本信息。</h5>

                    <h4 style="margin: 48px 0 0 0;">招商咨询</h4>
                    <div class="personList">
                        <div class="list">
                            <span>电话  010-83935791</span>
                            <span>电话      020-62878361</span>
                        </div>
                        <div class="list">
                            <span>李论 18600104403</span>
                            <span>龙婷婷 18675955335</span>
                        </div>
                        <div class="list">
                            <span>田甜 15901234263</span>
                            <span>胡景明 15013149920</span>
                        </div>
                        <div class="list">
                            <span>祁继 13811845831</span>
                            <span>邹碧云 17620224467</span>
                        </div>
                        <div class="list">
                            <span>丁桥 15011422050</span>
                            <span>赖丽红 13710063796</span>
                        </div>
                    </div>
                    <h4>展会咨询</h4>
                    <div class="personList">
                        <div class="list">
                            <span>陈岩 13717655325</span>
                            <span>陈菁 18620693085</span>
                            <!-- <span>薄纯玉 18500059709</span> -->
                        </div>
                    </div>
                    <h4>设计搭建咨询</h4>
                    <div class="personList">
                        <div class="list">
                            <span>吴林峰 18501235598</span>
                            <span>吴玲丽 13016031031</span>
                        </div>
                    </div>
                </div>
            </template>            



    </div>
</template>

<script>
import {
    exhibitiondisplaycommonGetList,
} from '@/api/exhibition'
export default {
    name: 'ExhibitionprojectJoinexhibitionpath',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
            html: '',
        };
    },

    mounted() {
        var that = this
        this.$root.$on('upDateView', ()=>{
            that.isMobile = JSON.parse(sessionStorage.getItem('isMobile'))
        })
        // this.exhibitiondisplaycommonGetList()
    },

    methods: {
        toDownload() {
            this.$router.push('/home/downloadCenter')

        },
        toSingup() {
            this.$router.push('/home/exhibitsSiteOrder')
        },
        exhibitiondisplaycommonGetList() {
            var param = {
                firstDirName: '展商服务',
                secondDirName: '参展流程',
            }
            exhibitiondisplaycommonGetList(param).then(res=>{
                var data = res.data.data
                // var html = data[0].plateBody.replace('<img ', '<img preview="1" preview-text="" srcset=""')
                var html = data[0].plateBody
                this.html = html

            })          
        },        
    },
};
</script>

<style lang="scss" scoped>
.Joinexhibitionpath {
        .personList {
            // width: 960px;
            margin: 0 auto;
            margin-bottom: 48px;
            .list {
                margin-bottom: 12px;
                span {
                    height: 30px;
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #000000;
                    line-height: 30px;
                    &:nth-child(1) {
                        margin-right: 138px;
                    }
                }
            }
            .list2 {
                margin-bottom: 12px;
                display: flex;
                justify-content: space-around;
                span {
                    height: 30px;
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #000000;
                    line-height: 30px;
                    display: flex;
                    // &:nth-child(1),
                    // &:nth-child(2) {
                    //     margin-right: 138px;
                    // }
                }
            }
        }       
    .middle {
        width: 850px;
        margin: 0 auto;
        h3 {
            height: 48px;
            font-size: 26px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 700;
            color: #0C266D;
            line-height: 48px;       
            margin-bottom: 48px;
            margin-top: 48px;
        }
        h4 {
            height: 30px;
            font-size: 16px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 700;
            color: #000000;
            line-height: 30px;            
        }
        h5 {
            height: 30px;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            line-height: 30px;  
            text-align: justify;          
        }
        .text {
            height: 60px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #727272;
            line-height: 20px;
            text-align: justify;
            margin-bottom: 12px;
        }     
    }
    img {
        width: 100%;
    }
    .a {
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
    }
    .m_introduce {
        // width: 327px;
        // width: 85%;
        // height: 318px;
        background: #FAFAFC;
        box-shadow: 0px 1px 0px 0px #E6E6E6;
        border-radius: 6px 6px 6px 6px;
        margin: 16px auto;
        padding: 12px;
        h3 {
            margin: 0 !important;
            img {
                width: 27px;
                height: 9px;
            }
            span {
                height: 21px;
                font-size: 17px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: 700;
                color: #0C266D;
                line-height: 24px;
                margin: 0 20px;
            }
        }
        .introduce {
            width: 100%;
            margin-top: 17px;
            .title {
                height: 30px;
                font-size: 16px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: 600;
                color: #000000;
                line-height: 30px;
            }
            .text {
                text-align: justify;
            }
            .a {
                height: 30px;
                font-size: 16px;
                text-align: justify;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #000000;
                line-height: 30px;
            }
            h4 {
                font-size: 16px;
                font-weight: 600;
            }
            h5 {
                font-size: 12px;
                text-align: justify;
            }
            .m_content {
                height: 18px;
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #000000;
                line-height: 18px;
                margin-top: 20px;
                margin-bottom: 10px;
            }
            .personList { 
                .list, .list2 {
                    span {
                        font-size: 12px;
                    }
                }
            }
        }
        p {
            font-size: 12px !important;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            line-height: 18px;
            text-indent: 25px;
            &:nth-child(2) {
                margin-top: 20px;
            }
        }
    }    
}

    a {
        text-decoration: underline;
        height: 30px;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #0B43BC;
        line-height: 30px;
    }
   @media screen and (max-width: 650px) { // 非pc端
        .m_introduce {
            .personList { 
                .list, .list2 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    span {
                        font-size: 12px;
                        margin-right: 0 !important;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
   }
</style>