<template>
    <div>
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleName="titleName"></Header>
        <div class="search">
            <div class="searchBtn flex_fsc">
                <el-input v-model="input" placeholder="请输入关键词"></el-input>
                <el-button type="primary" @click="toSearch">搜索</el-button>
            </div>
        </div>
        <div class="productCell">
            <div class="pcell" v-for="(item, i) in productList" :key="i" @click="toProductDetail(item.id)">
                <div class="pic">
                    <img :src="item.logo" alt="">
                </div>
                <div class="msg">
                    <div class="mtitle">{{item.cpmc}}</div>
                    <div class="operate flex_fsbc">
                        <span>展位号：{{item.zwh}}</span>
                        <span>查看详情</span>
                    </div>
                </div>
            </div>
            <Empty v-show="!productList.length"></Empty>
        </div>
        <SelfPage :total="total" :size="12" @handleCurrentChange="handleCurrentChange" v-show="productList.length" prevText="上一页" nextText="下一页"></SelfPage>
    </div>
</template>

<script>
import {
    getProducts,
} from '@/api/home'
export default {
    name: 'ExhibitionprojectOnlineproductlist',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),            
            input: '',
            titleName:{
                chinese: this.$route.query.name,
                english: 'List of exhibits',
            },
            productList: [],  
            page: 1,
            size: 12,
            total: 0,  
        };
    },

    mounted() {
        this.getProducts(this.$route.query.name)
    },
// 智能列车及先进车辆段配套装备
    methods: {
        getProducts(name) {
            var param = {
                zhanqu: name,
                pageindex: this.page,
                pagesize: this.size,
                cpmc: this.input,
            }
            getProducts(param).then(res=>{
                var data = res.data
                this.productList = data.chanping
                this.total = Number(data.total)
            })
        },
        handleCurrentChange(v) {
            this.page = v
            this.getProducts(this.$route.query.name)
            this.$root.$emit('toTop')
        },
        toSearch() {
            this.getProducts(this.$route.query.name)
            this.$root.$emit('toTop')
        },
        toProductDetail(id) {
            this.$router.push(`/home/showProductDetail?productId=${id}`)
            this.$root.$emit('toTop')
        },        
    },
};
</script>

<style lang="scss" scoped>
.search {
    width: 100%;
    height: 180px;
    line-height: 180px;
    background: #F7F7F7;
    .searchBtn {
        width: 688px;
        margin: 0 auto;
        ::v-deep .el-input__inner {
            border-radius: 0;
            height: 48px;
            background: #FFFFFF;
            border: 1px solid #C9C6C6;
        }
        .el-button {
            border-radius: 0;
            width: 88px;
            height: 48px;
            background: #0B43BC;
        }
    }
}
    .productCell {
            max-width: 940px;
            margin: 0 auto;
            margin-top: 48px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: space-between;
            .pcell {
                width: 300px;
                height: 322px;
                cursor: pointer;
                &:not(:nth-child(3n)) {
                    margin-right: 20px;
                }
                margin-bottom: 20px;
                .pic {
                    background: #EEEEEE;
                    img {
                        width: 215px;
                        height: 150px;
                    }
                    padding: 30px;
                }
                .msg {
                    background: #FFFFFF;
                    padding: 12px;
                    .mtitle {
                        height: 56px;
                        font-size: 18px;
                        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                        font-weight: 700;
                        color: #222222;
                        line-height: 28px;
                        text-align: justify;
                    }
                    .operate {
                        
                        span {
                            height: 20px;
                            font-size: 12px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #0C266D;
                            line-height: 20px;
                            &:last-child {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
    }

    @media screen and (max-width: 1440px) { // 非pc端
        .searchBtn {
            width: 80% !important;
        }
        .productCell {
            justify-content: center;
            align-content: center;

        }
    }

    @media screen and (max-width: 1000px) { // 非pc端
        .productCell {
            .pcell {
                &:not(:nth-child(3n)) {
                    margin-right: 0;
                }
            }

        }
    }
</style>