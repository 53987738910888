<!--
 * @Author: huanggs huanggx@gzyct.com
 * @Date: 2023-05-16 09:13:39
 * @LastEditors: huanggs huanggx@gzyct.com
 * @LastEditTime: 2023-06-21 12:07:57
 * @FilePath: \exhibitionProject\src\views\audienceService\visitGuide.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="visitGuide">
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleIndex="18"></Header>
        <!-- <div v-html="html"></div> -->
        <!-- 以上为对接接口了 -->	        
        <h3>参观指南</h3>
        <!-- <img :src="require('@/assets/images/empty__image@2x.png')" alt=""> -->
        <!-- <div class="text">此功能建设中，敬请期待！</div> -->
        <a class="h3" target="_self" href="http://www.gbatransit.com/resource/参观指南-电子版.pdf">参观指南-电子版.pdf</a>
    </div>
</template>

<script>
    import {
        exhibitiondisplaycommonGetList,
    } from '@/api/exhibition'
export default {
    name: 'ExhibitionprojectVisitguide',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),
            html: '',
        };
    },

    mounted() {
        // this.exhibitiondisplaycommonGetList()
    },

    methods: {
            exhibitiondisplaycommonGetList() {
                var param2 = {
                    firstDirName: '观众服务',
                    secondDirName: '参观指南',
                }

                exhibitiondisplaycommonGetList(param2).then(res=>{
					var data = res.data.data
					var html = data[0].plateBody
					this.html = html

                })                
            },         
    },
};
</script>

<style lang="scss" scoped>
.visitGuide {
    min-height: 100vh;
    h3 {
        height: 48px;
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;       
        padding-bottom: 32px;
        margin-top: 48px;
    }
    .h3 {
        font-size: 22px;
    }
    img {
        width: 160px;
        height: 173px;
    }
    .text {
        height: 30px;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        margin-bottom: 180px;
    }    
}
</style>