<template>
  <div class="navigation" @click.stop>
    <div class="navTop flex_fcc">
      <img class="navLeft_img" :src="require('@/assets/images/logo.png')" />
      <div class="navTabs" v-if="!isMobile">
        <el-menu 
        :default-active="activeIndex"
        background-color="#0C266D"
        text-color="#FFFFFF"
        active-text-color="#FFFFFF"
        class="el-menu-demo"
        mode="horizontal" 
        @select="handleSelect">
          <el-menu-item index="首页">
            <a class="rightA" :href="orgin+'/#/home/banner'" rel="noopener noreferrer">首页</a>
          </el-menu-item>
          <el-submenu index="展会介绍">
            <template slot="title">展会介绍</template>
            <el-menu-item index="展会概况">
              <a class="rightA" :href="orgin+'/#/home/situation'" rel="noopener noreferrer">展会概况</a>
            </el-menu-item>
            <el-menu-item index="亮点推荐">
              <a class="rightA" :href="orgin+'/#/home/highlight'" rel="noopener noreferrer">亮点推荐</a>
            </el-menu-item>
            <el-menu-item index="组织架构">
              <a class="rightA" :href="orgin+'/#/home/framework'" rel="noopener noreferrer">组织架构</a>
            </el-menu-item>
            <el-menu-item index="关于我们">
              <a class="rightA" :href="orgin+'/#/home/aboutus'" rel="noopener noreferrer">关于我们</a>
            </el-menu-item>
            <el-menu-item index="下载中心">
              <a class="rightA" :href="orgin+'/#/home/downloadCenter'" rel="noopener noreferrer">下载中心</a>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="展商服务">
            <template slot="title">展商服务</template>
            <el-menu-item index="展位预定" v-if="false">
            <!-- <el-menu-item index="展位预定"> -->
              <a class="rightA" :href="orgin+'/#/home/exhibitsSiteOrder'" rel="noopener noreferrer">展位预定</a>
            </el-menu-item>
            <el-menu-item index="展品范围">
              <a class="rightA" :href="orgin+'/#/home/exhibitionRange'" rel="noopener noreferrer">展品范围</a>
            </el-menu-item>
            <el-menu-item index="参展流程">
              <a class="rightA" :href="orgin+'/#/home/joinExhibitionPath'" rel="noopener noreferrer">参展流程</a>
            </el-menu-item>
            <el-menu-item index="特装搭建">
              <a class="rightA" :href="orgin+'/#/home/equipBuild'" rel="noopener noreferrer">特装搭建</a>
            </el-menu-item>
            <el-menu-item index="展商登录">
              <a class="rightA" href="http://gzgdzs.zbase.cn/login_zs.html?zhid=1" target="_blank" rel="noopener noreferrer">展商登录</a>
            </el-menu-item>
            <el-menu-item index="展商交通指引">
              <!-- <a class="rightA" :href="orgin+'/#/home/trafficGuide'" rel="noopener noreferrer">交通指引</a> -->
              <a class="rightA" :href="orgin+'/#/home/audienceTrafficGuide'" rel="noopener noreferrer">交通指引</a>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="观众服务">
            <template slot="title">观众服务</template>
            <el-menu-item index="论坛/观展报名">
              <a class="rightA" href="http://xt.zbase.cn/1326/home.html" target="_blank" rel="noopener noreferrer">论坛/观展报名</a>
            </el-menu-item>
            <el-menu-item index="参观指南">
              <!-- <a class="rightA" :href="orgin+'/#/home/visitGuide'" rel="noopener noreferrer">参观指南</a> -->
              <a class="rightA" target="_self" href="http://www.gbatransit.com/resource/参观指南-电子版.pdf" rel="noopener noreferrer">参观指南</a>
            </el-menu-item>
            <el-menu-item index="同期活动">
              <a class="rightA" :href="orgin+'/#/home/sameActivity'" rel="noopener noreferrer">同期活动</a>
            </el-menu-item>
            <el-menu-item index="观众交通指引">
              <a class="rightA" :href="orgin+'/#/home/audienceTrafficGuide'" rel="noopener noreferrer">交通指引</a>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="媒体中心">
            <template slot="title">媒体中心</template>
            <el-menu-item index="展会直播">
              <!-- <a class="rightA" :href="orgin+'/#/home/forumLive'" rel="noopener noreferrer">展会直播</a> -->
              <a class="rightA" target="_self" href="https://wx.vzan.com/live/page/980809753?v=1687752770127" rel="noopener noreferrer">展会直播</a>
            </el-menu-item>
            <el-menu-item index="合作媒体">
              <a class="rightA" :href="orgin+'/#/home/cooperateMedia'" rel="noopener noreferrer">合作媒体</a>
            </el-menu-item>
            <el-menu-item index="资讯报道">
              <a class="rightA" :href="orgin+'/#/home/consultReport'" rel="noopener noreferrer">资讯报道</a>
            </el-menu-item>
            <el-menu-item index="媒体矩阵">
              <a class="rightA" :href="orgin+'/#/home/mediamatrix'" rel="noopener noreferrer">媒体矩阵</a>
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="精彩图集">
            <a class="rightA" :href="orgin+'/#/home/wnderfulPic'" rel="noopener noreferrer">精彩图集</a>
          </el-menu-item>
          <!-- <el-submenu index="线上展">
            <template slot="title">线上展</template>
            <el-menu-item index="推荐展商">推荐展商</el-menu-item>
            <el-menu-item index="参展展品">参展展品</el-menu-item>
          </el-submenu> -->
          <el-menu-item index="线上展">
            <a class="rightA" :href="orgin+'/#/home/onlineShow'" rel="noopener noreferrer">线上展</a>
          </el-menu-item>
          <el-menu-item index="联系我们">
            <a class="rightA" :href="orgin+'/#/home/contactUs'" rel="noopener noreferrer">联系我们</a>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="navSearch flex_fcc">
        <!-- 20230609隐藏搜索功能 -->
        <!-- <div class="ns flex_fcc" v-if="!isMobile">
          <el-input v-model="searchValue" @focus="handleJump"></el-input>
          <el-button type="primary">搜索</el-button>
        </div> -->
        <!-- <img v-else class="el-icon-s-fold" @click="handleExpand" :src="require('@/assets/images/list-check@2x.png')" /> -->
        <img class="el-icon-s-fold" @click="handleExpand" :src="require('@/assets/images/list-check@2x.png')" />
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'navigation',
  props: {
    activeIndex: {
      type: String,
      default: '首页'
    },
  },
  data() {
    return {
      activeName: '1',
      searchValue: '',
      isExpand: false,
      currentNav: '',
      orgin: '',
      isMobile: JSON.parse(sessionStorage.getItem('isMobile')), 
    };
  },

  mounted() {
    this.orgin = location.origin
      this.$root.$on('upDateView', ()=>{
          that.isMobile = JSON.parse(sessionStorage.getItem('isMobile'))
      })
  },

  methods: {
    handleExpand() {
      this.isExpand = !this.isExpand
      this.$emit('handleShowExpand', this.isExpand);
    },
    handleJump() {
      this.$router.push(`/searchResult`)
    },
    handleClick() {

    },
    handleSelect(v) {
      this.currentNav = v
      this.$emit('handleNavEvent', v);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rightA {
  color: #fff;
  font-size: 14px;
  &:hover {
    background-color: #0C266D !important;
  }
}
  .navigation { 
    background: #0C266D;
  }

  .navTop {
    width: 100%;
    height: 80px;
    background: #0C266D;
    border-radius: 0px 0px 0px 0px;
    .navLeft_img {
      width: 197px;
      height: 53px;
      margin-right: 22px;
    }
    .navTabs {
      height: 80px;
      ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
        display: none;
      }
      ::v-deep .el-menu--horizontal>.el-menu-item, ::v-deep .el-submenu__title, ::v-deep a {
        color: #fff;
        font-size: 18px;
        height: 80px;
        line-height: 80px;
        &:hover {
          background-color: #0C266D !important;
        }
      }

      ::v-deep .el-menu.el-menu--horizontal {
        border-bottom: none;
      }
      ::v-deep .el-menu--horizontal>.el-menu-item.is-active, ::v-deep .is-active .el-submenu__title {
          border-bottom: 3px solid #65BC62 !important;
      }
      ::v-deep .el-menu--horizontal>.el-submenu:hover {
        background-color: #0C266D;
      }
      .el-menu {
        background-color: #0C266D;
      }

    }
    .navSearch {
      width: 308px;
      height: 53px;
      margin-left: 57px;
      .el-input {
        width: 221px;
      }
      ::v-deep .el-input__inner {
        width: 221px;
        height: 32px;
        background: rgba(255,255,255,0.2);
        border-radius: 4px 0px 0px 4px;
        border: 0px solid rgba(0,0,0,0.14);
      }
      .el-button {
        width: 87px;
        height: 32px;
        line-height: 10px;
        background: #65BC62;
        border-radius: 0px 4px 4px 0px;
        font-size: 18px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
      ::v-deep .el-button--primary {
        border: none !important;
      }
      .el-icon-s-fold {
        color: #fff;
        width: 24px;
        height: 24px;
        display: none;
      }
    }

  }
  @media screen and (max-width: 1440px) { // 非pc端
    .navTabs {
      height: 64px !important;
      ::v-deep .el-submenu__title, ::v-deep .el-menu-item {
        // font-size: 14px !important;
        height: 64px !important;
        padding: 0 8px !important;
        transform: scale(0.8);
      }
      // display: none;
    }
    .navSearch {
      transform: scale(0.8);
      width: 180px !important;
      // margin: 0 !important;
    }
    .ns {
      width: 180px !important;
      ::v-deep .el-input__inner {
        width: 120px !important;
      }
    }
  }
  @media screen and (max-width: 1300px) { // 非pc端
    .navigation {
      padding: 0 6px 0 6px !important;
      
    }
    .navTop {
      display: flex;
      justify-content: space-between;
      height: 64px;
      
    }

    .navSearch {
      justify-content: flex-end !important;
    }

    .el-icon-s-fold {
      display: inline-block !important;
    }
  }
</style>