<template>
    <div class="footer">
        <div class="firstF">
            <el-row>
                <!-- <el-col class="business">
                    <img :src="require('@/assets/images/logo.png')" alt="">
                    <span>
                        Loremipsumdolorsitametconsecteturadipiscingelithacid,hendreritphasellussedlitorapraesentlectusarcusodales.
                    </span>
                </el-col> -->
                <el-col class="business">
                    <span class="toLink" @click="hadleTo('首页')">
                        首页
                    </span>
                </el-col>
                <el-col class="pc">
                    <h3>展会介绍</h3>
                    <h5 @click="hadleTo('展会概况')">展会概况</h5>
                    <h5 @click="hadleTo('亮点推荐')">亮点推荐</h5>
                    <h5 @click="hadleTo('组织架构')">组织架构</h5>
                    <h5 @click="hadleTo('关于我们')">关于我们</h5>
                    <h5 @click="hadleTo('下载中心')">下载中心</h5>
                </el-col>
                <el-col class="pc">
                    <h3>展商服务</h3>
                    <h5 @click="hadleTo('展位预定')" v-if="false">展位预定</h5>
                    <h5 @click="hadleTo('展品范围')">展品范围</h5>
                    <h5 @click="hadleTo('参展流程')">参展流程</h5>
                    <h5 @click="hadleTo('特装搭建')">特装搭建</h5>
                    <h5 @click="hadleTo('展商登录')">展商登录</h5>
                    <h5 @click="hadleTo('展商交通指引')">交通指引</h5>
                </el-col>
                <el-col class="pc">
                    <h3>观众服务</h3>
                    <h5 @click="hadleTo('论坛/观展报名')">论坛/观展报名</h5>
                    <!-- <h5 @click="hadleTo('论坛报名')">论坛报名</h5> -->
                    <h5 @click="hadleTo('参观指南')">参观指南</h5>
                    <h5 @click="hadleTo('同期活动')">同期活动</h5>
                    <h5 @click="hadleTo('观众交通指引')">交通指引</h5>
                </el-col>
                <el-col class="pc">
                    <h3>媒体中心</h3>
                    <h5 @click="hadleTo('展会直播')">展会直播</h5>
                    <h5 @click="hadleTo('合作媒体')">合作媒体</h5>
                    <h5 @click="hadleTo('资讯报道')">资讯报道</h5>
                    <!-- <h5 @click="hadleTo('微信公众号')">微信公众号</h5> -->
                    <h5 @click="hadleTo('媒体矩阵')">媒体矩阵</h5>
                </el-col>
                <el-col class="pc">
                    <h3 class="toLink" @click="hadleTo('精彩图集')">精彩图集</h3>
                </el-col>
                <el-col class="pc">
                    <h3 class="toLink" @click="hadleTo('线上展')">线上展</h3>
                </el-col>
            </el-row>
        </div>
        <div class="flowSum">
            <el-row v-show="false">
                <el-col class="pc"><h3>流量统计</h3></el-col>
                <el-col class="pc"><h5>1,000,000次</h5></el-col>
                <el-col class="pc"></el-col>
                <el-col class="pc"></el-col>
                <el-col class="pc"></el-col>
                <el-col class="pc"></el-col>
                <el-col class="pc"></el-col>
            </el-row>
        </div>
        <div class="secF">
            <span>Copyright：©-广州大湾区国际轨道交通产业发展论坛暨展览会组委会</span>
            <span>技术支持：©-广州羊城通有限公司</span>
            <span>工业和信息化部备案管理系统网站：<a href="http://beian.miit.gov.cn/" target="_blank">粤ICP备2020106671号</a> </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExhibitionprojectFooter',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
      hadleTo(val) {
        var url= ''
        sessionStorage.setItem('currentNav', val)

        switch (val) {
          case '首页':
            url = '/home/banner'
            break;
          case '展会概况':
            url = '/home/situation'
            break;
          case '亮点推荐':
            url = '/home/highlight'
            break;
          case '组织架构':
            url = '/home/framework'
            break;
          case '关于我们':
            url = '/home/aboutus'
            break;
          case '下载中心':
            url = '/home/downloadCenter'
            break;
          case '展位预定':
            url = '/home/exhibitsSiteOrder'
            break;
          case '展品范围':
            url = '/home/exhibitionRange'
            break;
          case '参展流程':
            url = '/home/joinExhibitionPath'
            break;
          case '特装搭建':
            url = '/home/equipBuild'
            break;
          case '展商登录':
            // 跳转第三方
            window.open('http://gzgdzs.zbase.cn/login_zs.html?zhid=1')
            return
            break;
          case '展商交通指引':
            // url = '/home/trafficGuide'
            url = '/home/audienceTrafficGuide'
            break;            
          case '观众交通指引':
            url = '/home/audienceTrafficGuide'
            break;   
          case '论坛/观展报名':
            // 跳转第三方
            window.open('http://xt.zbase.cn/1326/home.html')
            return
            break;           
          case '参观指南':
            window.open('http://www.gbatransit.com/resource/参观指南-电子版.pdf', '_self')
            return
            url = '/home/visitGuide'
            break;         
          case '同期活动':
            url = '/home/sameActivity'
            break;         
          case '展会直播':
            // 跳转第三方
            window.open('https://wx.vzan.com/live/page/980809753?v=1687752770127', '_self')
            return                   
            url = '/home/forumLive'
            break;         
          case '合作媒体':
            url = '/home/cooperateMedia'
            break;         
          case '资讯报道':
            url = '/home/consultReport'
            break;         
          case '媒体矩阵':
            url = '/home/mediamatrix'
            break;         
          case '精彩图集':
            url = '/home/wnderfulPic'
            break;         
          case '线上展':
            url = '/home/onlineShow'
            break;         
          case '联系我们':
            url = '/home/contactUs'
            break;         

          default:
            url = '/home'
            break;
        }
        this.$router.push(url)
        this.$root.$emit('toTop')
      },
    },
};
</script>

<style lang="scss" scoped>
.footer {
    height: 500px;
    background: #0A1749;
    .toLink {
        cursor: pointer;
    }
    .firstF, .flowSum {
        padding-top: 54px;

        .el-row {
            display: flex;
            justify-content: flex-start;
            margin: 0 auto;
            width: 1325px;
            .el-col {
                width: 265px;
                height: 216px;
                h3 {
                    height: 24px;
                    font-size: 17px;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: 700;
                    color: #FFFFFF;
                    line-height: 24px;
                    margin-bottom: 29px;
                }
                h5 {
                    cursor: pointer;
                    height: 20px;
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #C9C6C6;
                    line-height: 20px;
                    &:hover {
                        color: #f8f6f6;
                    }
                    &:not(:first-child) {
                        margin-top: 10px;
                    }
                }
                &:nth-child(1) {
                    // display: flex;
                    // flex-direction: column;
                    // justify-content: flex-start;
                    // img {
                    //     width: 192px;
                    //     height: 74px;
                    //     margin-bottom: 35px;
                    // }
                    // span {
                    //     display: inline-block;
                    //     width: 257px;
                    //     height: 112px;
                    //     font-size: 14px;
                    //     font-family: Roboto-Regular, Roboto;
                    //     font-weight: 400;
                    //     color: #727272;
                    //     white-space: pre-wrap;
                    //     word-break:break-all;
                    //     line-height: 20px;
                    // }
                }
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5), 
                &:nth-child(6) {
                    
                    border-right: 1px solid rgba(255, 255, 255, .1);
                }
            }
        }
        .business {
            span {
                font-size: 17px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: 700;
                color: #FFFFFF;
                line-height: 24px;
            }
        }
    }
    .secF {
        margin-top: 37px;
        // height: 20px;
        font-size: 14px;
        min-height: 100px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #FFFFFF;
        // line-height: 20px;
        span {
            margin-top: 20px;
            display: block;
        }
        a {
          color: #fff;
          &:hover {
            color: cornflowerblue;
          }
        }
    }
    .flowSum {
        padding-top: 42px;
        .el-col {
            height: 36px !important;
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5), 
                &:nth-child(6) {
                    
                    border-right: none !important;
                }
        }

    }
}
@media screen and (max-width: 1300px) { // 非pc端
    .footer {
        height: 250px;
    }
    .pc {
        display: none;
        width: 0 !important;
        border: none !important;
    }
    .el-row {
        position: relative;
        height: 216px;
    }
    .flowSum, .firstF {
        display: none;
        padding-top: 0px;
    }
    .secF {
        font-size: 12px !important;
        margin-top: 20px !important;
    }

    .business {
        position: absolute;
        left: 20%;
    }

}
</style>