<template>
    <div>
        <Header :headImg="isMobile?mobileHeadImgUrl:headImgUrl" :titleName="titleName"></Header>
        <template v-if="compScreenWidth>1000">
            <div class="prodcutDetailTop">
                <div class="pdtLeft">
                    <!-- <img :src="require('@/assets/images/logo.png')" alt=""> -->
                    <!-- <el-image 
                        :src="detailObj.tp[0]" 
                        :preview-src-list="detailObj.tp">
                    </el-image> -->
                    <img :src="detailObj.tp[0]"  :large="detailObj.tp[0]" preview="1" preview-text="" alt="" srcset="">
                </div>
                <div class="pdtRight">
                    <h3>{{detailObj.cpxx.cpmc}}</h3>
                    <div class="name">
                        <span>展馆</span>
                        <span>{{detailObj.cpxx.zhanguan}}</span>
                    </div>
                    <div class="name">
                        <span>展区</span>
                        <span>{{detailObj.cpxx.zhanqu}}</span>
                    </div>
                    <div class="name">
                        <span>展位号</span>
                        <span>{{detailObj.cpxx.zwh}}</span>
                    </div>
                    <div class="company">
                        <div class="cname">展商：{{detailObj.cpxx.zsmc}}</div>
                        <div class="to" @click="toShowDetail(detailObj.cpxx.zsid)">前往展商 →</div>
                    </div>
                </div>
            </div>
            <div class="introduce">
                <div class="title">
                    <span>产品介绍</span>
                    <el-divider></el-divider>
                </div>
                <div class="text" v-html="detailObj.cpxx.cpjj">
                </div>
            </div>
            <h3>该公司其他产品</h3>
            <div class="productCell">
                <div class="pcell" v-for="(item, i) in detailProduct" :key="i"  @click="toProductDetail(item.id)">
                    <div class="pic">
                        <img :src="item.logo" alt="">
                    </div>
                    <div class="msg">
                        <div class="mtitle">{{item.cpmc}}</div>
                        <div class="operate flex_fsbc">
                            <span>展位号：{{item.zwh}}</span>
                            <span>查看详情</span>
                        </div>
                    </div>
                </div>
                <Empty v-show="!detailProduct.length"></Empty>
            </div>
            <SelfPage :total="total" :size="3" @handleCurrentChange="handleCurrentChange" v-show="detailProduct.length" prevText="上一页" nextText="下一页"></SelfPage>   
        </template>  
        <template v-else>
            <div class="mobileDetail">
                <h3>{{detailObj.cpxx.cpmc}}</h3>
                <!-- <img :src="require('@/assets/images/logo.png')" alt=""> -->
                <!-- <el-image 
                    :src="detailObj.tp[0]" 
                    :preview-src-list="detailObj.tp">
                </el-image>      -->
                <img :src="detailObj.tp[0]"  :large="detailObj.tp[0]" preview="1" preview-text="" alt="" srcset="">           
                <div class="intro">
                    <span>展馆：{{detailObj.cpxx.zhanguan}}</span>
                    <span>展区： {{detailObj.cpxx.zhanqu}}</span>
                    <span>展位号：{{detailObj.cpxx.zwh}}</span>
                </div>
                <div class="msg">
                    <h4>产品介绍</h4>
                    <div class="text text2" v-html="detailObj.cpxx.cpjj">
                    </div>
                </div>
                <div class="msg">
                    <h4>展商</h4>
                    <div class="text">
                        {{detailObj.cpxx.zsmc}}
                    </div>
                </div>
                <div class="msg">
                    <div class="to" @click="toShowDetail(detailObj.cpxx.zsid)">前往展商 →</div>
                </div>
            </div>
            <div class="m_introduce">
                <h3>
                    <img :src="require('@/assets/mobileImages/h3_left.png')" alt="">
                    <span>该公司其他产品</span>
                    <img :src="require('@/assets/mobileImages/h3_right.png')" alt="">
                </h3>
                <div class="introduce">
                    <div class="m_i_cell" v-for="(item, i) in detailProduct" :key="i" @click="toProductDetail(item.id)">
                        <div class="mleft">
                            <div class="title">{{item.cpmc}}</div>
                            <div class="num">展位号：{{item.zwh}}</div>
                            <div class="detail">查看详情</div>
                        </div>
                        <img :src="item.logo" alt="">
                    </div>
                    <SelfPage :total="total" :size="3" @handleCurrentChange="handleCurrentChange" v-show="detailProduct.length" prevText="上一页" nextText="下一页"></SelfPage>
                    <Empty v-show="!detailProduct.length"></Empty>
                </div>                
            </div>             
        </template>
    </div>
</template>

<script>
import {
    getProductsDetail,
    getProducts,
} from '@/api/home'
export default {
    name: 'ExhibitionprojectOnlineproductdetail',

    data() {
        return {
            headImgUrl: require('@/assets/images/header.png'),
            mobileHeadImgUrl: require('@/assets/mobileImages/m_header.png'),
            isMobile: JSON.parse(sessionStorage.getItem('isMobile')),   
            titleName:{
                chinese: '展品详情',
                english: 'Details of the exhibits',
            },
            detailObj: {
                cpxx: {},
                tp: [],
            },
            detailProduct: [],
            page: 1,
            size: 3,
            total: 0,            
        };
    },
    watch: {

      '$route.query.productId'(v) {
        this.getProductsDetail(Number(v))
      },
      
    },
    computed: {
        compScreenWidth() { // 计算屏幕宽度，进行适配代码
            return screen.width
        },
    },

    mounted() {
        this.getProductsDetail(this.$route.query.productId)
    },

    methods: {
        getProductsDetail(id) {
            
            getProductsDetail(id).then(res=>{
                this.detailObj = res.data
                this.getProducts(res.data.cpxx.zsid)
            })
        },
        getProducts(id) {
            var param = {
                zsid: id,
                cpid: this.$route.query.productId,
                pageindex: this.page,
                pagesize: this.size,
            }
            getProducts(param).then(res=>{
                var data = res.data
                // 过滤重复产品 $route.query.productId
                // data.chanping.map((R,i)=>{
                //     if (R.id==this.$route.query.productId) {
                //         data.chanping.splice(i, 1)
                //         data.total = Number(data.total) - 1
                //     }
                // })
                // console.log('data.chanping', data.chanping);
                // console.log('data.total', data.total);
                this.detailProduct = data.chanping
                this.total = Number(data.total)

            })
        },
        handleCurrentChange(current) {
            this.page = current
            this.getProducts(this.detailObj.cpxx.zsid)

        },
        toShowDetail(id) {
            this.$router.push(`/home/showDetail?id=${id}`)
        },
        toProductDetail(id) {
            this.$router.push(`/home/showProductDetail?productId=${id}`)
            this.$root.$emit('toTop')
        },
    },
};
</script>

<style lang="scss" scoped>
.introduce {
    max-width: 940px;
    margin: 0 auto;
    margin-top: 32px;
    .title {
        display: flex;
        align-items: center;
        span {
            display: inline-block;
            width: 110px;
            text-align: justify;
            height: 26px;
            font-size: 18px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 700;
            color: #0C266D;
            line-height: 26px;
        }
    }
    .text {
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        line-height: 32px;
        text-align: justify;
    }
    padding-bottom: 48px;
    border-bottom: 2px solid #EDF0F7;
    margin-bottom: 48px;
}
.prodcutDetailTop {
    max-width: 940px;
    margin: 0 auto;
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .pdtLeft {
        width: 233px;
        height: 250px;
        background: #EEEEEE;
        padding: 25px 73px;
        margin-right: 32px;
        .el-image, img {
            width: 254px;
            height: 257px;
        }
    }
    .pdtRight {
        width: 100%;
        height: 300px;
        h3 {
            text-align: justify;
        }
        .name {
            text-align: justify;
            margin-top: 20px;
            
            span {
                &:nth-child(1) {
                    height: 32px;
                    font-size: 18px;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: 700;
                    color: #0C266D;
                    line-height: 32px;
                    margin-right: 12px;
                }
                &:nth-child(2) {
                    height: 32px;
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #000000;
                    line-height: 32px;
                }
            }
        }
        .company {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 56px;
            .cname {
                height: 36px;
                font-size: 18px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #0B43BC;
                line-height: 36px;
            }
            .to {
                cursor: pointer;
                height: 20px;
                font-size: 15px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #0C266D;
                line-height: 20px;
            }
        }
    }
}
h3 {
    font-size: 26px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 700;
    color: #0C266D;
    line-height: 48px;
}
.productCell {
        max-width: 940px;
        margin: 0 auto;
        margin-top: 48px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: space-between;
        .pcell {
            width: 300px;
            height: 322px;
            cursor: pointer;
            &:not(:nth-child(3n)) {
                margin-right: 20px;
            }
            margin-bottom: 20px;
            .pic {
                background: #EEEEEE;
                img {
                    width: 215px;
                    height: 150px;
                }
                padding: 30px;
            }
            .msg {
                background: #FFFFFF;
                padding: 12px;
                .mtitle {
                    height: 56px;
                    font-size: 18px;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: 700;
                    color: #222222;
                    line-height: 28px;
                    text-align: justify;
                }
                .operate {
                    
                    span {
                        height: 20px;
                        font-size: 15px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #0C266D;
                        line-height: 20px;
                        &:last-child {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
}
.mobileDetail {
    // min-width: 351px;
    max-width: 80%;
    margin: 20px auto;
    background: #FFFFFF;
    padding: 0 12px;
    padding-bottom: 20px;
    box-shadow: 0px 1px 0px 0px #E6E6E6;
    border-radius: 6px 6px 6px 6px;
    h3 {
        font-size: 26px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #0C266D;
        line-height: 48px;
        text-align: center;
    }  
    img, .el-image {
        width: 200px;
        height: 150px;
        background: #EEEEEE;       
        margin: 12px 0;     
    }   
    .intro {
        span {
            height: 30px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #0B43BC;
            line-height: 30px;    
            display: block;         
        }
    }    
    .msg {
        h4 {
            height: 20px;
            font-size: 14px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 700;
            color: #0C266D;
            line-height: 20px;
            margin-top: 20px;
        }
        .text {
            font-size: 12px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            line-height: 18px;
            text-align: center;
        }
        .text2 {
            text-align: justify;
        }
    }   
    
}
.m_introduce {
    // width: 327px;
    width: 85%;
    // height: 318px;
    border-radius: 6px 6px 6px 6px;
    margin: 16px auto;
    padding: 12px;
    h3 {
        margin: 0 !important;
        img {
            width: 27px;
            height: 9px;
        }
        span {
            height: 21px;
            font-size: 17px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 700;
            color: #0C266D;
            line-height: 24px;
            margin: 0 20px;
        }
    }
    .introduce {
        width: 100%;
        margin-top: 17px;
        .m_i_cell {
            padding: 9px 12px;
            margin-top: 12px;
            // min-width: 327px;
            max-width: 100%;
            height: 108px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;   
            display: flex;  
            justify-content: space-between;
            align-items: center;
            .mleft {
                margin-right: 12px;
                .title {
                    font-size: 16px;
                    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                    font-weight: 500;
                    color: #222229;
                    line-height: 22px;
                    text-align: justify;
                }
                .num {
                    font-size: 12px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #727272;
                    line-height: 16px; 
                    text-align: justify;      
                    margin-top: 7px;                
                }
                .detail {
                    font-size: 15px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #0B43BC;
                    line-height: 14px;
                    text-align: justify;
                    margin-top: 7px;        
                }
            }    
            img {
                width: 110px;
                height: 84px;
                border-radius: 2px 2px 2px 2px;
            }       
        }
    }

}   
</style>