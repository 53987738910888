<!--
 * @Author: huanggs huanggx@gzyct.com
 * @Date: 2023-04-25 19:43:44
 * @LastEditors: huanggs huanggx@gzyct.com
 * @LastEditTime: 2023-05-09 11:18:45
 * @FilePath: \exhibitionProject\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app" ref="mainscroll">
    <router-view />
    <transition name="el-fade-in">
      <div class="el-backtop" v-show="show" style="right: 40px; bottom: 40px;" @click="toTop"><i class="el-icon-caret-top"></i></div>
    </transition>
  </div>
</template>

<script>

export default {
  data() {
    return {
      show: false,
    }
  },
  methods: {
    toTop() {
      this.$nextTick(()=>{
        var scrollEl = this.$refs.mainscroll
        scrollEl.scrollTo({top: 0, behavior: 'smooth'})
      })
    },
    handleScroll() {
      let domAppscrollTop = document.getElementById('app').scrollTop
      if (domAppscrollTop > 300) {
        this.show = true
      } else {
        this.show = false
      }
    },
  },
  beforeCreate() {


  },
  mounted() {


    this.$nextTick(()=>{
      let domApp = document.getElementById('app')
      domApp.addEventListener('scroll', this.handleScroll)
    });
    this.$root.$on('toTop', ()=>{
      this.toTop()
    })
  },
  destroyed() {
      let domApp = document.getElementById('app')
      domApp.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang="less">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 0;
    }
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
</style>